import produce from "immer";
import { isString, omit } from "micro-dash";
import { format } from "date-fns";

import { isIsoDate } from "./index";
import { blurredBackgroundEnabledFrom } from "../constants";

export function convertCeremonyEntityIntoInitialsValues(ceremony) {
    ceremony = omit(ceremony, "createdAt", "updatedAt", "uniqueUrl", "playUrl");
    ceremony.relatives = ceremony.relatives || [];
    ceremony.date = isString(ceremony.date)
        ? new Date(ceremony.date)
        : ceremony.date;

    return ceremony;
}

export function processCeremonyFormData(
    isEditing,
    incomingValues,
    preserveWaitingTime
) {
    const values = produce(incomingValues, draft => {
        draft.roomId = draft.room;
        draft.ceremonyMasterName = draft.ceremonyMaster.name;
        draft.ceremonyMasterEmail = draft.ceremonyMaster.email;
        draft.ceremonyMasterPhone = draft.ceremonyMaster.phone;
        delete draft.ceremonyMaster;
        delete draft.room;
        draft.waitingTime = parseInt(draft.waitingTime);
        draft.totalTime = parseInt(draft.totalTime);

        if (isIsoDate(draft.date) || draft.date instanceof Date) {
            draft.date = format(new Date(draft.date), "yyyy-MM-dd HH:mm:ss");
        }

        if (!draft.crematoriumId) {
            draft.crematoriumId = draft.crematoryId;
        }

        if (!isEditing && draft.relatives && !draft.relatives.length) {
            delete draft.relatives;
        }

        if (!preserveWaitingTime) {
            draft.waitingTime = draft.waitingTime * 60000;
        }
    });

    return values;
}

export function enableBlurredBackground(incomingValues) {
    const blurredBackgroundEnabledDate = new Date(blurredBackgroundEnabledFrom);
    const currentDate = new Date();

    const values = produce(incomingValues, draft => {
        if (currentDate > blurredBackgroundEnabledDate) {
            draft.useBlurredPhotoBackground = 1;
        }
    });

    return values;
}
