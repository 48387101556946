import React from "react";
import { Form } from "formik";

import i18n from "../../../../constants/i18n";
import UploadField from "../../../UploadField";

const BlockPhotoUploadModalView = ({
    errors,
    hideModal,
    isSubmitting,
    isVisible,
    setFieldTouched,
    setFieldValue,
    status,
    submitForm,
    values
}) => {
    return (
        <div
            className={`modal upload-modal ${
                isSubmitting ? "is-uploading" : ""
            } ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoUploadModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <UploadField
                            fileTypes={["image/png", "image/jpeg"]}
                            label={i18n.photoUploadModal.uploadFieldLabel}
                            name="photo"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            value={values.photo}
                            error={errors.photo}
                            multiple
                        />
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                    <div className="progress">
                        <span
                            className="progress-bar"
                            style={{
                                width: `${(status && status.uploadProgress) ||
                                    0}%`
                            }}
                        ></span>
                    </div>
                    <div className="upload-loader-container">
                        <div className="loader upload-loader"></div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default BlockPhotoUploadModalView;
