import {
    DELETE_ORGANIZATION,
    PUT_ORGANIZATIONS
} from "../constants/actionTypes";

export function putOrganizations(organizations) {
    return {
        type: PUT_ORGANIZATIONS,
        payload: organizations
    };
}

export function deleteOrganization(payload) {
    return {
        type: DELETE_ORGANIZATION,
        payload
    };
}
