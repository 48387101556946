import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";
import { normalize } from "normalizr";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { pick, isUndefined } from "micro-dash";
import * as Sentry from "@sentry/browser";

import i18n from "../../../../constants/i18n";
import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import { crematory as crematorySchema } from "../../../../schemas";

import RoomModalView from "./RoomModalView";

class RoomModalContainer extends Component {
    initialValues = {
        name: "",
        livestreaming: 0,
        recording: 0,
        hidden: 0,
        signageEnabled: 0
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (this.isEditing) {
            this.initialValues = pick(
                props.room,
                "id",
                "name",
                "livestreaming",
                "recording",
                "crematoryId",
                "hidden",
                "signageEnabled"
            );

            if (isUndefined(this.initialValues.hidden)) {
                this.initialValues.hidden = 0;
            }
        }
    }

    validationSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        livestreaming: Yup.number().required(i18n.validation.required),
        recording: Yup.number().required(i18n.validation.required),
        hidden: Yup.number().required(i18n.validation.required)
    });

    get isEditing() {
        return Boolean(this.props.room);
    }

    onSubmit = async (rawValues, formikBag) => {
        const values = { ...rawValues, master_unit: rawValues.hidden };

        try {
            if (this.isEditing) {
                await this.api.putRoom(values);
            } else {
                await this.api.postRoom(this.props.crematoryId, values);
            }
            const response = await this.api.getCrematoryById(
                this.props.crematoryId
            );

            const { entities } = normalize(response.data.data, crematorySchema);
            this.props.updateStoreEntities(entities);

            formikBag.setSubmitting(false);
            this.props.hideModal();
            this.props.showToast({
                body: this.isEditing
                    ? i18n.generic.createRoomSuccess
                    : i18n.generic.updateRoomSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                isEditing={this.isEditing}
                render={props => (
                    <RoomModalView
                        hideModal={this.props.hideModal}
                        isVisible={this.props.isVisible}
                        {...props}
                    ></RoomModalView>
                )}
            />
        );
    }
}

export default withRouter(RoomModalContainer);
