let id = 0;

const defaultOptions = {};

export default function createToast(options) {
    return {
        ...defaultOptions,
        ...options,
        id: id++
    };
}
