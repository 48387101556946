import { Component } from "react";
import { withRouter } from "react-router-dom";
import DeleteWarningModalView from "./DeleteWarningModalView";
import { get } from "micro-dash";
import * as Sentry from "@sentry/browser";
import { extractApiErrorMessage } from "../../../../helpers";
import createApiService from "../../../../network";
import axios from "axios";
import routeNames from "../../../../constants/routeNames";

class DeleteWarningModalContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false
        };

        this.api = createApiService(axios);
    }

    handleChange = () => {
        this.setState(prevState => ({
            isChecked: !prevState.isChecked
        }));
    };

    deleteCeremony = async id => {
        try {
            const response = await this.api.deleteCeremony(
                this.props.ceremonyId
            );
            this.props.hideModal();
            this.props.showToast({
                body: get(response, ["data", "message"], ""),
                title: "Success",
                themeClass: "is-success"
            });
            const message = get(response, ["data", "meta", "message"], null);
            if (message) {
                this.props.showToast({
                    body: message,
                    title: "Warning",
                    themeClass: "is-warning"
                });
            }
            this.props.onCeremonyDeleted(id);
            this.props.history.push(
                `${routeNames.locations}/${this.props.crematoryId}/reload`
            );
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    render() {
        return (
            <DeleteWarningModalView
                deleteCeremony={this.deleteCeremony}
                handleChange={this.handleChange}
                isChecked={this.state.isChecked}
                hideModal={() => this.props.hideModal()}
                isVisible={this.props.isVisible}
            />
        );
    }
}

export default withRouter(DeleteWarningModalContainer);
