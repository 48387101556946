import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import modalTypes from "../../constants/modalTypes";
import roleTypes from "../../constants/roles";
import { showToast } from "../../actions/toasts";
import { showModal } from "../../actions/modals";
import { deleteBlock } from "../../actions/blocks";
import { deletePhoto } from "../../actions/photos";
import { reorderBlocks } from "../../actions/blocks";
import { putNormalizedEntities } from "../../helpers";
import { getWalkInBlockId, getWalkOutBlockId } from "../../selectors/blocks";

import TimelineOverviewContainer from "./TimelineOverviewContainer";

const mapStateToProps = (state, ownProps) => {
    const ceremonyId = parseInt(ownProps.match.params.ceremonyID);
    const isEndUser = state.user.profile.roles.includes(roleTypes.endUser);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);
    const isCrematory = state.user.profile.roles.includes(roleTypes.crematory);
    const isOrganization = state.user.profile.roles.includes(
        roleTypes.organization
    );

    const crematory =
        state.entities.crematories.byId[
            parseInt(ownProps.match.params.crematoryID)
        ];
    const ceremony =
        state.entities.ceremonies.byId[
            parseInt(ownProps.match.params.ceremonyID)
        ];
    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    const blocks = Object.values(state.entities.blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );
    const totalBlockDuration = blocks.reduce(
        (prev, curr) => prev + curr.duration,
        0
    );

    return {
        crematory,
        ceremony,
        blocks,
        photos: Object.values(state.entities.photos.byId).filter(
            photo => photo.ceremonyId === ceremonyId
        ),
        hasExceededCeremonyDuration:
            ceremony && totalBlockDuration >= ceremony.item.totalTime * 60000,
        hasExceededAmountOfBlocks: blocks.length >= 40,
        isEndUser,
        isAdmin,
        isCrematory,
        isOrganization,
        walkInBlockId,
        walkOutBlockId,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        showToast: options => dispatch(showToast(options)),
        reorderBlocks: blockPositions =>
            dispatch(reorderBlocks(blockPositions)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities),
        onBlockDeleted: id => dispatch(deleteBlock(id)),
        onPhotoDeleted: id => dispatch(deletePhoto(id)),
        onMainImageButtonClick: () =>
            dispatch(
                showModal({
                    type: modalTypes.MAIN_CEREMONY_PHOTO,
                    props: { ceremonyId: props.match.params.ceremonyID }
                })
            ),
        showLockedTimelineModal: ({ areAssetsCleanedUp }) =>
            dispatch(
                showModal({
                    type: modalTypes.LOCKED_TIMELINE,
                    props: {
                        ceremonyId: props.match.params.ceremonyID,
                        crematoryId: props.match.params.crematoryID,
                        areAssetsCleanedUp
                    }
                })
            )
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TimelineOverviewContainer)
);
