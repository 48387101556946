import React from "react";
import { Form } from "formik";

import i18n from "../../../../constants/i18n";
import Input from "../../../Input";
import Checkbox from "../../../Checkbox";

import styles from "./RoomModal.module.scss";

const RoomModalView = ({
    isVisible,
    hideModal,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    values,
    submitForm,
    isSubmitting,
    isEditing
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {isEditing
                            ? i18n.roomModal.titleEditMode
                            : i18n.roomModal.titleCreateMode}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <Input
                            errors={errors}
                            touched={touched}
                            name="name"
                            label="Naam"
                            placeholder="Naam"
                        />
                        <div className={styles.checkboxContainer}>
                            <label className="label">
                                {i18n.roomModal.typeLabel}
                            </label>
                            <Checkbox
                                errors={errors}
                                touched={touched}
                                name="livestreaming"
                                value={values.livestreaming}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                label={i18n.generic.liveStreaming}
                                reversed
                            />
                            <Checkbox
                                errors={errors}
                                touched={touched}
                                name="recording"
                                value={values.recording}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                label={i18n.generic.recording}
                                reversed
                            />
                            <Checkbox
                                errors={errors}
                                touched={touched}
                                name="hidden"
                                value={values.hidden}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                label={i18n.roomModal.masterUnitLabel}
                                reversed
                            />
                        </div>
                        <label className="label">
                            {i18n.roomModal.signageLabel}
                        </label>
                        <Checkbox
                            errors={errors}
                            touched={touched}
                            name="signageEnabled"
                            value={values.signageEnabled}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            label={i18n.roomModal.signageEnabledLabel}
                            reversed
                        />
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default RoomModalView;
