import React from "react";

import CrematoryOverview from "../../components/CrematoryOverview";

const CrematoriesRoute = props => {
    return (
        <div className="container">
            <CrematoryOverview></CrematoryOverview>
        </div>
    );
};

export default CrematoriesRoute;
