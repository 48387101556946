import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { showModal } from "../../../../actions/modals";
import { putNormalizedEntities } from "../../../../helpers";
import modalTypes from "../../../../constants/modalTypes";

import LibraryImageCropModalContainer from "./LibraryImageCropModalContainer";

function getCalculatedAspectRatio(aspectRatioString) {
    const split = aspectRatioString.split(":");

    return split[0] / split[1];
}

const mapStateToProps = (state, props) => {
    const {
        entities: { ceremonies, crematories, photos }
    } = state;

    const { ceremonyId, photoId } = props;

    const ceremony = ceremonies.byId[ceremonyId].item;
    const crematory = crematories.byId[ceremony.crematoryId].item;
    const photo = photos.byId[photoId];
    const blocks = Object.values(state.entities.blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );

    return {
        aspectRatio: getCalculatedAspectRatio(crematory.aspectRatio),
        ceremony,
        crematory,
        isVisible: true,
        photo,
        blocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => {
            dispatch(hideModal());
        },
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities),
        openBlockEditModal: ceremonyId => {
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_EDIT,
                    props: {
                        ceremonyId: ceremonyId
                    }
                })
            );
        },
        showLibraryPhotoAdjustmentModal: (photoId, ceremonyId) => {
            dispatch(hideModal());
            dispatch(
                showModal({
                    type: modalTypes.LIBRARY_PHOTO_ADJUSTMENT,
                    props: {
                        ceremonyId: parseInt(ceremonyId),
                        photoId
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryImageCropModalContainer);
