/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import { DELETE_PHOTO, PUT_PHOTOS } from "../../constants/actionTypes";

const photosById = produce((draft, action) => {
    switch (action.type) {
        case PUT_PHOTOS:
            const photos = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            photos.forEach(photo => {
                draft[photo.id] = photo;
            });
            break;

        case DELETE_PHOTO: {
            delete draft[action.payload];
        }
    }
}, {});

const allPhotos = produce((draft, action) => {
    switch (action.type) {
        case PUT_PHOTOS:
            const photos = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            photos.forEach(photo => {
                const isAlreadyPresent = draft.includes(photo.id);
                if (!isAlreadyPresent) {
                    draft.push(photo.id);
                }
            });
            break;
    }
}, []);

export default combineReducers({
    byId: photosById,
    allIds: allPhotos
});
