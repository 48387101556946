import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducers";
import createApiService from "./network";
import { persistAuthToken } from "./helpers/tokenStorage";

export default function configureStore(preloadedState, axios) {
    const middlewares = [
        thunkMiddleware.withExtraArgument({ api: createApiService(axios) })
    ];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer, preloadedState, composedEnhancers);

    if (process.env.NODE_ENV !== "production" && module.hot) {
        module.hot.accept("./reducers", () =>
            store.replaceReducer(rootReducer)
        );
    }

    persistAuthToken(store);

    return store;
}
