import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../../network";

import LibraryPhotoAdjustmentModalView from "./LibraryPhotoAdjustmentModalView";

class LibraryPhotoAdjustmentModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            crop: {
                width: 320,
                x: 160,
                y: 80,
                aspect: props.aspectRatio
            },
            isSubmitting: false
        };
    }

    closeModal = () => {
        this.props.hideModal();
    };

    render() {
        return (
            <LibraryPhotoAdjustmentModalView
                hideModal={this.props.hideModal}
                isVisible={this.props.isVisible}
                onImageLoaded={this.onImageLoaded}
                showImageCropModal={this.props.showImageCropModal}
                showImageRotateModal={this.props.showImageRotateModal}
                closeModal={this.closeModal}
                onSubmit={this.onSubmit}
                src={this.state.src}
                photoInternalId={this.props.photo.id}
            />
        );
    }
}

export default withRouter(LibraryPhotoAdjustmentModalContainer);
