import React, { Component } from "react";

import modalTypes from "../../constants/modalTypes";

/* PLOP_INJECT_MODAL_IMPORT */
import BlockPhotoAdjustmentModal from "./components/BlockPhotoAdjustmentModal";
import BlockImageCropModal from "./components/BlockImageCropModal";
import BlockImageRotateModal from "./components/BlockImageRotateModal";
import LibraryPhotoAdjustmentModal from "./components/LibraryPhotoAdjustmentModal";
import LibraryImageCropModal from "./components/LibraryImageCropModal";
import LibraryImageRotateModal from "./components/LibraryImageRotateModal";
import UnlockCeremonyModal from "./components/UnlockCeremonyModal";
import AdminManagementModal from "./components/AdminManagementModal";
import OrganizationModal from "./components/OrganizationModal";
import CeremonyCreateSuccessModal from "./components/CeremonyCreateSuccessModal";
import BlockPreviewModal from "./components/BlockPreviewModal";
import BlockProcessingModal from "./components/BlockProcessingModal";
import DurationWarningModal from "./components/DurationWarningModal";
import DeactivatedCrematoryModal from "./components/DeactivatedCrematoryModal";
import LockedTimelineModal from "./components/LockedTimelineModal";
import RelativesModal from "./components/RelativesModal";
import BlockEditModal from "./components/BlockEditModal";
import VideoUploadModal from "./components/VideoUploadModal";
import MusicUploadModal from "./components/MusicUploadModal";
import MainCeremonyPhotoModal from "./components/MainCeremonyPhotoModal";
import SpeechModal from "./components/SpeechModal";
import DeviceModal from "./components/DeviceModal";
import RoomModal from "./components/RoomModal";
import CrematoryModal from "./components/CrematoryModal";
import CeremonyModal from "./components/CeremonyModal";
import PhotoUploadModal from "./components/PhotoUploadModal";
import BlockPhotoUploadModal from "./components/BlockPhotoUploadModal";
import ScreenSizeModal from "./components/ScreenSizeModal";
import BlockTypeModal from "./components/BlockTypeModal";
import OrganizationUserManagementModal from "./components/OrganizationUserManagementModal";
import BlockAmountWarningModal from "./components/BlockAmountWarningModal";
import DeleteWarningModal from "./components/DeleteWarningModal";

const MODAL_COMPONENTS = {
    /* PLOP_INJECT_MODAL_TYPE */
    [modalTypes.BLOCK_PHOTO_ADJUSTMENT]: BlockPhotoAdjustmentModal,
    [modalTypes.BLOCK_IMAGE_CROP]: BlockImageCropModal,
    [modalTypes.BLOCK_IMAGE_ROTATE]: BlockImageRotateModal,
    [modalTypes.LIBRARY_PHOTO_ADJUSTMENT]: LibraryPhotoAdjustmentModal,
    [modalTypes.LIBRARY_IMAGE_CROP]: LibraryImageCropModal,
    [modalTypes.LIBRARY_IMAGE_ROTATE]: LibraryImageRotateModal,
    [modalTypes.UNLOCK_CEREMONY_MODAL]: UnlockCeremonyModal,
    [modalTypes.ADMIN_MANAGEMENT]: AdminManagementModal,
    [modalTypes.ORGANIZATION_USER_MANAGEMENT]: OrganizationUserManagementModal,
    [modalTypes.ORGANIZATION]: OrganizationModal,
    [modalTypes.CEREMONY_CREATE_SUCCESS]: CeremonyCreateSuccessModal,
    [modalTypes.BLOCK_PREVIEW]: BlockPreviewModal,
    [modalTypes.BLOCK_PROCESSING]: BlockProcessingModal,
    [modalTypes.DURATION_WARNING]: DurationWarningModal,
    [modalTypes.BLOCK_AMOUNT_WARNING]: BlockAmountWarningModal,
    [modalTypes.DEACTIVATED_CREMATORY]: DeactivatedCrematoryModal,
    [modalTypes.LOCKED_TIMELINE]: LockedTimelineModal,
    [modalTypes.RELATIVES]: RelativesModal,
    [modalTypes.BLOCK_EDIT]: BlockEditModal,
    [modalTypes.VIDEO_UPLOAD]: VideoUploadModal,
    [modalTypes.MUSIC_UPLOAD]: MusicUploadModal,
    [modalTypes.MAIN_CEREMONY_PHOTO]: MainCeremonyPhotoModal,
    [modalTypes.SPEECH]: SpeechModal,
    [modalTypes.DEVICE]: DeviceModal,
    [modalTypes.ROOM]: RoomModal,
    [modalTypes.CREMATORY]: CrematoryModal,
    [modalTypes.CEREMONY]: CeremonyModal,
    [modalTypes.PHOTO_UPLOAD]: PhotoUploadModal,
    [modalTypes.BLOCK_PHOTO_UPLOAD]: BlockPhotoUploadModal,
    [modalTypes.SCREEN_SIZE]: ScreenSizeModal,
    [modalTypes.BLOCK_TYPE]: BlockTypeModal,
    [modalTypes.DELETE_WARNING]: DeleteWarningModal
};

class ModalsContainer extends Component {
    render() {
        const { modals } = this.props;

        const renderedModals = modals.map((modal, index) => {
            const { type, props = {} } = modal;
            if (!type) return null;

            const SpecificModal = MODAL_COMPONENTS[type];
            return <SpecificModal {...props} key={type + index} />;
        });

        return <>{renderedModals}</>;
    }
}

export default ModalsContainer;
