// @ts-check
import React, { Component } from "react";
import T from "prop-types";

import { CustomDragDropContext } from "../DragDropRoot";
import DraggablePhotoView from "./DraggablePhotoView";

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9
};

class DraggablePhotoContainer extends Component {
    static contextType = CustomDragDropContext;

    onKeyDown = (event, provided, snapshot) => {
        if (event.defaultPrevented) {
            return;
        }

        if (snapshot.isDragging) {
            return;
        }

        if (event.keyCode !== keyCodes.enter) {
            return;
        }

        // we are using the event for selection
        event.preventDefault();

        this.performAction(event);
    };

    // Using onClick as it will be correctly
    // preventing if there was a drag
    onClick = event => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }

        // marking the event as used
        event.preventDefault();

        this.performAction(event);
    };

    onTouchEnd = event => {
        const { photo } = this.props;
        const {
            togglePhotoSelection,
            togglePhotoSelectionInGroup
        } = this.context;

        if (event.defaultPrevented) {
            return;
        }

        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        event.preventDefault();
        togglePhotoSelectionInGroup(photo.id);
    };

    // Determines if the platform specific toggle selection in group key was used
    wasToggleInSelectionGroupKeyUsed = event => {
        const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    performAction = () => {
        const { photo } = this.props;
        const { togglePhotoSelectionInGroup } = this.context;

        togglePhotoSelectionInGroup(photo.id);
    };

    getIsSelected = () => {
        return this.context.selectedPhotoIds.includes(this.props.photo.id);
    };

    getIsGhosting = () => {
        const { draggingPhotoId } = this.context;

        return (
            this.getIsSelected() &&
            Boolean(draggingPhotoId) &&
            draggingPhotoId !== this.props.photo.id
        );
    };

    render() {
        const { photo, displayableIndex, render } = this.props;

        const isSelected = this.getIsSelected();
        const isGhosting = this.getIsGhosting();
        const selectionCount = this.context.selectedPhotoIds.length;
        return (
            <DraggablePhotoView
                isGhosting={isGhosting}
                isSelected={isSelected}
                onClick={this.onClick}
                onKeyDown={this.onKeyDown}
                onTouchEnd={this.onTouchEnd}
                photo={photo}
                render={render}
                selectionCount={selectionCount}
                displayableIndex={displayableIndex}
            ></DraggablePhotoView>
        );
    }
}

DraggablePhotoContainer.propTypes = {
    photo: T.shape({
        id: T.oneOfType([T.string, T.number]),
        index: T.number
    }),
    displayableIndex: T.number,
    render: T.func
};

export default DraggablePhotoContainer;
