import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../../network";

import BlockPhotoAdjustmentModalView from "./BlockPhotoAdjustmentModalView";

class BlockPhotoAdjustmentModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            crop: {
                width: 320,
                x: 160,
                y: 80,
                aspect: props.aspectRatio
            },
            isSubmitting: false
        };
    }

    onReturnToBlockEditModal = () => {
        this.props.hideModal();
        this.props.openBlockEditModal(
            this.props.ceremony.id,
            this.props.block.id
        );
    };

    render() {
        return (
            <BlockPhotoAdjustmentModalView
                hideModal={this.props.hideModal}
                isVisible={this.props.isVisible}
                onImageLoaded={this.onImageLoaded}
                showImageCropModal={this.props.showImageCropModal}
                showImageRotateModal={this.props.showImageRotateModal}
                onSubmit={this.onSubmit}
                onReturnToBlockEditModal={this.onReturnToBlockEditModal}
                src={this.state.src}
                photoInternalId={this.props.photo.id}
                blockId={this.props.block.id}
            />
        );
    }
}

export default withRouter(BlockPhotoAdjustmentModalContainer);
