import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { normalize } from "normalizr";

import createApiService from "../../network";
import routeNames from "../../constants/routeNames";
import { putNormalizedEntities } from "../../helpers";
import { ceremonyWithCrematorium as ceremonyWithCrematoriumSchema } from "../../schemas";

import EndUserCeremonyOverviewView from "./EndUserCeremonyOverviewView";

class EndUserCeremonyOverviewContainer extends Component {
    state = {
        currentIds: [],
        isFetching: true,
        meta: null,
        links: null
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    componentDidMount() {
        this._isMounted = true;

        this.fetchCeremonies();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCeremonies = async (pageNumber = 1) => {
        if (this.state.meta && pageNumber === this.state.meta.currentPage) {
            return;
        }

        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getUserAssignedCeremonies(
                pageNumber
            );

            const { data, meta, links } = response.data;
            this.normalizeAndPutEntities(data);

            if (this._isMounted) {
                this.setState({
                    currentIds: data.map(ceremony => ceremony.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    onPaginationSelect = async pageNumber => {
        if (this.state.roomFilterActiveId) {
            this.getCeremoniesByRoom({
                roomId: this.state.roomFilterActiveId,
                pageNumber
            });
        } else {
            this.fetchCeremonies(pageNumber);
        }
    };

    normalizeAndPutEntities = rawData => {
        const { entities } = normalize(rawData, [
            ceremonyWithCrematoriumSchema
        ]);
        putNormalizedEntities(this.props.dispatch, entities);
    };

    render() {
        return (
            <EndUserCeremonyOverviewView
                ceremonyIds={this.state.currentIds}
                error={this.state.error}
                isFetching={this.state.isFetching}
                meta={this.state.meta}
                onPaginationSelect={this.onPaginationSelect}
                onSelect={(crematoryId, ceremonyId) => {
                    this.props.history.push(
                        `${routeNames.locations}/${crematoryId}/timeline/${ceremonyId}`
                    );
                }}
            />
        );
    }
}

export default withRouter(EndUserCeremonyOverviewContainer);
