const config = {
    oc: {
        maxUploadFileSize: 500000000, // 500MB, value is in bytes.
        walkInTime: 10, // WalkIn block default value (minutes).
        walkOutTime: 10, // WalkOut block default value (minutes).
        musicFadeTime: 4000 // 4 seconds, value is in milliseconds.
    }
};

export default config;
