import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExternalCeremonyOverviewContainer from "./ExternalCeremonyOverviewContainer";
import roleTypes from "../../constants/roles";

const mapStateToProps = (state, props) => {
    const ceremonies = Object.values(state.entities.ceremonies.byId);
    const isCrematory = state.user.profile.roles.includes(roleTypes.crematory);
    const isOrganization = state.user.profile.roles.includes(
        roleTypes.organization
    );

    return {
        ceremonies,
        isCrematory,
        isOrganization,
        user: state.user
    };
};
export default withRouter(
    connect(mapStateToProps)(ExternalCeremonyOverviewContainer)
);
