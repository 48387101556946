import React from "react";
import { get } from "micro-dash";

import styles from "./CrematoryOverview.module.scss";

import i18n from "../../constants/i18n";
import TotalDataUsage from "../../components/TotalDataUsage";
import TotalCrematoryCount from "../../components/TotalCrematoryCount";
import Table from "./Table";
import Pagination from "../Pagination";
import Search from "../Search";
import ShowIf from "../ShowIf";

const CrematoryOverviewView = props => {
    return (
        <div className="container">
            <div className={`level ${styles.titleLevel}`}>
                <div className="level-left">
                    <h1>{i18n.generic.clientOverview}</h1>
                </div>

                <div className="level-right">
                    <div className="level-item">
                        <ShowIf condition={props.isAdmin}>
                            <button
                                onClick={props.onAddButtonClick}
                                className="button is-primary is-rounded"
                            >
                                {i18n.generic.add}
                            </button>
                        </ShowIf>
                        <ShowIf condition={!props.isAdmin}>
                            <button
                                onClick={props.onExternalCeremoniesLinkClick}
                                className={"button is-secondary is-rounded"}
                            >
                                {i18n.generic.externalCeremoniesLink}
                            </button>
                        </ShowIf>
                    </div>
                </div>
            </div>

            <div className={`level ${styles.searchLevel}`}>
                <div className="level-left">
                    <Search
                        onChange={props.onSearchChange}
                        value={props.searchQuery}
                        isLoading={props.isSearching}
                    ></Search>
                </div>

                <div className="level-right">
                    <div className="level-item">
                        <ShowIf condition={props.isAdmin}>
                            <TotalDataUsage />
                        </ShowIf>
                    </div>

                    <div className="level-item">
                        <TotalCrematoryCount
                            value={get(props, ["meta", "total"])}
                        ></TotalCrematoryCount>
                    </div>
                </div>
            </div>

            {props.isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <>
                    <Table
                        crematoryIds={props.crematoryIds}
                        onEdit={props.onEdit}
                        onSelect={props.onSelect}
                    ></Table>
                    {props.isFetching === false &&
                    !props.crematoryIds.length ? (
                        <div className={`${styles.warningContainer}`}>
                            <span className="icon has-text-warning is-medium">
                                <i className="fas fa-2x fa-exclamation-circle"></i>
                            </span>

                            <h3 className="has-centered-text">
                                {i18n.generic.noResultsFound}
                            </h3>
                        </div>
                    ) : null}
                    <Pagination
                        meta={props.meta}
                        onSelect={props.onPaginationSelect}
                    ></Pagination>
                </>
            )}

            {props.error ? (
                <div className={`${styles.warningContainer}`}>
                    <span className="icon has-text-warning is-medium">
                        <i className="fas fa-2x fa-exclamation-circle"></i>
                    </span>
                    <h3 className="has-centered-text">{i18n.generic.error}</h3>
                </div>
            ) : null}
        </div>
    );
};

export default CrematoryOverviewView;
