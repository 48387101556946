import { connect } from "react-redux";

import { showToast } from "../../actions/toasts";
import ForgotPasswordContainer from "./ForgotPasswordFormContainer";

const mapDispatchToProps = dispatch => {
    return {
        showToast: options => {
            dispatch(showToast(options));
        }
    };
};

export default connect(
    undefined,
    mapDispatchToProps
)(ForgotPasswordContainer);
