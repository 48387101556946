import { connect } from "react-redux";
import TableItemView from "./TableItemView";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {
        organization: state.entities.organizations.byId[ownProps.organizationId]
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableItemView)
);
