import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";

import LoginFormContainer from "./LoginFormContainer";
import { login } from "../../actions/auth";
import { fetchUser } from "../../actions/user";
import routeNames from "../../constants/routeNames";
import { fetchUserAssignedCrematory } from "../../actions/crematories";
import { fetchUserAssignedCeremonies } from "../../actions/ceremonies";
import roles from "../../constants/roles";
import { showToast } from "../../actions/toasts";
import { extractApiErrorMessage } from "../../helpers";

const mapDispatchToProps = (dispatch, props) => {
    return {
        showToast: options => {
            dispatch(showToast(options));
        },
        onLogin: token => {
            dispatch(login(token));
            dispatch(fetchUser())
                .then(async user => {
                    if (!user.emailVerifiedAt) {
                        props.history.push(routeNames.verifyEmail);
                        return;
                    }

                    if (user.roles.includes(roles.crematory)) {
                        await dispatch(fetchUserAssignedCrematory());
                    }

                    if (user.roles.includes(roles.endUser)) {
                        await dispatch(fetchUserAssignedCeremonies());
                    }

                    props.history.push(routeNames.app);

                    return Promise.resolve();
                })
                .catch(e => {
                    Sentry.captureException(e);
                    console.error(e);
                    dispatch(
                        showToast({
                            body: extractApiErrorMessage(e),
                            title: "Error",
                            themeClass: "is-danger"
                        })
                    );
                });
        }
    };
};

export default connect(
    undefined,
    mapDispatchToProps
)(LoginFormContainer);
