import React, { useRef } from "react";
import ShowIf from "../../../ShowIf";

import styles from "./LibraryImageRotateModal.module.scss";

import i18n from "../../../../constants/i18n";

const LibraryImageRotateModalView = ({
    isSubmitting,
    isVisible,
    onRotationChange,
    onCreateNewImage,
    rotation,
    scale,
    src,
    onReturnToImageAdjustmentModal,
    onUpdateCurrentImage,
    isImageUsedInBlock
}) => {
    const ref = useRef(null);
    const [buttonClicked, setButtonClicked] = React.useState(false);
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoADjustmentModal.rotateTitle}
                    </h2>
                    <button
                        onClick={onReturnToImageAdjustmentModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <ShowIf condition={!buttonClicked}>
                        <div className={styles.AdjustmentContainer}>
                            {src && (
                                <img
                                    ref={ref}
                                    src={src}
                                    style={{
                                        transform: `rotate(${rotation}deg) scale(${scale})`
                                    }}
                                    alt=""
                                />
                            )}
                        </div>
                        <div className={styles.rotateButtonContainer}>
                            <div
                                onClick={() => {
                                    onRotationChange(
                                        ref.current.width,
                                        ref.current.height
                                    );
                                }}
                                className="field has-addons"
                            >
                                <p className="control">
                                    <button
                                        className={`button is-rounded ${
                                            isSubmitting ? "is-loading" : ""
                                        }`}
                                    >
                                        <span>Roteren</span>
                                        <span className="icon is-small">
                                            <i className="fas fa-retweet"></i>
                                        </span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </ShowIf>
                    <ShowIf condition={buttonClicked}>
                        <div className={styles.warningContainer}>
                            <p>
                                {i18n.photoADjustmentModal.imageInBlockWarning}"
                                <strong>
                                    {
                                        i18n.photoADjustmentModal
                                            .updateCurrentImage
                                    }
                                </strong>
                                "{" "}
                                {i18n.photoADjustmentModal.imageInBlockWarning2}
                                "
                                <strong>
                                    {i18n.photoADjustmentModal.addAsNewImage}
                                </strong>
                                ".
                            </p>
                        </div>
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <ShowIf condition={!isSubmitting}>
                        <button
                            onClick={() => {
                                if (buttonClicked || !isImageUsedInBlock) {
                                    onUpdateCurrentImage();
                                } else {
                                    setButtonClicked(true);
                                }
                            }}
                            className={`button is-primary is-rounded ${
                                isSubmitting ? "is-loading" : ""
                            }`}
                        >
                            {i18n.photoADjustmentModal.updateCurrentImage}
                        </button>
                        <button
                            onClick={onCreateNewImage}
                            className={`button is-primary is-rounded ${
                                isSubmitting ? "is-loading" : ""
                            }`}
                        >
                            {i18n.photoADjustmentModal.addAsNewImage}
                        </button>
                    </ShowIf>
                    <ShowIf condition={isSubmitting}>
                        <button
                            className={`button is-primary is-rounded is-loading`}
                        >
                            {" "}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default LibraryImageRotateModalView;
