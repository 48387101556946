import routeNames from "../constants/routeNames";
import { clearAuthToken } from "../helpers/tokenStorage";
import { LOGIN, LOGOUT } from "../constants/actionTypes";

export function login(payload) {
    return {
        type: LOGIN,
        payload
    };
}

export function logout() {
    return function(dispatch) {
        dispatch({
            type: LOGOUT
        });

        clearAuthToken();
        window.location.href = routeNames.login;
    };
}
