import { cloneDeep } from "micro-dash";
import { decamelizeKeys } from "humps";

export function processOrganizationFormData(isEditing, incomingValues) {
    let values = cloneDeep(incomingValues);

    if (!isEditing) {
        values.organizationId = values.organization;
        delete values.organization;
    }

    // decamelize() will convert instances of File class to empty object
    // Explicitly revert correct value to this field
    values = decamelizeKeys(values);

    return values;
}
