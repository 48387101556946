import React from "react";
import T from "prop-types";

import styles from "./TableFilter.module.scss";

const TableFilterView = ({ items, onChange, placeholder, value }) => {
    return (
        <div className={styles.container}>
            <div className={`select ${styles.select}`}>
                <select
                    value={value}
                    onChange={event => onChange(event.target.value)}
                >
                    <option value="">{placeholder}</option>
                    {items.map(item => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

TableFilterView.propTypes = {
    items: T.arrayOf(
        T.shape({
            id: T.number.isRequired,
            name: T.string.isRequired
        })
    ).isRequired,
    onChange: T.func.isRequired,
    placeholder: T.string,
    value: T.oneOfType([T.string, T.number])
};

export default TableFilterView;
