import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";
import * as Sentry from "@sentry/browser";

import i18n from "../../constants/i18n";

import createApiService from "../../network";
import routeNames from "../../constants/routeNames";
import { extractApiErrorMessage } from "../../helpers";

import DeviceOverviewView from "./DeviceOverviewView";

class DeviceOverviewContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (!props.crematory) {
            props.fetchActiveCrematory();
        }
    }

    get isCrematoryAvailable() {
        return Boolean(this.props.crematory && this.props.crematory.item);
    }

    get activeCrematoryId() {
        return get(this.props, ["crematory", "item", "id"], null);
    }

    onAddDeviceLinkClick = () => {
        this.props.showForm();
    };

    onBackLinkClick = () => {
        const { crematory, history } = this.props;

        if (this.isCrematoryAvailable) {
            history.push(
                `${routeNames.locationDetailsPaginated}`
                    .replace(":crematoryID", crematory.item.id)
                    .replace(
                        ":pageNumber",
                        sessionStorage.getItem("ceremonyOverviewPage") || 1
                    )
            );
        } else {
            history.push(routeNames.app);
        }
    };

    onDeviceDeleteRequest = async deviceId => {
        const room = this.props.rooms.find(room => room.device === deviceId);

        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                const response = await this.api.deleteDevice(room.id, deviceId);

                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });

                this.props.onDeviceDeleted(deviceId);
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onDeviceEditRequest = device => {
        this.props.showForm(device);
    };

    onRoomLinkClick = () => {
        this.props.history.push(
            `${routeNames.locations}/${this.activeCrematoryId}/rooms`
        );
    };

    render() {
        const { crematory, devices, rooms } = this.props;

        return (
            <DeviceOverviewView
                crematory={crematory}
                devices={devices}
                onAddDeviceLinkClick={this.onAddDeviceLinkClick}
                onBackLinkClick={this.onBackLinkClick}
                onDeviceDeleteRequest={this.onDeviceDeleteRequest}
                onDeviceEditRequest={this.onDeviceEditRequest}
                onRoomLinkClick={this.onRoomLinkClick}
                rooms={rooms}
            ></DeviceOverviewView>
        );
    }
}

export default withRouter(DeviceOverviewContainer);
