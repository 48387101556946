import React, { useEffect, useRef } from "react";
import styles from "./EditUserDetail.module.scss";
import i18n from "../../../constants/i18n";
import { Form } from "formik";
import Select from "../../Select";
import routeNames from "../../../constants/routeNames";
import Input from "./Input";
import ShowIf from "../../ShowIf/ShowIfView";
import LocationSelect from "./LocationSelect";
import SelectStringBased from "../../SelectStringBased";

const EditUserDetailView = ({
    userToEdit,
    errors,
    touched,
    values,
    roles,
    setFieldValue,
    handleBlur,
    history,
    submitForm,
    isSubmitting,
    organizations,
    isFetching,
    locations,
    onLocationChange,
    initialValues
}) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            setFieldValue("locations", []);
        } else {
            mounted.current = true;
        }
    }, [values.role]);

    return (
        <div className="container">
            <div className={`${styles.backButton}`}>
                <div
                    onClick={() =>
                        history.push(
                            `${routeNames.userDetail}`.replace(
                                ":userID",
                                userToEdit.id
                            )
                        )
                    }
                >
                    <span className="icon is-small">
                        <i className="fas fa-chevron-left"></i>
                    </span>
                    <span>{i18n.editUser.backToUserButton}</span>
                </div>
            </div>

            <div className="level">
                <div className="level-left">
                    <h1>
                        {i18n.editUser.userLabel}: {userToEdit.name}
                    </h1>
                </div>
                <div className="level-right"></div>
            </div>
            <ShowIf condition={!isFetching}>
                <Form>
                    <Input
                        errors={errors}
                        touched={touched}
                        name="name"
                        label={i18n.editUser.nameLabel}
                        placeholder={userToEdit.name}
                    />
                    <Input
                        errors={errors}
                        touched={touched}
                        name="email"
                        label={i18n.editUser.emailLabel}
                        placeholder={userToEdit.email}
                    />
                    <Input
                        errors={errors}
                        touched={touched}
                        name="phone"
                        label={i18n.editUser.phoneLabel}
                        placeholder={userToEdit.phone}
                    />
                    <SelectStringBased
                        errors={errors}
                        touched={touched}
                        name="role"
                        label={i18n.editUser.roleLabel}
                        placeholder="Selecteer een rol"
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        value={values.role}
                        items={roles}
                    />
                    <ShowIf condition={values.role === "Crematorium"}>
                        <LocationSelect
                            label={i18n.editUser.locationLabel}
                            name="locations"
                            isMulti={false}
                            errors={errors}
                            options={locations}
                            onchange={onLocationChange}
                            value={values.locations}
                            roleChanged={initialValues.role !== values.role}
                        />
                    </ShowIf>
                    <ShowIf condition={values.role === "Organization"}>
                        <LocationSelect
                            label={i18n.editUser.locationLabel}
                            name="locations"
                            isMulti={true}
                            errors={errors}
                            options={locations}
                            onchange={onLocationChange}
                            value={values.locations}
                            empty={initialValues.role !== values.role}
                        />
                    </ShowIf>
                    <ShowIf
                        condition={
                            values.role === "Crematorium" ||
                            values.role === "Organization"
                        }
                    >
                        <Select
                            errors={errors}
                            touched={touched}
                            name="organizationId"
                            label={i18n.editUser.organizationLabel}
                            placeholder={
                                i18n.crematoryModal.organizationPlaceholder
                            }
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            value={values.organizationId}
                            items={organizations}
                        />
                    </ShowIf>
                </Form>
                <hr />
                <button
                    onClick={submitForm}
                    disabled={isSubmitting}
                    className="button is-primary is-rounded"
                >
                    {i18n.generic.save}
                </button>
            </ShowIf>
        </div>
    );
};

export default EditUserDetailView;
