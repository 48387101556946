import { get } from "micro-dash";
import React from "react";

import mediaPlaceholder from "../../../assets/media_placeholder.svg";
import blockTypes from "../../../constants/blockTypes";
import {
    getBlockHeaderClassName,
    getBlockTitle
} from "../../../helpers/blockHelpers";
import formatMediaDuration from "../../../helpers/formatMediaDuration";
import formatFilename from "../../../helpers/formatFilename";
import ShowIf from "../../ShowIf";
import DroppableBlock from "../DroppableBlock";

import styles from "./Block.module.scss";
import i18n from "../../../constants/i18n";

const PhotoBackground = ({
    url,
    extraClassName,
    mediaPlaceholder,
    alternativePlaceholderImage
}) => {
    const alternativePlaceholderUrl = alternativePlaceholderImage
        ? alternativePlaceholderImage.fileName
        : null;

    return (
        <>
            <ShowIf condition={!url}>
                <div className={`${styles.photoLoader} ${extraClassName}`}>
                    <div className="loader"></div>
                </div>
            </ShowIf>
            {/* If a ceremony main image is chosen, then show this image as alternative placeholder. */}
            <ShowIf
                condition={
                    url && url === mediaPlaceholder && alternativePlaceholderUrl
                }
            >
                <div
                    className={`${styles.mediaObject} ${extraClassName}`}
                    style={{
                        backgroundImage: `url(${alternativePlaceholderUrl})`
                    }}
                >
                    <div className={`${styles.mainImageLabel}`}>
                        <span title={i18n.generic.noImageSelectedWarning}>
                            {i18n.generic.mainImage}
                        </span>
                    </div>
                </div>
            </ShowIf>
            {/* Default placeholder if NO ceremony main image is chosen. */}
            <ShowIf
                condition={
                    url &&
                    url === mediaPlaceholder &&
                    !alternativePlaceholderUrl
                }
            >
                <div
                    className={`${styles.mediaObject} ${extraClassName}`}
                    style={{ backgroundImage: `url(${url})` }}
                />
            </ShowIf>
            {/* Chosen image. */}
            <ShowIf condition={url && url !== mediaPlaceholder}>
                <div
                    className={`${styles.mediaObject} ${extraClassName}`}
                    style={{ backgroundImage: `url(${url})` }}
                />
            </ShowIf>
        </>
    );
};

const SinglePhoto = ({ block, alternativePlaceholderImage }) => (
    <div className="tile is-12 is-parent">
        <div className="tile is-child">
            <PhotoBackground
                url={get(block, ["photos", "0", "fileName"], mediaPlaceholder)}
                extraClassName={styles.isSingle}
                mediaPlaceholder={mediaPlaceholder}
                alternativePlaceholderImage={alternativePlaceholderImage}
            />
        </div>
    </div>
);

const SingleVideo = ({ block }) => (
    <div className="tile is-12 is-parent">
        <div className="tile is-12 is-child">
            <ShowIf condition={get(block, ["video", "fileName"])}>
                <div className={`${styles.videoObject} ${styles.isSingle}`}>
                    <span className="icon is-medium">
                        <i className="fas fa-video fa-lg"></i>
                    </span>
                </div>
            </ShowIf>
            <ShowIf
                condition={
                    !get(block, ["video", "fileName"]) &&
                    !get(block, ["video", "processingFailure"])
                }
            >
                <div className={`${styles.videoObject} ${styles.isSingle}`}>
                    <div className="loader"></div>
                </div>
            </ShowIf>
            <ShowIf
                condition={
                    !get(block, ["video", "fileName"]) &&
                    get(block, ["video", "processingFailure"])
                }
            >
                <div className={`${styles.videoObject} ${styles.isSingle}`}>
                    <span
                        className="icon is-right has-text-danger is-large"
                        title={i18n.generic.processingFailureMessage.replace(
                            "<FILENAME>",
                            block.video.originalFileName
                        )}
                    >
                        <i className="fas fa-times-circle" />
                    </span>
                </div>
            </ShowIf>
        </div>
    </div>
);

const DoublePhoto = ({ block, alternativePlaceholderImage }) => (
    <div className="tile is-12 is-vertical">
        <div className="tile is-12 is-parent">
            <div className="tile is-child">
                <PhotoBackground
                    url={get(
                        block,
                        ["photos", "0", "fileName"],
                        mediaPlaceholder
                    )}
                    extraClassName={styles.isDouble}
                    mediaPlaceholder={mediaPlaceholder}
                    alternativePlaceholderImage={alternativePlaceholderImage}
                />
            </div>
        </div>
        <div className="tile is-12 is-parent">
            <div className="tile is-child">
                <PhotoBackground
                    url={get(
                        block,
                        ["photos", "1", "fileName"],
                        mediaPlaceholder
                    )}
                    extraClassName={styles.isDouble}
                />
            </div>
        </div>
    </div>
);

const MultiplePhotos = ({
    block,
    hideFourthPhoto,
    alternativePlaceholderImage
}) => (
    <>
        <div className="tile is-6 is-vertical is-parent">
            <div className="tile is-child">
                <PhotoBackground
                    url={get(
                        block,
                        ["photos", "0", "fileName"],
                        mediaPlaceholder
                    )}
                    extraClassName={styles.isDouble}
                    mediaPlaceholder={mediaPlaceholder}
                    alternativePlaceholderImage={alternativePlaceholderImage}
                />
            </div>
            <div className="tile is-child">
                <PhotoBackground
                    url={get(
                        block,
                        ["photos", "2", "fileName"],
                        mediaPlaceholder
                    )}
                    extraClassName={styles.isDouble}
                />
            </div>
        </div>
        <div className="tile is-6 is-vertical is-parent">
            <div className="tile is-child">
                <PhotoBackground
                    url={get(
                        block,
                        ["photos", "1", "fileName"],
                        mediaPlaceholder
                    )}
                    extraClassName={styles.isDouble}
                />
            </div>
            <ShowIf condition={!hideFourthPhoto}>
                <div className="tile is-child">
                    <PhotoBackground
                        url={get(
                            block,
                            ["photos", "3", "fileName"],
                            mediaPlaceholder
                        )}
                        extraClassName={styles.isDouble}
                    />
                </div>
            </ShowIf>
        </div>
    </>
);

const ExtraHeaderPlaceholder = () => (
    <>
        <span className="icon">
            <i className="fas fa-music"></i>
        </span>
        <span className="icon is-small">
            <i className="fas fa-plus"></i>
        </span>
        <span className="icon">
            <i className="fas fa-video"></i>
        </span>
        <span className="icon is-small">
            <i className="fas fa-plus"></i>
        </span>
    </>
);

const shouldShowExtraHeader = block => {
    if (!block.isWalkInBlock && !block.isWalkOutBlock) {
        return false;
    } else {
        return true;
    }
};

const hasAssignedAsset = block => {
    return (
        (block.type === blockTypes.VIDEO && block.video) ||
        (block.type === blockTypes.AUDIO && block.audioTrack)
    );
};

const BlockView = ({
    block,
    index,
    onEditButtonClick,
    onWalkInOrOutBlockMediaPlaceholderClick,
    speechPhotoLimit,
    alternativePlaceholderImage
}) => {
    const alternativePlaceholderUrl = alternativePlaceholderImage
        ? alternativePlaceholderImage.fileName
        : null;

    return (
        <div
            className={`${styles.container} block ${
                block.isWalkInBlock ? "tutorial-step-4" : ""
            }`}
            key={block.id}
        >
            <div className={styles.blockIndicatorContainer}>
                {" "}
                <div className={styles.blockIndex}>{index + 1}</div>
                <ShowIf
                    condition={
                        block.photos.length < 1 &&
                        block.type !== blockTypes.VIDEO &&
                        !(
                            block.type === blockTypes.AUDIO &&
                            block.audioTrack.processingFailure
                        ) &&
                        !alternativePlaceholderUrl
                    }
                >
                    <div className={styles.blockIndex}>
                        <span
                            className="icon has-text-warning is-medium"
                            title={i18n.generic.noImageSelectedWarning}
                        >
                            <i className="fas fa-2x fa-exclamation-circle"></i>
                        </span>
                    </div>
                </ShowIf>
                <ShowIf
                    condition={
                        block.type === blockTypes.AUDIO &&
                        block.audioTrack.processingFailure
                    }
                >
                    <div className={styles.blockIndex}>
                        <span
                            className="icon has-text-info is-medium"
                            title={i18n.generic.processingFailureMessage.replace(
                                "<FILENAME>",
                                get(block, ["audioTrack", "originalFileName"])
                            )}
                        >
                            <i className="fas fa-2x fa-exclamation-circle"></i>
                        </span>
                    </div>
                </ShowIf>
            </div>
            <div
                onClick={() => onWalkInOrOutBlockMediaPlaceholderClick(block)}
                className={`
          ${styles.extraHeader}
          ${hasAssignedAsset(block) ? styles.isDisabled : ""}
          ${hasAssignedAsset(block) ? styles.isPink : ""}
          ${!shouldShowExtraHeader(block) ? styles.isEmpty : ""}`}
            >
                <ShowIf
                    condition={
                        !hasAssignedAsset(block) ||
                        block.type === blockTypes.SPEAKER
                    }
                >
                    <ExtraHeaderPlaceholder />
                </ShowIf>
                <ShowIf condition={block.audioTrack}>
                    <span>
                        {formatFilename(
                            get(
                                block,
                                ["audioTrack", "originalFileName"],
                                "Muziek"
                            )
                        )}
                    </span>
                </ShowIf>
                <ShowIf condition={block.video}>
                    <span>
                        {formatFilename(
                            get(block, ["video", "originalFileName"], "Video")
                        )}
                    </span>
                </ShowIf>
            </div>
            <div
                className={`${styles.header} ${getBlockHeaderClassName(
                    block,
                    styles
                )}`}
            >
                <span className={styles.title}>
                    {getBlockTitle(block)} (
                    {formatMediaDuration(block.duration)} min)
                </span>
            </div>
            <div className={styles.body}>
                <DroppableBlock
                    block={block}
                    render={(snapshot, placeholder) => (
                        <div
                            className={`tile is-ancestor ${
                                styles.photoTileAncestor
                            } ${
                                snapshot.isDraggingOver
                                    ? styles.photoTileActive
                                    : ""
                            }`}
                        >
                            <ShowIf condition={snapshot.isDraggingOver}>
                                <div className={styles.selectIconContainer}>
                                    <span className="icon">
                                        <i className="fas fa-plus"></i>
                                    </span>
                                </div>
                            </ShowIf>
                            <ShowIf
                                condition={
                                    block.type !== blockTypes.VIDEO &&
                                    (block.isWalkInBlock ||
                                        block.isWalkOutBlock)
                                }
                            >
                                <MultiplePhotos
                                    block={block}
                                    alternativePlaceholderImage={
                                        alternativePlaceholderImage
                                    }
                                />
                            </ShowIf>
                            <ShowIf
                                condition={
                                    block.type === blockTypes.AUDIO &&
                                    !block.isWalkInBlock &&
                                    !block.isWalkOutBlock
                                }
                            >
                                <ShowIf
                                    condition={
                                        !get(block, [
                                            "audioTrack",
                                            "processingFailure"
                                        ])
                                    }
                                >
                                    <MultiplePhotos
                                        block={block}
                                        alternativePlaceholderImage={
                                            alternativePlaceholderImage
                                        }
                                    />
                                </ShowIf>
                                <ShowIf
                                    condition={get(block, [
                                        "audioTrack",
                                        "processingFailure"
                                    ])}
                                >
                                    <div className="tile is-12 is-child">
                                        <div
                                            className={`${styles.videoObject} ${styles.isSingle}`}
                                        >
                                            <span
                                                className="icon is-right has-text-danger is-large"
                                                title={i18n.generic.processingFailureMessage.replace(
                                                    "<FILENAME>",
                                                    get(block, [
                                                        "audioTrack",
                                                        "originalFileName"
                                                    ])
                                                )}
                                            >
                                                <i className="fas fa-times-circle" />
                                            </span>
                                        </div>
                                    </div>
                                </ShowIf>
                            </ShowIf>
                            <ShowIf
                                condition={
                                    block.type === blockTypes.SPEAKER &&
                                    !block.isWalkInBlock &&
                                    !block.isWalkOutBlock
                                }
                            >
                                {speechPhotoLimit === 1 && (
                                    <SinglePhoto
                                        block={block}
                                        alternativePlaceholderImage={
                                            alternativePlaceholderImage
                                        }
                                    />
                                )}
                                {speechPhotoLimit === 2 && (
                                    <DoublePhoto
                                        block={block}
                                        alternativePlaceholderImage={
                                            alternativePlaceholderImage
                                        }
                                    />
                                )}
                                {speechPhotoLimit === 3 && (
                                    <MultiplePhotos
                                        block={block}
                                        alternativePlaceholderImage={
                                            alternativePlaceholderImage
                                        }
                                        hideFourthPhoto
                                    />
                                )}
                                {speechPhotoLimit > 3 && (
                                    <MultiplePhotos
                                        block={block}
                                        alternativePlaceholderImage={
                                            alternativePlaceholderImage
                                        }
                                    />
                                )}
                            </ShowIf>
                            <ShowIf condition={block.type === blockTypes.VIDEO}>
                                <SingleVideo block={block} />
                            </ShowIf>
                            {placeholder}
                        </div>
                    )}
                ></DroppableBlock>
                <div
                    onClick={() => onEditButtonClick(block)}
                    className={`${styles.editButton} ${
                        block.photos.length > 4 ? styles.hasMore : ""
                    } `}
                >
                    <ShowIf condition={block.photos.length > 4}>
                        <span className={styles.editButtonLabel}>
                            +{block.photos.length - 4}
                        </span>
                    </ShowIf>
                    <span className="icon is-small">
                        <i className="fas fa-pen"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default BlockView;
