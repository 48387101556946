import { connect } from "react-redux";
import { get } from "micro-dash";
import OrganizationDetailContainer from "./OrganizationDetailContainer";
import roleTypes from "../../constants/roles";

function getOrganizationIdFromRouteParams(match) {
    const id = get(match, ["params", "organizationID"], null);
    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = state => {
    const organizationDetailId = getOrganizationIdFromRouteParams(state.match);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);

    return {
        isAdmin,
        organization: state.entities.organizations.byId[organizationDetailId]
    };
};

export default connect(mapStateToProps)(OrganizationDetailContainer);
