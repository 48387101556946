import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import UserDetailView from "./UserDetailView";
import { fetchUserById } from "../../actions/user";

class UserDetailContainer extends Component {
    async componentDidMount() {
        if (!this.props.user) {
            const userId = parseInt(this.props.match.params.userID);
            this.props.dispatch(fetchUserById(userId));
        }
    }

    render() {
        const { user } = this.props;

        if (!user) {
            return <></>;
        }

        return (
            <UserDetailView
                history={this.props.history}
                user={user}
                startImpersonation={this.props.startImpersonation}
            ></UserDetailView>
        );
    }
}

export default withRouter(UserDetailContainer);
