import React from "react";

import RoomsOverview from "../../components/RoomsOverview";

const RoomsRoute = () => {
    return (
        <div className="container">
            <RoomsOverview></RoomsOverview>
        </div>
    );
};

export default RoomsRoute;
