import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { normalize } from "normalizr";
import createApiService from "../../network";
import routeNames from "../../constants/routeNames";
import { putNormalizedEntities } from "../../helpers";
import { ceremonyWithCrematorium as ceremonyWithCrematoriumSchema } from "../../schemas";
import ExternalCeremonyOverviewView from "./ExternalCeremonyOverviewView";

class ExternalCeremonyOverviewContainer extends Component {
    state = {
        currentIds: [],
        isFetching: true,
        meta: null,
        links: null
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    componentDidMount() {
        this._isMounted = true;

        this.fetchCeremonies();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCeremonies = async (pageNumber = 1) => {
        if (this.state.meta && pageNumber === this.state.meta.currentPage) {
            return;
        }

        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getUserExternalCeremonies(
                pageNumber
            );

            const { data, meta, links } = response.data;
            this.normalizeAndPutEntities(data);

            if (this._isMounted && Array.isArray(data)) {
                this.setState({
                    currentIds: data.map(ceremony => ceremony.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            } else {
                this.setState({
                    currentIds: [],
                    meta: [],
                    links: [],
                    isFetching: false
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    onPaginationSelect = async pageNumber => {
        this.fetchCeremonies(pageNumber);
    };

    onBackToOverviewButtonClick = () => {
        if (this.props.isCrematory) {
            this.props.history.push(
                `${routeNames.locationDetails}`.replace(
                    ":crematoryID",
                    this.props.user.profile.locations[0].id
                )
            );
        }

        if (this.props.isOrganization) {
            this.props.history.push(`${routeNames.locationsFirstPage}`);
        }
    };

    normalizeAndPutEntities = rawData => {
        const { entities } = normalize(rawData, [
            ceremonyWithCrematoriumSchema
        ]);
        putNormalizedEntities(this.props.dispatch, entities);
    };

    render() {
        return (
            <ExternalCeremonyOverviewView
                ceremonyIds={this.state.currentIds}
                error={this.state.error}
                isFetching={this.state.isFetching}
                meta={this.state.meta}
                onPaginationSelect={this.onPaginationSelect}
                onBackToOverviewButtonClick={this.onBackToOverviewButtonClick}
                onSelect={(crematoryId, ceremonyId) => {
                    this.props.history.push(
                        `${routeNames.locations}/${crematoryId}/timeline/${ceremonyId}`
                    );
                }}
            />
        );
    }
}

export default withRouter(ExternalCeremonyOverviewContainer);
