/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import { PUT_AUDIO_TRACKS } from "../../constants/actionTypes";

const audioTracksById = produce((draft, action) => {
    switch (action.type) {
        case PUT_AUDIO_TRACKS:
            const audioTracks = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            audioTracks.forEach(audioTrack => {
                draft[audioTrack.id] = audioTrack;
            });
            break;
    }
}, {});

const allAudioTracks = produce((draft, action) => {
    switch (action.type) {
        case PUT_AUDIO_TRACKS:
            const audioTracks = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            audioTracks.forEach(audioTrack => {
                const isAlreadyPresent = draft.includes(audioTrack.id);

                if (!isAlreadyPresent) {
                    draft.push(audioTrack.id);
                }
            });
            break;
    }
}, []);

export default combineReducers({
    byId: audioTracksById,
    allIds: allAudioTracks
});
