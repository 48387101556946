/* eslint-disable default-case */

import produce from "immer";

import { getAuthToken } from "../helpers/tokenStorage";
import { LOGIN } from "../constants/actionTypes";

const initialState = {
    token: getAuthToken()
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case LOGIN:
            draft.token = action.payload;
            break;
    }
}, initialState);

export default reducer;
