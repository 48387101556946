import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { deleteBlock } from "../../../../actions/blocks";
import { putCeremonies } from "../../../../actions/ceremonies";
import { putNormalizedEntities } from "../../../../helpers";
import { getCeremonyBlockWithAssetsById } from "../../../../selectors/blocks";

import MusicUploadModalContainer from "./MusicUploadModalContainer";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const blocks = Object.values(state.entities.blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );
    const block = getCeremonyBlockWithAssetsById(state, {
        ceremonyId,
        blockId: props.blockId
    });

    return {
        isEditing: Boolean(props.blockId),
        isVisible: true,
        block,
        blocks,
        ceremony: state.entities.ceremonies.byId[ceremonyId]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options)),
        onBlockDeleted: id => dispatch(deleteBlock(id)),
        onUpdatedCeremony: ceremony => dispatch(putCeremonies([ceremony])),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MusicUploadModalContainer);
