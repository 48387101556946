import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";
import * as Sentry from "@sentry/browser";

import createApiService from "../../network";
import routeNames from "../../constants/routeNames";
import { extractApiErrorMessage } from "../../helpers";
import i18n from "../../constants/i18n";

import RoomsOverviewView from "./RoomsOverviewView";

class RoomsOverviewContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (!props.crematory) {
            props.fetchActiveCrematory();
        }
    }

    get isCrematoryAvailable() {
        return Boolean(this.props.crematory && this.props.crematory.item);
    }

    get activeCrematoryId() {
        return get(this.props, ["crematory", "item", "id"], null);
    }

    onAddRoomLinkClick = () => {
        this.props.showForm();
    };

    onBackLinkClick = () => {
        const { crematory, history } = this.props;

        if (this.isCrematoryAvailable) {
            history.push(
                `${routeNames.locationDetailsPaginated}`
                    .replace(":crematoryID", crematory.item.id)
                    .replace(
                        ":pageNumber",
                        sessionStorage.getItem("ceremonyOverviewPage") || 1
                    )
            );
        } else {
            history.push(routeNames.app);
        }
    };

    onRoomDeleteRequest = async roomId => {
        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                const response = await this.api.deleteRoom(
                    this.activeCrematoryId,
                    roomId
                );

                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });

                this.props.onRoomDeleted(roomId);
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: i18n.processingFailure.roomDeleteError,
                    title: "Error",
                    themeClass: "is-danger",
                    timeout: 10000,
                });
            }
        }
    };

    onRoomEditRequest = room => {
        this.props.showForm(room);
    };

    onDeviceLinkClick = () => {
        this.props.history.push(
            `${routeNames.locations}/${this.activeCrematoryId}/devices`
        );
    };

    render() {
        const { crematory, devices, isAdmin, rooms } = this.props;

        return (
            <RoomsOverviewView
                crematory={crematory}
                devices={devices}
                isAdmin={isAdmin}
                onAddRoomLinkClick={this.onAddRoomLinkClick}
                onBackLinkClick={this.onBackLinkClick}
                onRoomDeleteRequest={this.onRoomDeleteRequest}
                onRoomEditRequest={this.onRoomEditRequest}
                onDeviceLinkClick={this.onDeviceLinkClick}
                rooms={rooms}
            />
        );
    }
}

export default withRouter(RoomsOverviewContainer);
