import React from "react";
import T from "prop-types";

import i18n from "../../constants/i18n";
import ShowIf from "../ShowIf";
import { isNil } from "micro-dash";

const SearchView = ({ isLoading, onChange, value, placeholder }) => {
    return (
        <div className="field">
            <div
                className={`control has-icons-right ${
                    isLoading ? "is-loading" : ""
                }`}
            >
                <input
                    onChange={onChange}
                    value={value}
                    className="input is-rounded"
                    type="text"
                    placeholder={
                        !isNil(placeholder)
                            ? placeholder
                            : i18n.generic.searchClients
                    }
                />
                <ShowIf condition={!isLoading}>
                    <span className="icon is-right has-text-dark">
                        <i className="fas fa-search"></i>
                    </span>
                </ShowIf>
            </div>
        </div>
    );
};

SearchView.propTypes = {
    isLoading: T.bool.isRequired,
    onChange: T.func.isRequired,
    value: T.string.isRequired
};

export default SearchView;
