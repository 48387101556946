import React from "react";
import Tippy from "@tippy.js/react";
import { capitalize } from "micro-dash";

import i18n from "../../../constants/i18n";
import styles from "./TableItem.module.scss";

const TableItemView = ({ device, onEdit, onDelete, rooms }) => {
    if (!device) {
        return null;
    }

    const assignedRoom = rooms.find(room => room.id === device.roomId);

    return (
        <tr>
            <td>
                <h2>{device.name}</h2>
            </td>
            <td>
                <span>
                    {i18n.deviceOverview.deviceTypeLabel}:{" "}
                    {device.type
                        ? capitalize(device.type)
                        : i18n.deviceOverview.unknownDeviceType}
                </span>
            </td>
            <td>
                <span>
                    {i18n.deviceOverview.deviceIdLabel}: {device.externalId}
                </span>
            </td>
            <td>
                <span>
                    {i18n.deviceOverview.roomIdLabel}:{" "}
                    {device.roomNumber ? device.roomNumber : ""}
                </span>
            </td>
            <td>
                {assignedRoom ? (
                    <span>{assignedRoom.name}</span>
                ) : (
                    i18n.deviceOverview.notAssignedToRoom
                )}
            </td>
            <td>
                {i18n.deviceOverview.registrationCode}:{" "}
                {device.registrationCode}
            </td>
            <td>
                <div className="level">
                    <div className="level-item">
                        <Tippy content={i18n.generic.edit}>
                            <span
                                onClick={() => onEdit(device)}
                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                            >
                                <i className="fas fa-lg fa-pen"></i>
                            </span>
                        </Tippy>
                    </div>
                    <div className="level-item">
                        <Tippy content={i18n.generic.delete}>
                            <span
                                onClick={() => onDelete(device.id)}
                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                            >
                                <i className="fas fa-lg fa-trash-alt"></i>
                            </span>
                        </Tippy>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default TableItemView;
