import React from "react";

import styles from "./BlockTypeModal.module.scss";
import i18n from "../../../../constants/i18n";

const BlockTypeModalView = ({ isEditing, hideModal, isVisible, items }) => (
    <div className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}>
        <div className="modal-background" />
        <div className="modal-card">
            <header className="modal-card-head">
                <h2 className="modal-card-title has-text-primary">
                    {isEditing
                        ? i18n.blockTypeModal.titleEditMode
                        : i18n.blockTypeModal.titleCreateMode}
                </h2>
                <button
                    onClick={hideModal}
                    className="delete"
                    aria-label="close"
                />
            </header>
            <section className="modal-card-body">
                <div
                    className={`${styles.container} ${
                        items.length === 2 ? styles.doubleItems : ""
                    }`}
                >
                    {items.map(item => (
                        <div
                            key={item.id}
                            onClick={() => item.clickHandler()}
                            className={styles.item}
                        >
                            <img
                                className={styles.icon}
                                src={item.icon}
                                alt={item.altAttr}
                            ></img>
                            <span>{item.label}</span>
                        </div>
                    ))}
                </div>
            </section>
            <footer className="modal-card-foot"></footer>
        </div>
    </div>
);

export default BlockTypeModalView;
