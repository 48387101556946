import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { putNormalizedEntities } from "../../../../helpers";

import RoomModalContainer from "./RoomModalContainer";

const mapStateToProps = (state, props) => {
    return {
        isVisible: true
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoomModalContainer);
