import i18n from "../../../constants/i18n";
import React from "react";
import Tippy from "@tippy.js/react";
import styles from "./Locations.module.scss";

const LocationsView = props => {
    return (
        <div className="container">
            {props.isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <div className="block">
                    <div className="level">
                        <div className="level-left">
                            <h1 className="title is-4">
                                {i18n.organizationDetail.locations}
                            </h1>
                        </div>
                        <div className="level-right">
                            <Tippy content={i18n.generic.add}>
                                <span
                                    onClick={props.onAddButtonClick}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lg fa-plus"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                    <table className="table is-bordered is-striped is-fullwidth is-narrow">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Naam</th>
                                <th>Plaats</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.locations.map(location => (
                                <tr key={location.id}>
                                    <td>{location.id}</td>
                                    <td>{location.name}</td>
                                    <td>{location.city}</td>
                                    <td>
                                        <Tippy content={i18n.generic.edit}>
                                            <span
                                                onClick={() =>
                                                    props.onEdit(location.id)
                                                }
                                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                            >
                                                <i className="fas fa-lg fa-pen"></i>
                                            </span>
                                        </Tippy>
                                    </td>
                                    <td>
                                        <Tippy content={i18n.generic.show}>
                                            <span
                                                onClick={() =>
                                                    props.onShowLocation(
                                                        location.id
                                                    )
                                                }
                                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                            >
                                                <i className="fas fa-lg fa-eye"></i>
                                            </span>
                                        </Tippy>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default LocationsView;
