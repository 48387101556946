import React from "react";

import styles from "./VerifyEmail.module.scss";

const VerifyEmailRoute = () => {
    return (
        <div className={`container ${styles.container}`}>
            <p className={styles.text}>
                Uw emailadres is nog niet geverifieerd. Kijk in uw inbox of
                spamfolder voor de verificatielink.
            </p>
        </div>
    );
};

export default VerifyEmailRoute;
