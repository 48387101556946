import React, { Component } from "react";
import axios from "axios";
import { normalize } from "normalizr";
import { debounce } from "micro-dash";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import createApiService from "../../network";
import CrematoryOverviewView from "./CrematoryOverviewView";
import { crematory as crematorySchema } from "../../schemas";
import { putNormalizedEntities } from "../../helpers";
import routeNames from "../../constants/routeNames";
import { showModal } from "../../actions/modals";
import modalTypes from "../../constants/modalTypes";

class CrematoryOverviewContainer extends Component {
    state = {
        currentIds: [],
        isFetching: true,
        isSearching: false,
        searchQuery: "",
        meta: null,
        links: null
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    componentDidMount() {
        this._isMounted = true;

        this.fetchCrematories(this.props.match.params.pageNumber);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.match.params.pageNumber !==
            this.props.match.params.pageNumber
        ) {
            this.fetchCrematories(this.props.match.params.pageNumber);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCrematories = async (pageNumber = 1) => {
        if (this.state.meta && pageNumber === this.state.meta.currentPage) {
            return;
        }

        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getCrematoriesPaginated(pageNumber);

            const { data, meta, links } = response.data;
            const { entities } = normalize(data, [crematorySchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            if (this._isMounted) {
                this.setState({
                    currentIds: data.map(crematory => crematory.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    searchCrematory = async query => {
        this.setState({ isFetching: true, isSearching: true });

        try {
            const response = await this.api.searchCrematory(query);

            const { data, meta, links } = response.data;
            const { entities } = normalize(data, [crematorySchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            this.setState({
                currentIds: data.map(crematory => crematory.id),
                meta,
                links,
                isFetching: false,
                isSearching: false,
                error: null
            });
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            this.setState({
                isFetching: false,
                isSearching: false,
                error
            });
        }
    };

    debouncedOnSearch = debounce(event => {
        this.searchCrematory(event.target.value);
    }, 500);

    onSearch = event => {
        event.persist();
        this.debouncedOnSearch(event);
        this.setState({ searchQuery: event.target.value });
    };

    onPaginationSelect = pageNumber => {
        this.props.history.push(
            `${routeNames.locationsPaginated}`.replace(
                ":pageNumber",
                pageNumber
            )
        );
    };

    onAddButtonClick = () => {
        this.props.dispatch(
            showModal({ type: modalTypes.CREMATORY, props: {} })
        );
    };

    onExternalCeremoniesLinkClick = () => {
        this.props.history.push(
            `${routeNames.invitedCeremonies}`.replace(":pageNumber", 1)
        );
    };

    render() {
        return (
            <CrematoryOverviewView
                crematoryIds={this.state.currentIds}
                meta={this.state.meta}
                onPaginationSelect={this.onPaginationSelect}
                onExternalCeremoniesLinkClick={
                    this.onExternalCeremoniesLinkClick
                }
                isFetching={this.state.isFetching}
                isAdmin={this.props.isAdmin}
                isSearching={this.state.isSearching}
                onSearchChange={this.onSearch}
                searchQuery={this.state.searchQuery}
                onEdit={id => {
                    this.props.dispatch(
                        showModal({
                            type: modalTypes.CREMATORY,
                            props: { crematoryId: id }
                        })
                    );
                }}
                onSelect={id => {
                    sessionStorage.setItem(
                        "crematoryOverviewPage",
                        this.props.match.params.pageNumber
                    );
                    this.props.history.push(
                        `${routeNames.locationDetails}/`.replace(
                            ":crematoryID",
                            id
                        )
                    );
                }}
                error={this.state.error}
                onAddButtonClick={this.onAddButtonClick}
            ></CrematoryOverviewView>
        );
    }
}

export default withRouter(CrematoryOverviewContainer);
