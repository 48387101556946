import React from "react";

import TableItem from "../TableItem";

const CrematoryTable = ({ crematoryIds, onEdit, onSelect }) => {
    return (
        <div>
            <table className="table is-hoverable is-fullwidth">
                <tbody>
                    {crematoryIds.map(id => (
                        <TableItem
                            key={id}
                            crematoryId={id}
                            onEdit={onEdit}
                            onSelect={onSelect}
                        ></TableItem>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CrematoryTable;
