import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import {
    INVALIDATE_CEREMONY,
    FETCH_CEREMONY_REQUEST,
    FETCH_CEREMONY_SUCCESS,
    FETCH_CEREMONY_FAILURE,
    PUT_CEREMONIES,
    DELETE_CEREMONY,
    RELOCATE_CEREMONY
} from "../constants/actionTypes";

import {
    ceremony as ceremonySchema,
    ceremonyWithCrematorium as ceremonyWithCrematoriumSchema,
    photo as photoSchema,
    block as blockSchema,
    audioTrack as audioTrackSchema,
    video as videoSchema
} from "../schemas";
import { putNormalizedEntities } from "../helpers";

export function invalidateCeremony(id) {
    return {
        type: INVALIDATE_CEREMONY,
        payload: id
    };
}

export function fetchCeremonyRequest(id) {
    return {
        type: FETCH_CEREMONY_REQUEST,
        payload: id
    };
}

export function fetchCeremonySuccess(payload) {
    return {
        type: FETCH_CEREMONY_SUCCESS,
        payload
    };
}

export function fetchCeremonyFailure(id, error) {
    return {
        type: FETCH_CEREMONY_FAILURE,
        payload: id,
        error
    };
}

export function putCeremonies(payload) {
    return {
        type: PUT_CEREMONIES,
        payload
    };
}

export function deleteCeremony(payload) {
    return {
        type: DELETE_CEREMONY,
        payload
    };
}

export function relocateCeremony(payload) {
    return {
        type: RELOCATE_CEREMONY,
        payload
    };
}

export function fetchCeremonyPhotos(ceremony) {
    return async function(dispatch, _, { api }) {
        try {
            const {
                data: { data: photos }
            } = await api.getCeremonyPhotos(ceremony.item.id);

            const { entities } = normalize(photos, [photoSchema]);
            putNormalizedEntities(dispatch, entities);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
    };
}

export function fetchCeremonyBlocks(ceremony) {
    return async function(dispatch, _, { api }) {
        try {
            const {
                data: { data: blocks }
            } = await api.getCeremonyBlocks(ceremony.item.id);

            const { entities } = normalize(blocks, [blockSchema]);
            putNormalizedEntities(dispatch, entities);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
    };
}

export function fetchCeremonyAudioTracks(ceremony) {
    return async function(dispatch, _, { api }) {
        try {
            const {
                data: { data: audioTracks }
            } = await api.getCeremonyAudioTracks(ceremony.item.id);

            const { entities } = normalize(audioTracks, [audioTrackSchema]);
            putNormalizedEntities(dispatch, entities);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
    };
}

export function fetchCeremonyVideos(ceremony) {
    return async function(dispatch, _, { api }) {
        try {
            const {
                data: { data: videos }
            } = await api.getCeremonyVideos(ceremony.item.id);

            const { entities } = normalize(videos, [videoSchema]);
            putNormalizedEntities(dispatch, entities);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
    };
}

export function fetchCeremony(id, normalizeWithCrematorium) {
    return function(dispatch, _, { api }) {
        dispatch(fetchCeremonyRequest(id));

        return api
            .getCeremonyById(id)
            .then(async response => {
                const { entities } = normalize(
                    response.data.data,
                    normalizeWithCrematorium
                        ? ceremonyWithCrematoriumSchema
                        : ceremonySchema
                );
                putNormalizedEntities(dispatch, entities);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error(error);
                dispatch(fetchCeremonyFailure(id, error));
            });
    };
}

function shouldFetchCeremony(state, id) {
    const ceremony = state.entities.ceremonies.byId[id];

    if (!ceremony) {
        return true;
    }

    if (ceremony.isFetching) {
        return false;
    } else {
        return ceremony.didInvalidate;
    }
}

export function fetchCeremonyIfNeeded(id, normalizeWithCrematorium) {
    return (dispatch, getState) => {
        if (shouldFetchCeremony(getState(), id)) {
            return dispatch(fetchCeremony(id, normalizeWithCrematorium));
        } else {
            return Promise.resolve();
        }
    };
}

export function fetchUserAssignedCeremonies() {
    return function(dispatch, _, { api }) {
        return api
            .getUserAssignedCeremonies()
            .then(async ({ data: { data: ceremonies } }) => {
                return ceremonies.map(ceremony =>
                    dispatch(fetchCeremonyIfNeeded(ceremony.id, true))
                );
            });
    };
}
