import React from "react";
import { sortBy } from "micro-dash";
import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames";

import { droppableIdPrefixes } from "../constants";
import { CustomDragDropContext } from "../DragDropRoot";
import DraggableBlock from "../DraggableBlock";
import Block from "../Block";
import ShowIf from "../../ShowIf";
import styles from "./DroppableBlockList.module.scss";

const DroppableBlockView = ({
    blocks,
    isBlockReorderModeEnabled,
    onAddButtonClick,
    onEditButtonClick,
    onWalkInOrOutBlockMediaPlaceholderClick,
    speechPhotoLimit
}) => {
    const sortedBlocks = sortBy(blocks, [block => block.position]);

    return (
        <CustomDragDropContext.Consumer>
            {context => (
                <Droppable
                    droppableId={droppableIdPrefixes.blocks}
                    direction="horizontal"
                    isDropDisabled={context.isDraggingPhoto}
                >
                    {providedRoot => (
                        <div
                            ref={providedRoot.innerRef}
                            className={`block-list ${styles.blockList}`}
                        >
                            {sortedBlocks.map((block, index, array) => (
                                <DraggableBlock
                                    key={block.id}
                                    block={block}
                                    index={index}
                                >
                                    <div style={{ display: "flex" }}>
                                        <Block
                                            block={block}
                                            index={index}
                                            onEditButtonClick={
                                                onEditButtonClick
                                            }
                                            onWalkInOrOutBlockMediaPlaceholderClick={
                                                onWalkInOrOutBlockMediaPlaceholderClick
                                            }
                                            isBlockReorderModeEnabled={
                                                isBlockReorderModeEnabled
                                            }
                                            speechPhotoLimit={speechPhotoLimit}
                                        />
                                        <ShowIf
                                            condition={
                                                index !== array.length - 1
                                            }
                                        >
                                            <div
                                                className={classNames({
                                                    [styles.addBlockButton]: true,
                                                    "tutorial-step-3":
                                                        index === 0
                                                })}
                                                onClick={() =>
                                                    onAddButtonClick(index + 1)
                                                }
                                            >
                                                <span className="icon">
                                                    <i className="fas fa-plus" />
                                                </span>
                                            </div>
                                        </ShowIf>
                                    </div>
                                </DraggableBlock>
                            ))}
                            {providedRoot.placeholder}
                        </div>
                    )}
                </Droppable>
            )}
        </CustomDragDropContext.Consumer>
    );
};

export default DroppableBlockView;
