import React, { Component } from "react";
import UsersView from "./UsersView";
import routeNames from "../../../constants/routeNames";
import createApiService from "../../../network";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { showModal } from "../../../actions/modals";
import modalTypes from "../../../constants/modalTypes";

class UsersContainer extends Component {
    state = {
        organizationUsers: null,
        isFetching: true
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    componentDidMount() {
        if (this.state.organizationUsers === null) {
            this.getOrganizationUsers();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    getOrganizationUsers = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const { data } = await this.api.getOrganizationUsers(
                this.props.match.params.organizationID
            );

            this.setState({
                organizationUsers: data.data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    onAddButtonClick = () => {
        this.props.dispatch(
            showModal({
                type: modalTypes.ORGANIZATION_USER_MANAGEMENT,
                props: {
                    organizationId: this.props.match.params.organizationID,
                    reloadUsers: this.getOrganizationUsers
                }
            })
        );
    };

    render() {
        return (
            <UsersView
                organizationUsers={this.state.organizationUsers}
                isFetching={this.state.isFetching}
                onAddButtonClick={this.onAddButtonClick}
                onShowUser={id => {
                    this.props.history.push(
                        `${routeNames.userDetail}/`.replace(":userID", id)
                    );
                }}
            />
        );
    }
}

export default withRouter(UsersContainer);
