export const getRoomEntities = state => state.entities.rooms;

export const getRoomsById = state => getRoomEntities(state).byId;
export const getRoomsAllIds = state => getRoomEntities(state).allIds;
export const getRoomById = (state, props) => getRoomsById(state)[props.roomId];

export const getCrematoryRooms = (state, crematoryId) => {
    const crematory = state.entities.crematories.byId[crematoryId];
    return Object.values(state.entities.rooms.byId).filter(room =>
        crematory.item.rooms.includes(room.id)
    );
};
