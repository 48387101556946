import React from "react";
import { isNumber } from "micro-dash";

import i18n from "../../constants/i18n";

const TotalDataUsageView = props => (
    <span>
        {i18n.generic.totalDataUsage}
        {isNumber(props.valueInGb) ? props.valueInGb : ""} GB
    </span>
);

export default TotalDataUsageView;
