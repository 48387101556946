import React, { Component } from "react";

import i18n from "../../../../constants/i18n";

import photoIcon from "../../../../assets/photo.svg";
import musicIcon from "../../../../assets/music.svg";
import speakerIcon from "../../../../assets/speaker.svg";
import videoIcon from "../../../../assets/video.svg";

import BlockTypeModalView from "./BlockTypeModalView";

class BlockTypeModalContainer extends Component {
    itemTypes = {
        MUSIC: "music",
        SPEAKER: "speaker",
        VIDEO: "video"
    };

    constructor(props) {
        super(props);

        this.items = [
            {
                id: this.itemTypes.MUSIC,
                label: i18n.blockTypeModal.musicType,
                icon: musicIcon,
                altAttr: "Music disk",
                clickHandler: () => this.onSelect(this.itemTypes.MUSIC)
            },
            {
                id: this.itemTypes.SPEAKER,
                label: i18n.blockTypeModal.speechType,
                icon: speakerIcon,
                altAttr: "A speaking person",
                clickHandler: () => this.onSelect(this.itemTypes.SPEAKER)
            },
            {
                id: this.itemTypes.VIDEO,
                label: i18n.blockTypeModal.videoType,
                icon: videoIcon,
                altAttr: "Monitor displaying a play button",
                clickHandler: () => this.onSelect(this.itemTypes.VIDEO)
            }
        ];

        if (this.props.isEditing) {
            const speakerIndex = this.items.findIndex(
                ({ id }) => id === this.itemTypes.SPEAKER
            );
            this.items[speakerIndex].label = i18n.blockTypeModal.photoType;
            this.items[speakerIndex].icon = photoIcon;
            this.items[speakerIndex].altAttr = "Photo placeholder";
        }

        if (props.hideSpeechType) {
            this.items = this.items.filter(
                item => item.id !== this.itemTypes.SPEAKER
            );
        }
    }

    onSelect = type => {
        this.props.hideModal();

        switch (type) {
            case this.itemTypes.MUSIC:
                this.props.showMusicUploadModal();
                break;
            case this.itemTypes.SPEAKER:
                this.props.showSpeakerModal();
                break;
            case this.itemTypes.VIDEO:
                this.props.showVideoUploadModal();
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <BlockTypeModalView
                hideModal={() => this.props.hideModal()}
                isVisible={true}
                onSelect={this.onSelect}
                items={this.items}
                isEditing={this.props.isEditing}
            />
        );
    }
}

export default BlockTypeModalContainer;
