import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import {
    photo as photoSchema,
    block as blockSchema
} from "../../../../schemas";
import { extractApiErrorMessage } from "../../../../helpers";

import BlockImageRotateModalView from "./BlockImageRotateModalView";

class BlockImageRotateModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            rotation: 0,
            scale: 1,
            isSubmitting: false
        };
    }

    onRotationChange = (width, height) => {
        let targetRotation;
        let targetScale;

        if (this.state.rotation === -270) {
            targetRotation = 0;
        } else {
            targetRotation = this.state.rotation - 90;
        }

        if (Math.abs(targetRotation) % 180) {
            targetScale = height / width;
        } else {
            targetScale = 1;
        }

        this.setState({ rotation: targetRotation, scale: targetScale });
    };

    onSubmit = async () => {
        try {
            // If image is not rotated, return to block edit modal
            if (this.state.rotation === 0) {
                this.props.hideModal();
                this.props.openBlockEditModal(
                    this.props.ceremony.id,
                    this.props.block.id
                );
                return;
            }

            this.setState({ isSubmitting: true });

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("orientation", this.state.rotation * -1);

            const {
                data: { data: rotatedImage }
            } = await this.api.postPhotoAndRotate(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            const existingPhotoIndex = this.props.block.imageIds.findIndex(
                item => item === this.props.photo.id
            );
            const imageIds = [...this.props.block.imageIds];
            imageIds[existingPhotoIndex] = rotatedImage.id;

            const {
                data: { data: updatedBlock }
            } = await this.api.putBlock(this.props.ceremony.id, {
                ...this.props.block,
                imageIds
            });

            const { entities: blockEntities } = normalize(
                updatedBlock,
                blockSchema
            );
            this.props.updateStoreEntities(blockEntities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.openBlockEditModal(
                this.props.ceremony.id,
                this.props.block.id
            );
            this.props.showToast({
                body: i18n.generic.updatedBlockSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onReturnToImageAdjustmentModal = () => {
        this.props.hideModal();
        this.props.showPhotoAdjustmentModal(
            this.props.blockId,
            this.props.photo.id,
            this.props.ceremonyId
        );
    };

    render() {
        return (
            <BlockImageRotateModalView
                hideModal={this.props.hideModal}
                isSubmitting={this.state.isSubmitting}
                isVisible={this.props.isVisible}
                onRotationChange={this.onRotationChange}
                onSubmit={this.onSubmit}
                onReturnToImageAdjustmentModal={
                    this.onReturnToImageAdjustmentModal
                }
                rotation={this.state.rotation}
                scale={this.state.scale}
                src={this.state.src}
            />
        );
    }
}

export default withRouter(BlockImageRotateModalContainer);
