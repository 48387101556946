import React from "react";
import { Droppable } from "react-beautiful-dnd";

import ShowIf from "../../ShowIf";
import { droppableIdPrefixes } from "../constants";
import { CustomDragDropContext } from "../DragDropRoot";

const DroppableBlockRemovalView = ({ render }) => {
    return (
        <CustomDragDropContext.Consumer>
            {context => (
                <ShowIf condition={context.showBlockRemovalBox}>
                    <Droppable droppableId={droppableIdPrefixes.bin}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {render(snapshot)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </ShowIf>
            )}
        </CustomDragDropContext.Consumer>
    );
};

export default DroppableBlockRemovalView;
