import React from "react";

import styles from "./Table.module.scss";

import TableItem from "../TableItem";

const DeviceTable = ({ devices, onEdit, onDelete, rooms }) => {
    return (
        <div>
            <table
                className={`table is-hoverable is-fullwidth ${styles.table}`}
            >
                <tbody>
                    {devices.map(device => (
                        <TableItem
                            key={device.id}
                            device={device}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            rooms={rooms}
                        ></TableItem>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DeviceTable;
