import React from "react";
import { ErrorMessage, Form } from "formik";
import { isString, sortBy } from "micro-dash";
import i18n from "../../../../constants/i18n";
import styles from "./OrganizationUserManagementModal.module.scss";
import Input from "../../../Input";
import ShowIf from "../../../ShowIf";
import Select from "../../../Select";

const OrganizationUserManagementModalView = ({
    errors,
    hideModal,
    isFetching,
    isSubmitting,
    isVisible,
    submitForm,
    touched,
    handleBlur,
    setFieldValue,
    locations,
    values
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.organizationUserManagementModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p className={styles.description}>
                        {i18n.organizationUserManagementModal.description}
                    </p>
                    <ShowIf condition={isFetching}>
                        <div className={`loader ${styles.loader}`} />
                    </ShowIf>
                    <ShowIf condition={!isFetching}>
                        <Form>
                            <Input
                                errors={errors}
                                name={`name`}
                                label={i18n.generic.name}
                                placeholder={i18n.generic.name}
                                smallerMargin
                                touched={touched}
                                value={values.name}
                            />
                            <Input
                                errors={errors}
                                name={`email`}
                                label={i18n.generic.email}
                                placeholder={i18n.generic.email}
                                smallerMargin
                                touched={touched}
                                value={values.email}
                            />
                            <Select
                                errors={errors}
                                touched={touched}
                                name="crematorium"
                                label={
                                    i18n.organizationUserManagementModal
                                        .location
                                }
                                placeholder={
                                    i18n.organizationUserManagementModal
                                        .locationPlaceholder
                                }
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                value={values.location}
                                items={locations}
                            />
                        </Form>
                    </ShowIf>
                    <ShowIf condition={isString(errors.admins)}>
                        <ErrorMessage
                            name="admins"
                            component="p"
                            className="help is-danger is-size-6"
                        />
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default OrganizationUserManagementModalView;
