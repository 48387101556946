import React from "react";

import DroppableBlockList from "../DroppableBlockList";

const BlocksView = ({
    blocks,
    isBlockReorderModeEnabled,
    onAddButtonClick,
    onEditButtonClick,
    onWalkInOrOutBlockMediaPlaceholderClick,
    speechPhotoLimit
}) => {
    return (
        <div className="tutorial-step-2">
            <DroppableBlockList
                blocks={blocks}
                isBlockReorderModeEnabled={isBlockReorderModeEnabled}
                onAddButtonClick={onAddButtonClick}
                onEditButtonClick={onEditButtonClick}
                onWalkInOrOutBlockMediaPlaceholderClick={
                    onWalkInOrOutBlockMediaPlaceholderClick
                }
                speechPhotoLimit={speechPhotoLimit}
            ></DroppableBlockList>
        </div>
    );
};

export default BlocksView;
