// @ts-check
import cuid from "cuid";
import { times } from "micro-dash";
import React from "react";

import ShowIf from "../../ShowIf";
import TimelinePhoto from "../../TimelinePhoto";
import DraggablePhoto from "../DraggablePhoto";
import styles from "./Photos.module.scss";
import i18n from "../../../constants/i18n";

function splitArrayIntoPairs(initialArray) {
    return initialArray.reduce(function(result, _, index, array) {
        if (index % 2 === 0) {
            result.push(array.slice(index, index + 2));
        }

        return result;
    }, []);
}

function isPlaceholderPhoto(photo) {
    return photo.isPlaceholder;
}

const PhotosView = ({
    photos,
    ceremonyId,
    ceremony,
    showPhotoUploadModal,
    showPhotoAdjustmentModal,
    deletePhoto,
    useBlurredPhotoBackground,
    onBlurredBackgroundClick,
    blurredBackgroundEnabledCheck
}) => {
    const items =
        photos.length > 24
            ? photos
            : times(24, n => photos[n] || { id: cuid(), isPlaceholder: true });

    const indexedItems = items.map((photo, index) => ({ ...photo, index }));
    const pairs = splitArrayIntoPairs(indexedItems);

    let counter = 1;

    return (
        <div className={`${styles.root} tutorial-step-1`}>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h2 className={styles.title}>Foto's</h2>
                        <div
                            onClick={() => showPhotoUploadModal()}
                            className="icon-container is-round is-clickable"
                        >
                            <span className="icon is-small">
                                <i className="fas fa-s fa-plus" />
                            </span>
                        </div>
                    </div>
                    <ShowIf condition={blurredBackgroundEnabledCheck}>
                        <div className="level-item">
                            <button
                                className="button is-rounded tutorial-step-5"
                                onClick={() =>
                                    onBlurredBackgroundClick(ceremony)
                                }
                            >
                                <ShowIf condition={useBlurredPhotoBackground}>
                                    {i18n.photos.blackBackground}
                                </ShowIf>
                                <ShowIf condition={!useBlurredPhotoBackground}>
                                    {i18n.photos.blurredBackground}
                                </ShowIf>
                            </button>
                        </div>
                    </ShowIf>
                </div>
            </div>
            <div className={`tile is-ancestor ${styles.tileAncestor}`}>
                {pairs.map((pair, index) => (
                    <div
                        key={index}
                        className="tile is-parent is-vertical photo-pair-container"
                    >
                        {pair.map((photo, secondIndex) => (
                            <div className="tile is-child" key={photo.id}>
                                <ShowIf condition={isPlaceholderPhoto(photo)}>
                                    <div className={styles.spacer}></div>
                                    <TimelinePhoto
                                        photo={photo}
                                        showPhotoUploadModal={
                                            showPhotoUploadModal
                                        }
                                    />
                                </ShowIf>
                                <ShowIf condition={!isPlaceholderPhoto(photo)}>
                                    <div className={styles.editButtonContainer}>
                                        <ShowIf condition={photo.fileName}>
                                            <span
                                                className={`icon is-small ${styles.deleteButton}`}
                                                onClick={() =>
                                                    showPhotoAdjustmentModal(
                                                        ceremonyId,
                                                        photo.id
                                                    )
                                                }
                                            >
                                                <i className="fas fa-pen"></i>
                                            </span>
                                        </ShowIf>
                                        <ShowIf
                                            condition={
                                                photo.fileName ||
                                                photo.processingFailure
                                            }
                                        >
                                            <span
                                                className={`icon is-small ${styles.deleteButton}`}
                                                onClick={() =>
                                                    deletePhoto(photo.id)
                                                }
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </span>
                                        </ShowIf>
                                    </div>

                                    <DraggablePhoto
                                        photo={photo}
                                        displayableIndex={counter++}
                                        render={({
                                            snapshot,
                                            placeholder,
                                            selectionCount,
                                            isSelected,
                                            isGhosting
                                        }) => (
                                            <React.Fragment>
                                                <TimelinePhoto
                                                    photo={photo}
                                                    isSelected={
                                                        !snapshot.isDragging &&
                                                        isSelected
                                                    }
                                                />
                                                <ShowIf
                                                    condition={
                                                        snapshot.isDragging &&
                                                        selectionCount > 1
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.selectionCount
                                                        }
                                                    >
                                                        {selectionCount}
                                                    </div>
                                                </ShowIf>
                                            </React.Fragment>
                                        )}
                                    ></DraggablePhoto>
                                </ShowIf>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PhotosView;
