import React from "react";
import { matchPath, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "micro-dash";

import styles from "./PrivateRoutes.module.scss";

import routeNames from "../constants/routeNames";
import roles from "../constants/roles";

import Navbar from "../components/Navbar";
import PrivateRoute from "./PrivateRoute";
import CrematoriesRoute from "./views/Crematories";
import SelectCeremonyRoute from "./views/SelectCeremony";
import CeremoniesRoute from "./views/Ceremonies";
import DevicesRoute from "./views/Devices";
import TimelineRoute from "./views/Timeline";
import RoomsRoute from "./views/Rooms";
import UsersRoute from "./views/Users";
import UserDetailRoute from "./views/UserDetail";
import EditUserDetailRoute from "./views/EditUserDetail";
import OrganizationsRoute from "./views/Organizations";
import VerifyEmailRoute from "./views/VerifyEmail";
import ChangePassword from "./views/ChangePassword";
import VerifyEmailGuard from "./VerifyEmailGuard";
import Footer from "../components/Footer";
import OrganizationDetail from "./views/OrganizationDetail";
import ExternalCeremoniesRoute from "./views/ExternalCeremonies";

const getRedirectPath = (role, crematories, ceremonies) => {
    if (role === roles.admin) return `${routeNames.locationsFirstPage}`;
    if (role === roles.crematory) {
        return `${routeNames.locations}/${get(Object.values(crematories), [
            "0",
            "item",
            "id"
        ])}`;
    }
    if (role === roles.organization) {
        return `${routeNames.locationsFirstPage}`;
    }
    if (role === roles.endUser) {
        return `${routeNames.selectCeremony}`;
    }
};

const getCrematoryLogo = (role, crematories, history) => {
    // Workaround for react-router-dom that does not always provide full route params
    // Read more here: https://github.com/ReactTraining/react-router/issues/5870
    const match = matchPath(history.location.pathname, {
        path: routeNames.locationDetails,
        exact: false,
        strict: false
    });

    const routeCrematoryId = get(match, ["params", "crematoryID"]);

    // Admin role
    if (routeCrematoryId && crematories[routeCrematoryId]) {
        return get(crematories[routeCrematoryId], ["item", "logo"]);
    }

    // Crematory role
    if (role === roles.crematory) {
        return get(Object.values(crematories), ["0", "item", "logo"]);
    }

    // For now, API doesn't provide a way to fetch crematory when logged in as an EndUser
};

const PrivateRoutes = ({
    roles,
    crematories,
    ceremonies,
    username,
    history
}) => {
    const role = roles[0];

    return (
        <div className={`hero is-fullheight ${styles.container}`}>
            <div className="hero-head">
                <Navbar
                    username={username}
                    showMenu
                    extraLogo={getCrematoryLogo(role, crematories, history)}
                />
            </div>
            <div className="hero-body is-top-aligned">
                <PrivateRoute
                    path={routeNames.verifyEmail}
                    exact
                    component={VerifyEmailRoute}
                />
                <VerifyEmailGuard>
                    <Switch>
                        <PrivateRoute
                            path={routeNames.selectCeremony}
                            exact
                            component={SelectCeremonyRoute}
                        />
                        <PrivateRoute
                            path={routeNames.locationsPaginated}
                            exact
                            component={CrematoriesRoute}
                        />
                        <PrivateRoute
                            path={routeNames.locationDetailsPaginated}
                            exact
                            component={CeremoniesRoute}
                        />
                        <PrivateRoute
                            path={routeNames.locationDetails}
                            exact
                            component={CeremoniesRoute}
                        />
                        <PrivateRoute
                            path={routeNames.invitedCeremonies}
                            exact
                            component={ExternalCeremoniesRoute}
                        />
                        <Redirect
                            path={`${routeNames.locationDetails}/reload`}
                            exact
                            to={routeNames.locationDetails}
                        />
                        <PrivateRoute
                            path={routeNames.timeline}
                            exact
                            component={TimelineRoute}
                        />
                        <PrivateRoute
                            path={routeNames.rooms}
                            exact
                            component={RoomsRoute}
                        />
                        <PrivateRoute
                            path={routeNames.devices}
                            exact
                            component={DevicesRoute}
                        />
                        <PrivateRoute
                            path={routeNames.app + routeNames.resetPassword}
                            exact
                            component={ChangePassword}
                        />
                        <PrivateRoute
                            path={routeNames.usersPaginated}
                            exact
                            component={UsersRoute}
                        />
                        <PrivateRoute
                            path={routeNames.searchUsersPaginated}
                            exact
                            component={UsersRoute}
                        />
                        <PrivateRoute
                            path={routeNames.userDetail}
                            exact
                            component={UserDetailRoute}
                        />
                        <PrivateRoute
                            path={routeNames.editUserDetail}
                            exact
                            component={EditUserDetailRoute}
                        />
                        <PrivateRoute
                            path={routeNames.organizations}
                            exact
                            component={OrganizationsRoute}
                        />
                        <PrivateRoute
                            path={routeNames.organizationDetail}
                            exact
                            component={OrganizationDetail}
                        />
                        <PrivateRoute
                            path={routeNames.organizationsPaginated}
                            exact
                            component={OrganizationsRoute}
                        />
                        <PrivateRoute
                            path={routeNames.searchOrganizationsPaginated}
                            exact
                            component={OrganizationsRoute}
                        />
                        <Route
                            render={() => (
                                <Redirect
                                    to={getRedirectPath(
                                        role,
                                        crematories,
                                        ceremonies
                                    )}
                                />
                            )}
                        />
                    </Switch>
                </VerifyEmailGuard>
            </div>
            <div className={`hero-foot ${styles.heroFoot}`}>
                <Footer />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: get(state, ["user", "profile"]),
        roles: get(state, ["user", "profile", "roles"]),
        username: get(state, ["user", "profile", "name"]),
        organizations: get(state, ["entities", "organizations", "byId"]),
        crematories: get(state, ["entities", "crematories", "byId"]),
        ceremonies: get(state, ["entities", "ceremonies", "byId"]),
        lastLocationPage: get(state, "lastLocationPage")
    };
};

export default connect(mapStateToProps)(PrivateRoutes);
