import React from "react";

import styles from "./DeactivatedCrematoryModal.module.scss";

import i18n from "../../../../constants/i18n";

const DeactivatedCrematoryModalView = ({ logout, isVisible }) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.deactivatedCrematoryModal.title}
                    </h2>
                </header>
                <section className="modal-card-body">
                    <p className={styles.text}>
                        {i18n.deactivatedCrematoryModal.body}
                    </p>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={logout}
                        className="button is-primary is-rounded"
                    >
                        {i18n.deactivatedCrematoryModal.footerButtonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DeactivatedCrematoryModalView;
