import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import {
    INVALIDATE_CREMATORY,
    FETCH_CREMATORY_REQUEST,
    FETCH_CREMATORY_SUCCESS,
    FETCH_CREMATORY_FAILURE,
    PUT_CREMATORIES,
    DELETE_CREMATORY
} from "../constants/actionTypes";

import { crematory as crematorySchema } from "../schemas";
import { putNormalizedEntities } from "../helpers";

export function invalidateCrematory(id) {
    return {
        type: INVALIDATE_CREMATORY,
        payload: id
    };
}

export function fetchCrematoryRequest(id) {
    return {
        type: FETCH_CREMATORY_REQUEST,
        payload: id
    };
}

export function fetchCrematorySuccess(payload) {
    return {
        type: FETCH_CREMATORY_SUCCESS,
        payload
    };
}

export function fetchCrematoryFailure(id, error) {
    return {
        type: FETCH_CREMATORY_FAILURE,
        payload: id,
        error
    };
}

export function putCrematories(payload) {
    return {
        type: PUT_CREMATORIES,
        payload
    };
}

export function deleteCrematory(payload) {
    return {
        type: DELETE_CREMATORY,
        payload
    };
}

export function fetchCrematory(id) {
    return function(dispatch, _, { api }) {
        dispatch(fetchCrematoryRequest(id));

        return api
            .getCrematoryById(id)
            .then(async response => {
                const { entities } = normalize(
                    response.data.data,
                    crematorySchema
                );
                putNormalizedEntities(dispatch, entities);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error(error);
                dispatch(fetchCrematoryFailure(id, error));
            });
    };
}

function shouldFetchCrematory(state, id) {
    const crematory = state.entities.crematories.byId[id];

    if (!crematory) {
        return true;
    }

    if (crematory.isFetching) {
        return false;
    } else {
        return crematory.didInvalidate;
    }
}

export function fetchCrematoryIfNeeded(id) {
    return (dispatch, getState) => {
        if (shouldFetchCrematory(getState(), id)) {
            return dispatch(fetchCrematory(id));
        } else {
            return Promise.resolve();
        }
    };
}

export function fetchUserAssignedCrematory() {
    return function(dispatch, _, { api }) {
        return api.getCrematoriesPaginated().then(async response => {
            return dispatch(fetchCrematoryIfNeeded(response.data.data[0].id));
        });
    };
}
