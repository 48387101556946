import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import * as Sentry from "@sentry/browser";
import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import { extractApiErrorMessage } from "../../../../helpers";
import ValidationError from "../../../../helpers/ValidationError";
import roles from "../../../../constants/roles";

import OrganizationUserManagementModalView from "./OrganizationUserManagementModalView";

class OrganizationUserManagementModalContainer extends Component {
    initialValues = {
        email: "",
        name: "",
        crematorium: undefined,
        role: roles.crematory
    };

    validationSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        email: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required),
        crematorium: Yup.number()
    });

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            isFetching: true,
            locations: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        this.setState({ isFetching: true });

        await this.getLocationsByOrganizationId();

        if (this._isMounted) {
            this.setState({ isFetching: false });
        }
    }

    getLocationsByOrganizationId = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getLocationsByOrganizationId(
                this.props.organizationId
            );

            const { data } = response.data;

            this.setState({
                locations: data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    onSubmit = async (values, formikBag) => {
        try {
            let results = [];

            // Invite Crematorium User
            try {
                await this.api.inviteAdmin(values);
                results.push({
                    failed: false,
                    errors: []
                });
            } catch (e) {
                if (e instanceof ValidationError) {
                    results.push({
                        failed: true,
                        errors: e.errors
                    });
                }
            }

            const hasAnyRequestFailed = results.some(result => result.failed);

            if (hasAnyRequestFailed) {
                results.forEach(({ failed, errors }, index) => {
                    if (failed) {
                        formikBag.setFieldError(
                            `admins[${index}]`,
                            errors,
                            true
                        );
                    }
                });
            }

            formikBag.setSubmitting(false);

            if (
                results.length &&
                results.every(result => result.failed === false)
            ) {
                this.props.hideModal();
                this.props.showToast({
                    body: i18n.generic.updateAdminsSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.reloadUsers();
            }
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            formikBag.setSubmitting(false);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
            >
                {props => (
                    <OrganizationUserManagementModalView
                        currentUserId={this.props.currentUserId}
                        hideModal={this.props.hideModal}
                        isFetching={this.state.isFetching}
                        isVisible={this.props.isVisible}
                        locations={this.state.locations}
                        {...props}
                    />
                )}
            </Formik>
        );
    }
}

export default withRouter(OrganizationUserManagementModalContainer);
