import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../network";

import PhotosView from "./PhotosView";

class PhotosContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }
    render() {
        return <PhotosView {...this.props} />;
    }
}

export default withRouter(PhotosContainer);
