import React from "react";

import OrganizationOverview from "../../components/OrganizationOverview";

const OrganizationsRoute = props => {
    return (
        <div className="container">
            <OrganizationOverview></OrganizationOverview>
        </div>
    );
};

export default OrganizationsRoute;
