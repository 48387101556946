import React from "react";

import styles from "./Table.module.scss";

import TableItem from "../TableItem";

const RoomsTable = ({ devices, isAdmin, rooms, onDelete, onEdit }) => {
    return (
        <div>
            <table
                className={`table is-hoverable is-fullwidth ${styles.table}`}
            >
                <tbody>
                    {rooms.map(room => (
                        <TableItem
                            key={room.id}
                            devices={devices}
                            isAdmin={isAdmin}
                            room={room}
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RoomsTable;
