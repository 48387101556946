import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { putNormalizedEntities } from "../../../helpers";

import PhotosContainer from "./PhotosContainer";
import { hideModal, showModal } from "../../../actions/modals";
import modalTypes from "../../../constants/modalTypes";

const mapStateToProps = (state, ownProps) => {
    const ceremonyId = parseInt(ownProps.match.params.ceremonyID);
    const photos = Object.values(state.entities.photos.byId).filter(
        photo => photo.ceremonyId === ceremonyId
    );

    return {
        ceremonyId,
        photos
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities),
        showPhotoAdjustmentModal: (ceremonyId, photoId) => {
            dispatch(hideModal());
            dispatch(
                showModal({
                    type: modalTypes.LIBRARY_PHOTO_ADJUSTMENT,
                    props: {
                        ceremonyId: parseInt(ceremonyId),
                        photoId
                    }
                })
            );
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PhotosContainer)
);
