import { connect } from "react-redux";

import { showToast } from "../../actions/toasts";

import ResetPasswordFormContainer from "./ResetPasswordFormContainer";

const mapDispatchToProps = dispatch => {
    return {
        showToast: options => dispatch(showToast(options))
    };
};

export default connect(
    undefined,
    mapDispatchToProps
)(ResetPasswordFormContainer);
