class ValidationError extends Error {
    constructor(errors, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }

        this.name = "ValidationError";
        this.errors = errors;
    }
}

export default ValidationError;
