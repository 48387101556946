import { connect } from "react-redux";

import { fetchCrematoryIfNeeded } from "../../../actions/crematories";
import TableItemView from "./TableItemView";
import roleTypes from "../../../constants/roles";

const mapStateToProps = (state, ownProps) => {
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);

    return {
        crematory: state.entities.crematories.byId[ownProps.crematoryId],
        isAdmin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDataIfNeeded: () => {
            dispatch(fetchCrematoryIfNeeded());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableItemView);
