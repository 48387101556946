import React from "react";
import T from "prop-types";
import { ErrorMessage } from "formik";

import styles from "./Select.module.scss";
import ShowIfView from "../ShowIf";
import { FormNotice } from "../FormNotice/FormNotice";

const Select = ({
    errors,
    disabled,
    handleBlur,
    items,
    label,
    name,
    placeholder,
    setFieldValue,
    touched,
    value,
    notice
}) => {
    return (
        <div className={`field ${styles.field}`}>
            <label className="label">{label}</label>
            <div className="control has-icons-right">
                <div
                    className={`select ${
                        errors[name] && touched[name] ? "is-danger" : ""
                    } ${styles.isBig}`}
                >
                    <select
                        name={name}
                        value={value}
                        onBlur={handleBlur}
                        onChange={event =>
                            setFieldValue(
                                name,
                                parseInt(event.target.value) || ""
                            )
                        }
                        disabled={disabled}
                    >
                        <option value="">{placeholder}</option>
                        {items.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <ErrorMessage name={name}>
                    <span className="icon is-right has-text-danger">
                        <i className="fas fa-exclamation-circle " />
                    </span>
                </ErrorMessage>
            </div>
            <ErrorMessage
                name={name}
                component="p"
                className="help is-danger is-size-6"
            />
            <ShowIfView condition={notice}>
                <FormNotice>{notice}</FormNotice>
            </ShowIfView>
        </div>
    );
};

Select.propTypes = {
    errors: T.object.isRequired,
    handleBlur: T.func.isRequired,
    items: T.arrayOf(
        T.shape({
            id: T.number.isRequired,
            name: T.string.isRequired
        })
    ).isRequired,
    label: T.string,
    name: T.string.isRequired,
    placeholder: T.string,
    setFieldValue: T.func.isRequired,
    touched: T.object.isRequired,
    value: T.oneOfType([T.string, T.number])
};

export default Select;
