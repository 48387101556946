import React from "react";

import UserOverview from "../../components/UserOverview";

const UsersRoute = props => {
    return (
        <div className="container">
            <UserOverview></UserOverview>
        </div>
    );
};

export default UsersRoute;
