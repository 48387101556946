import { connect } from "react-redux";
import roleTypes from "../../constants/roles";
import UserDetailContainer from "./UserDetailContainer";
import { get } from "micro-dash";
import { withRouter } from "react-router-dom";
import { startImpersonation } from "../../actions/user";

function getUserIdFromRouteParams(match) {
    const id = get(match, ["params", "userID"], null);
    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = (state, props) => {
    const userDetailId = getUserIdFromRouteParams(props.match);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);

    return {
        user: state.entities.users.byId[userDetailId],
        isAdmin
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        startImpersonation: (userId, username) => {
            dispatch(startImpersonation(userId, username, props));
        },
        dispatch
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserDetailContainer)
);
