import React from "react";
import { Form } from "formik";

import i18n from "../../../../constants/i18n";
import ShowIf from "../../../ShowIf";
import UploadField from "../../../UploadField";
import styles from "./MusicUploadModal.module.scss";

import { List, Search } from "./Library";

const NoResults = () => {
    return (
        <span className={styles.noResults}>
            {i18n.musicUploadModal.noResultsWarning}
        </span>
    );
};

const MusicUploadModalView = ({
    block,
    dirty,
    errors,
    hideModal,
    isEditing,
    isSubmitting,
    isVisible,
    setFieldValue,
    status,
    submitForm,
    supportedFormats,
    values,
    ...rest
}) => {
    const {
        hasSearchedAtLeastOnce,
        libraryItems,
        libraryLoading,
        libraryQuery,
        onQueryChange,
        playbackActive,
        playbackDuration,
        playbackTime,
        playbackTrackID,
        setPlaybackTime,
        togglePlayback
    } = rest;

    return (
        <div
            className={`modal upload-modal ${
                isSubmitting ? "is-uploading" : ""
            } ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.musicUploadModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <Search
                            isLoading={libraryLoading}
                            onChange={onQueryChange}
                            query={libraryQuery}
                        />
                        {!libraryItems.length &&
                        hasSearchedAtLeastOnce &&
                        libraryQuery.length > 2 &&
                        !libraryLoading ? (
                            <NoResults />
                        ) : (
                            <List
                                playbackActive={playbackActive}
                                playbackDuration={playbackDuration}
                                playbackTime={playbackTime}
                                playbackTrackID={playbackTrackID}
                                onSelect={track => {
                                    togglePlayback(false);
                                    if (
                                        values.libraryTrack === track.trackToken
                                    ) {
                                        setFieldValue("libraryTrack", null);
                                        setFieldValue("libraryArtist", null);
                                        setFieldValue("librarySongName", null);
                                        setFieldValue("libraryDuration", 0);
                                    } else {
                                        setFieldValue(
                                            "libraryTrack",
                                            track.trackToken
                                        );
                                        setFieldValue(
                                            "libraryArtist",
                                            track.artist
                                        );
                                        setFieldValue(
                                            "librarySongName",
                                            track.title
                                        );
                                        setFieldValue(
                                            "libraryDuration",
                                            track.duration
                                        );
                                    }
                                }}
                                selectedTrackID={values.libraryTrack}
                                setPlaybackTime={setPlaybackTime}
                                togglePlayback={togglePlayback}
                                tracks={libraryItems}
                            />
                        )}

                        <UploadField
                            label={i18n.musicUploadModal.uploadFieldLabel}
                            name="audioTrack"
                            setFieldValue={setFieldValue}
                            value={values.audioTrack}
                            fileTypes={supportedFormats}
                        />
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                    <ShowIf condition={values.audioTrack}>
                        <div className="progress">
                            <span
                                className="progress-bar"
                                style={{
                                    width: `${(status &&
                                        status.uploadProgress) ||
                                        0}%`
                                }}
                            ></span>
                        </div>
                    </ShowIf>
                    <div className="upload-loader-container">
                        <div className="loader upload-loader"></div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default MusicUploadModalView;
