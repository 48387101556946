import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "formik";

import styles from "./DateField.module.scss";

const DatePreview = forwardRef(
    ({ errors, name, onClick, touched, value }, ref) => (
        <div
            ref={ref}
            onClick={onClick}
            className={`input is-rounded ${
                errors[name] && touched[name] ? "is-danger" : ""
            } ${styles.dateInput}`}
        >
            <span>{value}</span>
        </div>
    )
);

const DateFieldView = ({
    errors,
    label,
    name,
    setFieldValue,
    touched,
    values
}) => {
    return (
        <div className={`field ${styles.field}`}>
            <label className="label">{label}</label>
            <div className="control has-icons-right">
                <DatePicker
                    minDate={new Date()}
                    selected={values[name]}
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={e => setFieldValue("date", e)}
                    showTimeSelect
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    customInput={
                        <DatePreview
                            errors={errors}
                            touched={touched}
                            name={name}
                        />
                    }
                />
                <span className="icon is-right">
                    <i className="far fa-calendar-alt"></i>
                </span>
            </div>
            <ErrorMessage
                name={name}
                component="p"
                className="help is-danger is-size-6"
            />
        </div>
    );
};

export default DateFieldView;
