import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";

import i18n from "../../constants/i18n";

import LoginFormView from "./LoginFormView";
import createApiService from "../../network";
import ValidationError from "../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../helpers";

class LoginFormContainer extends Component {
    initialValues = {
        email: "",
        password: ""
    };

    validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required),
        password: Yup.string().required(i18n.validation.required)
    });

    onSubmit = async (values, formikBag) => {
        const api = createApiService(axios);

        try {
            const response = await api.login(values);

            formikBag.setSubmitting(false);
            this.props.onLogin(response.data);
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <LoginFormView
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default LoginFormContainer;
