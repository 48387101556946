import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import BlockView from "./BlockView";

const mapStateToProps = (state, ownProps) => {
    const ceremonyId = parseInt(ownProps.match.params.ceremonyID);
    const ceremony = state.entities.ceremonies.byId[ceremonyId];
    const ceremonyMainImage =
        state.entities.photos.byId[ceremony.item.mainImageId];

    return {
        alternativePlaceholderImage: ceremonyMainImage
    };
};

export default withRouter(connect(mapStateToProps)(BlockView));
