import React from "react";
import { Form, ErrorMessage, FieldArray } from "formik";
import { isString } from "micro-dash";

import styles from "./CrematoryModal.module.scss";

import i18n from "../../../../constants/i18n";

import AspectRatioField from "../../../AspectRatioField";
import Checkbox from "../../../Checkbox";
import Input from "../../../Input";
import ShowIf from "../../../ShowIf";
import UploadField from "../../../UploadField";
import FieldArrayAddButton from "../../../FieldArrayAddButton";
import Select from "../../../Select";

const fileTypes = ["image/png", "image/jpeg"];

const CrematoryModalView = ({
    isEditing,
    isVisible,
    isFetching,
    hideModal,
    setFieldValue,
    errors,
    touched,
    values,
    submitForm,
    isSubmitting,
    deleteCrematory,
    setActiveStatus,
    handleBlur,
    organizations,
    ...props
}) => {
    // console.log(values);
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <ShowIf condition={isEditing}>
                        <span
                            className={`icon has-text-danger is-medium ${styles.deleteButton}`}
                            onClick={() => deleteCrematory(values.id)}
                        >
                            <i className="fas fa-trash-alt fa-lg" />
                        </span>
                    </ShowIf>
                    <h2 className="modal-card-title has-text-primary">
                        {isEditing
                            ? i18n.crematoryModal.titleEditMode
                            : i18n.crematoryModal.titleCreateMode}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <ShowIf condition={!isFetching}>
                        <Form>
                            <Input
                                errors={errors}
                                touched={touched}
                                name="name"
                                label={i18n.generic.name}
                                placeholder={
                                    i18n.crematoryModal.nameFieldPlaceholder
                                }
                            />
                            <ShowIf condition={!isEditing}>
                                <Select
                                    errors={errors}
                                    touched={touched}
                                    name="organization"
                                    label={
                                        i18n.crematoryModal.organizationLabel
                                    }
                                    placeholder={
                                        i18n.crematoryModal
                                            .organizationPlaceholder
                                    }
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    value={values.organization}
                                    items={organizations}
                                />
                            </ShowIf>
                            <Input
                                errors={errors}
                                touched={touched}
                                name="region"
                                label={i18n.generic.region}
                                placeholder={i18n.generic.region}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="zipcode"
                                label={i18n.generic.zipcode}
                                placeholder={i18n.generic.zipcode}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="city"
                                label={i18n.generic.city}
                                placeholder={i18n.generic.city}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="address"
                                label={i18n.generic.address}
                                placeholder={i18n.generic.address}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="locationPhoneNumber"
                                label={i18n.crematoryModal.locationPhoneNumber}
                                placeholder={
                                    i18n.crematoryModal.locationPhoneNumber
                                }
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="locationEmail"
                                label={i18n.crematoryModal.locationEmail}
                                placeholder={i18n.crematoryModal.locationEmail}
                            />
                            <UploadField
                                fileTypes={fileTypes}
                                name="logo"
                                label={i18n.generic.logo}
                                value={values.logo}
                                setFieldValue={setFieldValue}
                            />
                            <AspectRatioField
                                label={i18n.generic.aspectRatio}
                                name="aspectRatio"
                                setFieldValue={setFieldValue}
                                value={values.aspectRatio}
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="crematoriumAdminName"
                                label={i18n.crematoryModal.adminNameFieldLabel}
                                placeholder={i18n.generic.name}
                                type="text"
                            />
                            <Input
                                errors={errors}
                                touched={touched}
                                name="crematoriumAdminEmail"
                                label={i18n.crematoryModal.adminEmailFieldLabel}
                                placeholder={i18n.generic.email}
                                type="email"
                            />
                            <ShowIf condition={!isEditing}>
                                <label className="label">
                                    {i18n.generic.rooms}
                                </label>
                                <FieldArray
                                    name="rooms"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.rooms.map((room, index) => (
                                                <div
                                                    key={index}
                                                    className="field"
                                                >
                                                    <Input
                                                        errors={errors}
                                                        touched={touched}
                                                        name={`rooms[${index}].name`}
                                                        placeholder={
                                                            i18n.generic.name
                                                        }
                                                        showDeleteButton
                                                        onDelete={() =>
                                                            arrayHelpers.remove(
                                                                index
                                                            )
                                                        }
                                                        smallerMargin
                                                    />
                                                    <div
                                                        className={
                                                            styles.checkboxContainer
                                                        }
                                                    >
                                                        <Checkbox
                                                            errors={errors}
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            label={
                                                                i18n.generic
                                                                    .recording
                                                            }
                                                            name={`rooms[${index}].recording`}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            touched={touched}
                                                            value={
                                                                room.recording
                                                            }
                                                            reversed
                                                        />
                                                        <Checkbox
                                                            errors={errors}
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            label={
                                                                i18n.generic
                                                                    .liveStreaming
                                                            }
                                                            name={`rooms[${index}].livestreaming`}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            touched={touched}
                                                            value={
                                                                room.livestreaming
                                                            }
                                                            reversed
                                                        />
                                                        <Checkbox
                                                            errors={errors}
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            label={
                                                                i18n.roomModal
                                                                    .masterUnitLabel
                                                            }
                                                            name={`rooms[${index}].hidden`}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            touched={touched}
                                                            value={room.hidden}
                                                            reversed
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <FieldArrayAddButton
                                                onClick={() =>
                                                    arrayHelpers.push({
                                                        name: "",
                                                        recording: false,
                                                        livestreaming: false,
                                                        hidden: false
                                                    })
                                                }
                                            />
                                            <ShowIf
                                                condition={isString(
                                                    errors.rooms
                                                )}
                                            >
                                                <ErrorMessage
                                                    name="rooms"
                                                    component="p"
                                                    className="help is-danger is-size-6"
                                                />
                                            </ShowIf>
                                        </div>
                                    )}
                                />
                            </ShowIf>
                        </Form>
                    </ShowIf>
                    <ShowIf condition={isFetching}>
                        <div className={styles.loaderContainer}>
                            <div className="loader" />
                        </div>
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {isEditing ? i18n.generic.save : i18n.generic.add}
                    </button>
                    <ShowIf condition={isEditing}>
                        <button
                            onClick={() =>
                                setActiveStatus(
                                    values.id,
                                    !Boolean(values.active)
                                )
                            }
                            className="button is-secondary is-rounded"
                        >
                            {values.active
                                ? i18n.crematoryModal.deactivate
                                : i18n.crematoryModal.activate}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default CrematoryModalView;
