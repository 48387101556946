import React from "react";

import styles from "./BlockProcessingModal.module.scss";

import ShowIf from "../../../ShowIf";
import {
    getBlockTitle,
    getBlockHeaderClassName
} from "../../../../helpers/blockHelpers";
import i18n from "../../../../constants/i18n";

function isBlockFullyProcesed(block) {
    if (block.audioTrack && !block.audioTrack.fileName) {
        return false;
    }

    if (block.video && !block.video.fileName) {
        return false;
    }

    if (
        block.photos &&
        block.photos.length &&
        block.photos.some(photo => !photo.fileName)
    ) {
        return false;
    }

    return true;
}

function hasProcessingFailure(block) {
    if (block.audioTrack && block.audioTrack.processingFailure) {
        return true;
    }

    if (block.video && block.video.processingFailure) {
        return true;
    }

    return false;
}

const BlockProcessingModalView = ({
    blocks,
    hideModal,
    isFooterButtonDisabled,
    isVisible,
    onFooterButtonClick
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.blockProcessingModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <h4 className={styles.title}>
                        {i18n.blockProcessingModal.bodyTitle}
                    </h4>
                    {blocks.map((block, index) => (
                        <div key={block.id} className={styles.blockContainer}>
                            <span className={styles.blockIndex}>
                                {index + 1}
                            </span>
                            <div
                                className={`${
                                    styles.block
                                } ${getBlockHeaderClassName(block, styles)}`}
                            >
                                <span>{getBlockTitle(block)}</span>
                            </div>
                            <div className={styles.processingIndicator}>
                                <ShowIf condition={isBlockFullyProcesed(block)}>
                                    <span className="icon">
                                        <i className="fas fa-check"></i>
                                    </span>
                                </ShowIf>
                                <ShowIf
                                    condition={
                                        !isBlockFullyProcesed(block) &&
                                        !hasProcessingFailure(block)
                                    }
                                >
                                    <div
                                        className={styles.blockLoaderContainer}
                                    >
                                        <div
                                            className={`${styles.blockLoader} loader`}
                                        ></div>
                                    </div>
                                </ShowIf>
                                <ShowIf condition={hasProcessingFailure(block)}>
                                    <span className="icon has-text-danger">
                                        <i className="fas fa-times-circle"></i>
                                    </span>
                                </ShowIf>
                            </div>
                        </div>
                    ))}
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={() => onFooterButtonClick()}
                        disabled={isFooterButtonDisabled}
                        className="button is-primary is-rounded"
                    >
                        {i18n.blockProcessingModal.footerButtonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default BlockProcessingModalView;
