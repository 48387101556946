import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";

import roles from "../../constants/roles";
import { getCrematoryRooms } from "../../selectors/rooms";

import CeremonyOverviewContainer from "./CeremonyOverviewContainer";

function getCrematoryIdFromRouteParams(match) {
    const id = get(match, ["params", "crematoryID"], null);

    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = (state, props) => {
    const crematoryId = getCrematoryIdFromRouteParams(props.match);
    const crematory = state.entities.crematories.byId[crematoryId];
    const ceremonies = state.entities.ceremonies.allIds;
    let rooms = [];

    if (crematory && crematory.item) {
        rooms = getCrematoryRooms(state, crematoryId);
    }

    return {
        ceremonies,
        crematory,
        rooms,
        currentUserId: state.user.profile.id,
        isAdmin: state.user.profile.roles.includes(roles.admin),
        isOrganization: state.user.profile.roles.includes(roles.organization),
        organizationId: state.user.profile.organizationId
    };
};

export default withRouter(connect(mapStateToProps)(CeremonyOverviewContainer));
