import React from "react";
import { Link } from "react-router-dom";

import styles from "./ForgotPassword.module.scss";
import routeNames from "../../constants/routeNames";

import ForgotPasswordForm from "../../components/ForgotPasswordForm";

const ForgotPasswordRoute = props => {
    return (
        <>
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h1 className={`has-text-centered ${styles.title}`}>
                            Wachtwoord resetten?
                        </h1>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-3">
                        <ForgotPasswordForm {...props} />
                        <Link
                            className={`link ${styles.link} has-text-centered`}
                            to={routeNames.login}
                        >
                            Terug naar inloggen
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordRoute;
