import React, { Component } from "react";
import axios from "axios";
import { normalize } from "normalizr";
import { debounce } from "micro-dash";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import createApiService from "../../network";
import OrganizationOverviewView from "./OrganizationOverviewView";
import { organization as organizationSchema } from "../../schemas";
import { putNormalizedEntities } from "../../helpers";
import routeNames from "../../constants/routeNames";
import { showModal } from "../../actions/modals";
import modalTypes from "../../constants/modalTypes";

class OrganizationOverviewContainer extends Component {
    state = {
        currentIds: [],
        isFetching: true,
        isSearching: false,
        searchQuery: "",
        meta: null,
        links: null
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        // Re-direct organization to default page if organization is not an admin.
        if (!this.props.isAdmin) {
            props.history.replace(routeNames.app);
        }

        this.api = createApiService(axios);
    }

    componentDidMount() {
        this._isMounted = true;

        this.fetchOrganizations(this.props.match.params.pageNumber);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.match.params.pageNumber !==
            this.props.match.params.pageNumber
        ) {
            this.fetchOrganizations(this.props.match.params.pageNumber);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchOrganizations = async (pageNumber = 1) => {
        if (this.state.meta && pageNumber === this.state.meta.currentPage) {
            return;
        }

        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.searchOrganization(
                this.state.searchQuery,
                pageNumber
            );

            const { data, meta, links } = response.data;
            const { entities } = normalize(data, [organizationSchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            if (this._isMounted) {
                this.setState({
                    currentIds: data.map(organization => organization.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            }
        } catch (error) {
            Sentry.captureException(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    searchOrganization = async query => {
        this.onPaginationSelect(1);
        this.setState({
            isFetching: true,
            isSearching: true,
            searchQuery: query
        });

        try {
            const response = await this.api.searchOrganization(query, 1);

            const { data, meta, links } = response.data;
            const { entities } = normalize(data, [organizationSchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            this.setState({
                currentIds: data.map(organization => organization.id),
                meta,
                links,
                isFetching: false,
                isSearching: false,
                error: null
            });
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            this.setState({
                isFetching: false,
                isSearching: false,
                error
            });
        }
    };

    debouncedOnSearch = debounce(event => {
        this.searchOrganization(event.target.value);
    }, 500);

    onSearch = event => {
        event.persist();
        this.debouncedOnSearch(event);
        this.setState({ searchQuery: event.target.value });
    };

    onPaginationSelect = pageNumber => {
        if (this.state.searchQuery.length > 0) {
            this.props.history.push(
                `${routeNames.searchOrganizationsPaginated}`
                    .replace(":pageNumber", pageNumber)
                    .replace(":searchQuery", this.state.searchQuery)
            );
        } else {
            this.props.history.push(
                `${routeNames.organizationsPaginated}`.replace(
                    ":pageNumber",
                    pageNumber
                )
            );
        }
    };

    onAddButtonClick = () => {
        this.props.dispatch(
            showModal({ type: modalTypes.ORGANIZATION, props: {} })
        );
    };

    render() {
        return (
            <OrganizationOverviewView
                organizationIds={this.state.currentIds}
                meta={this.state.meta}
                onPaginationSelect={this.onPaginationSelect}
                isFetching={this.state.isFetching}
                isSearching={this.state.isSearching}
                onSearchChange={this.onSearch}
                onShowOrganization={id => {
                    this.props.history.push(
                        `${routeNames.organizationDetail}/`.replace(
                            ":organizationID",
                            id
                        )
                    );
                }}
                searchQuery={this.state.searchQuery}
                onEdit={id => {
                    this.props.dispatch(
                        showModal({
                            type: modalTypes.ORGANIZATION,
                            props: { organizationId: id }
                        })
                    );
                }}
                onAddButtonClick={this.onAddButtonClick}
                error={this.state.error}
            ></OrganizationOverviewView>
        );
    }
}

export default withRouter(OrganizationOverviewContainer);
