import React from "react";

import EndUserCeremonyOverview from "../../components/EndUserCeremonyOverview";

const SelectCeremonyRoute = props => {
    return (
        <div className="container">
            <EndUserCeremonyOverview />
        </div>
    );
};

export default SelectCeremonyRoute;
