import React from "react";
import { get, isNumber } from "micro-dash";
import styles from "./OrganizationOverview.module.scss";
import i18n from "../../constants/i18n";
import Table from "./Table";
import Pagination from "../Pagination";
import Search from "../Search";

const OrganizationOverviewView = props => {
    return (
        <div className="container">
            <div className={`level ${styles.titleLevel}`}>
                <div className="level-left">
                    <h1>{i18n.organizationOverview.title}</h1>
                </div>

                <div className="level-right">
                    <div className="level-item">
                        <button
                            onClick={props.onAddButtonClick}
                            className="button is-primary is-rounded"
                        >
                            {i18n.generic.add}
                        </button>
                    </div>
                </div>
            </div>

            <div className={`level ${styles.searchLevel}`}>
                <div className="level-left">
                    <Search
                        onChange={props.onSearchChange}
                        value={props.searchQuery}
                        isLoading={props.isSearching}
                        placeholder={i18n.generic.searchOrganizations}
                    ></Search>
                </div>

                <div className="level-right">
                    <div className="level-item">
                        <span>
                            {i18n.generic.totalOrganizationCount}
                            {isNumber(get(props, ["meta", "total"]))
                                ? get(props, ["meta", "total"])
                                : ""}
                        </span>
                    </div>
                </div>
            </div>

            {props.isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <>
                    <Table
                        organizationIds={props.organizationIds}
                        onEdit={props.onEdit}
                        onSelect={props.onSelect}
                        onShowOrganization={props.onShowOrganization}
                    ></Table>
                    {props.isFetching === false &&
                    !props.organizationIds.length ? (
                        <div className={`${styles.warningContainer}`}>
                            <span className="icon has-text-warning is-medium">
                                <i className="fas fa-2x fa-exclamation-circle"></i>
                            </span>

                            <h3 className="has-centered-text">
                                {i18n.generic.noResultsFound}
                            </h3>
                        </div>
                    ) : null}
                    <Pagination
                        meta={props.meta}
                        onSelect={props.onPaginationSelect}
                    ></Pagination>
                </>
            )}

            {props.error ? (
                <div className={`${styles.warningContainer}`}>
                    <span className="icon has-text-warning is-medium">
                        <i className="fas fa-2x fa-exclamation-circle"></i>
                    </span>
                    <h3 className="has-centered-text">{i18n.generic.error}</h3>
                </div>
            ) : null}
        </div>
    );
};

export default OrganizationOverviewView;
