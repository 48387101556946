import React from "react";
import classNames from "classnames";

import styles from "./BlockPhotoAdjustmentModal.module.scss";

import i18n from "../../../../constants/i18n";

const BlockPhotoAdjustmentModalView = ({
    hideModal,
    isSubmitting,
    isVisible,
    onSubmit,
    src,
    photoInternalId,
    blockId,
    onReturnToBlockEditModal,
    ...props
}) => {
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoADjustmentModal.title}
                    </h2>
                    <button
                        onClick={onReturnToBlockEditModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.cropContainer}>
                        {src && <img src={src} />}
                    </div>
                    <div className={styles.buttonContainer}>
                        <div
                            className={classNames(
                                "has-addons",
                                styles.cropButtonContainer
                            )}
                        >
                            <p className="control">
                                <button
                                    className="button is-rounded is-primary"
                                    onClick={() =>
                                        props.showImageCropModal(
                                            blockId,
                                            photoInternalId
                                        )
                                    }
                                >
                                    <span>{i18n.generic.photoCrop}</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-crop"></i>
                                    </span>
                                </button>
                            </p>
                        </div>
                        <div
                            className={classNames(
                                "has-addons",
                                styles.rotateButtonContainer
                            )}
                        >
                            <p className="control">
                                <button
                                    className="button is-rounded is-primary"
                                    onClick={() =>
                                        props.showImageRotateModal(
                                            blockId,
                                            photoInternalId
                                        )
                                    }
                                >
                                    <span>Roteren</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-retweet"></i>
                                    </span>
                                </button>
                            </p>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot"></footer>
            </div>
        </div>
    );
};

export default BlockPhotoAdjustmentModalView;
