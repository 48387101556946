import { connect } from "react-redux";
import { get } from "micro-dash";
import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import OrganizationUserManagementModalContainer from "./OrganizationUserManagementModalContainer";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
    const {
        user,
        entities: { crematories }
    } = state;

    return {
        isVisible: true,
        crematoryId: crematories.allIds[0],
        currentUserId: get(user, ["profile", "id"])
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrganizationUserManagementModalContainer)
);
