import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { normalize } from "normalizr";
import { withRouter } from "react-router-dom";
import { get, omit } from "micro-dash";
import * as Sentry from "@sentry/browser";

import i18n from "../../../../constants/i18n";
import roles from "../../../../constants/roles";
import routeNames from "../../../../constants/routeNames";

import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import { processCrematoryFormData } from "../../../../helpers/crematoryTransformer";
import { crematory as crematorySchema } from "../../../../schemas";

import CrematoryModalView from "./CrematoryModalView";
import { extractApiErrorMessage } from "../../../../helpers";

class CrematoryModalContainer extends Component {
    initialValues = {
        name: "",
        region: "",
        city: "",
        address: "",
        logo: null,
        aspectRatio: "",
        crematoriumAdminEmail: "",
        crematoriumAdminName: "",
        rooms: [],
        organization: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true
        };

        this.api = createApiService(axios);

        if (props.crematory) {
            this.initialValues = props.crematory;
        }

        if (props.organizationId) {
            this.initialValues.organization = props.organizationId;
        }
    }

    baseValidationSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        region: Yup.string().required(i18n.validation.required),
        city: Yup.string().required(i18n.validation.required),
        address: Yup.string().required(i18n.validation.required),
        aspectRatio: Yup.string().required(i18n.validation.required),
        logo: Yup.mixed().required(i18n.validation.required),
        zipcode: Yup.mixed().required(i18n.validation.required),
        locationPhoneNumber: Yup.mixed().required(i18n.validation.required),
        locationEmail: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required)
    });

    createCrematorySchema = Yup.object().shape({
        crematoriumAdminEmail: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required),
        crematoriumAdminName: Yup.string().required(i18n.validation.required),
        rooms: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required(i18n.validation.required),
                    recording: Yup.boolean().required(i18n.validation.required),
                    livestreaming: Yup.boolean().required(
                        i18n.validation.required
                    ),
                    hidden: Yup.boolean().required(i18n.validation.required)
                })
            )
            .min(1, i18n.validation.addAtLeastOneRoom)
            .required(i18n.validation.required),
        organization: Yup.number()
    });

    async componentDidMount() {
        if (!this.getIsEditing()) {
            this.getOrganizations();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    getOrganizations = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getOrganizations();

            const { data } = response.data;

            this.setState({
                organizations: data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    getIsEditing = () => {
        return Boolean(this.props.crematory);
    };

    getValidationSchema = () => {
        return this.getIsEditing()
            ? this.baseValidationSchema
            : this.baseValidationSchema.concat(this.createCrematorySchema);
    };

    onSubmit = async (values, formikBag) => {
        if (this.getIsEditing()) {
            this.editCrematory(values, formikBag);
        } else {
            this.addCrematory(values, formikBag);
        }
    };

    createCrematoryUser = async values => {
        const userValues = {
            name: values.employeeName,
            email: values.employeeEmail,
            password: values.employeePassword,
            password_confirmation: values.employeePassword,
            role: roles.crematory
        };

        const response = await this.api.createUser(userValues);
        return response.data.data.id;
    };

    addCrematory = async (values, formikBag) => {
        const processedValues = processCrematoryFormData(false, values);

        try {
            const {
                data: { data: createdCrematory }
            } = await this.api.postCrematory({
                ...processedValues,
                active: 1
            });

            formikBag.setSubmitting(false);
            const { entities } = normalize(createdCrematory, crematorySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.history.push(
                `${routeNames.locations}/${createdCrematory.id}`
            );
            this.props.showToast({
                body: i18n.generic.createCrematorySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                const errors = omit(e.errors, "email", "password");
                if (e.errors.email) {
                    errors.employeeEmail = e.errors.email;
                }
                if (e.errors.password) {
                    errors.employeePassword = e.errors.password;
                }
                formikBag.setErrors(errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    editCrematory = async (values, formikBag) => {
        const processedValues = processCrematoryFormData(
            true,
            values,
            this.props.crematory
        );

        try {
            const {
                data: { data: updatedCrematory }
            } = await this.api.putCrematory(
                processedValues.id,
                processedValues
            );

            formikBag.setSubmitting(false);
            const { entities } = normalize(updatedCrematory, crematorySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updateCrematorySuccess,
                title: "Success",
                themeClass: "is-success"
            });
            this.props.onComplete && this.props.onComplete();
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    setActiveStatus = async (id, isActive) => {
        const processedValues = processCrematoryFormData(true, {
            ...this.props.crematory,
            active: +isActive
        });

        try {
            const {
                data: { data: updatedCrematory }
            } = await this.api.putCrematory(id, processedValues);

            const { entities } = normalize(updatedCrematory, crematorySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updateCrematorySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    deleteCrematory = async id => {
        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                const response = await this.api.deleteCrematory(id);
                this.props.hideModal();
                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.onCrematoryDeleted(id);
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.getValidationSchema()}
                render={props => (
                    <CrematoryModalView
                        deleteCrematory={this.deleteCrematory}
                        hideModal={this.props.hideModal}
                        isVisible={true}
                        isFetching={this.state.isFetching}
                        isEditing={this.getIsEditing()}
                        setActiveStatus={this.setActiveStatus}
                        organizations={this.state.organizations}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(CrematoryModalContainer);
