import React from "react";
import { ErrorMessage, Form, FieldArray } from "formik";
import { isString, sortBy } from "micro-dash";

import i18n from "../../../../constants/i18n";
import styles from "./RelativesModal.module.scss";

import FieldArrayAddButton from "../../../FieldArrayAddButton";
import Input from "../../../Input";
import ShowIf from "../../../ShowIf";

const RelativesModalView = ({
    relatives,
    errors,
    hideModal,
    isFetching,
    isSubmitting,
    isVisible,
    onDelete,
    submitForm,
    touched,
    values,
    isAdmin,
    isCrematoriumAdmin,
    isCeremonyMasterId
}) => {
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.relativesModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <ShowIf condition={isFetching}>
                        <div className={`loader ${styles.loader}`} />
                    </ShowIf>
                    <ShowIf condition={!isFetching}>
                        <div className={styles.listContainer}>
                            {sortBy(relatives, relative => relative.name).map(
                                relative => (
                                    <div
                                        key={relative.id}
                                        className={styles.listItem}
                                    >
                                        <div className={styles.listItemDetails}>
                                            <p className={styles.listItemName}>
                                                {relative.name}
                                            </p>
                                            <p className={styles.listItemEmail}>
                                                {relative.email}
                                            </p>
                                        </div>
                                        <ShowIf
                                            condition={
                                                isAdmin ||
                                                isCrematoriumAdmin ||
                                                isCeremonyMasterId
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.listItemActions
                                                }
                                            >
                                                <span
                                                    onClick={() =>
                                                        onDelete(relative.email)
                                                    }
                                                    className={`icon ${styles.icon} ${styles.clickable}`}
                                                >
                                                    <i className="fas fa-lg fa-trash-alt"></i>
                                                </span>
                                            </div>
                                        </ShowIf>
                                    </div>
                                )
                            )}
                        </div>
                        <label className="label">
                            {i18n.relativesModal.fieldLabel}
                        </label>
                        <FieldArray
                            name="relatives"
                            render={arrayHelpers => (
                                <div>
                                    {values.relatives.map((value, index) => (
                                        <div
                                            key={index}
                                            className={`field ${styles.field}`}
                                        >
                                            <div
                                                className={
                                                    styles.inputContainer
                                                }
                                            >
                                                <Input
                                                    errors={errors}
                                                    name={`relatives[${index}].name`}
                                                    placeholder={
                                                        i18n.relativesModal
                                                            .nameFieldPlaceholder
                                                    }
                                                    smallerMargin
                                                    touched={touched}
                                                />
                                                <Input
                                                    errors={errors}
                                                    name={`relatives[${index}].email`}
                                                    placeholder={
                                                        i18n.relativesModal
                                                            .fieldPlaceholder
                                                    }
                                                    smallerMargin
                                                    touched={touched}
                                                />
                                            </div>
                                            <span
                                                onClick={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                                className={`icon is-right ${styles.deleteButton}`}
                                            >
                                                <i className="fas fa-times" />
                                            </span>
                                        </div>
                                    ))}
                                    <FieldArrayAddButton
                                        onClick={() => arrayHelpers.push("")}
                                    />
                                    <ShowIf
                                        condition={isString(errors.relatives)}
                                    >
                                        <ErrorMessage
                                            name="relatives"
                                            component="p"
                                            className="help is-danger is-size-6"
                                        />
                                    </ShowIf>
                                </div>
                            )}
                        />
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.relativesModal.saveLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default RelativesModalView;
