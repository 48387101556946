import React from "react";
import T from "prop-types";

import styles from "./Search.module.scss";

import i18n from "../../../../../constants/i18n";
import ShowIf from "../../../../ShowIf";

const Search = ({ isLoading, onChange, query }) => {
    return (
        <div className="field">
            <div className="control has-icons-right">
                <input
                    className="input is-rounded"
                    name="query"
                    value={query}
                    onChange={onChange}
                    placeholder={i18n.musicUploadModal.searchFieldPlaceholder}
                />
                <ShowIf condition={!isLoading}>
                    <span className="icon is-right has-text-dark">
                        <i className="fas fa-search"></i>
                    </span>
                </ShowIf>
                <ShowIf condition={isLoading}>
                    <span className={`icon is-right ${styles.loaderContainer}`}>
                        <div className="loader"></div>
                    </span>
                </ShowIf>
            </div>
        </div>
    );
};

Search.propTypes = {
    isLoading: T.bool.isRequired,
    onChange: T.func.isRequired,
    query: T.string.isRequired
};

export default Search;
