import { combineReducers } from "redux";

import authReducer from "./auth";
import entityReducer from "./entities";
import userReducer from "./user";
import totalCrematoryCountReducer from "./totalCrematoryCount";
import totalDataUsageReducer from "./totalDataUsage";
import modalReducer from "./modals";
import toastReducer from "./toasts";

const rootReducer = combineReducers({
    auth: authReducer,
    entities: entityReducer,
    user: userReducer,
    totalCrematoryCount: totalCrematoryCountReducer,
    totalDataUsage: totalDataUsageReducer,
    modals: modalReducer,
    toasts: toastReducer
});

export default rootReducer;
