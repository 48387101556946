import { get } from "micro-dash";

import { putAudioTracks } from "../actions/audioTracks";
import { putBlocks } from "../actions/blocks";
import { putCeremonies } from "../actions/ceremonies";
import { putCrematories } from "../actions/crematories";
import { putDevices } from "../actions/devices";
import { putPhotos } from "../actions/photos";
import { putRooms } from "../actions/rooms";
import { putVideos } from "../actions/videos";
import { putUsers } from "../actions/users";
import { putOrganizations } from "../actions/organizations";

export function wait(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function isIsoDate(dateString) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateString)) {
        return false;
    }

    const date = new Date(dateString);
    return date.toISOString() === dateString;
}

export function isJSONString(string) {
    try {
        JSON.parse(string);
    } catch (error) {
        return false;
    }

    return true;
}

export function isJSONObject(object) {
    try {
        JSON.stringify(object);
    } catch (error) {
        return false;
    }

    return true;
}

export function extractApiErrorMessage(error) {
    return get(error, ["response", "data", "message"], error.message);
}

export function putNormalizedEntities(dispatch, entities) {
    for (let [key, byId] of Object.entries(entities)) {
        const items = Object.values(byId);

        switch (key) {
            case "audioTracks":
                dispatch(putAudioTracks(items));
                break;

            case "blocks":
                dispatch(putBlocks(items));
                break;

            case "ceremonies":
                dispatch(putCeremonies(items));
                break;

            case "crematories":
                dispatch(putCrematories(items));
                break;

            case "devices":
                dispatch(putDevices(items));
                break;

            case "photos":
                dispatch(putPhotos(items));
                break;

            case "rooms":
                dispatch(putRooms(items));
                break;

            case "videos":
                dispatch(putVideos(items));
                break;

            case "users":
                dispatch(putUsers(items));
                break;

            case "organizations":
                dispatch(putOrganizations(items));
                break;

            default:
                return;
        }
    }
}
