import React from "react";

import styles from "./DurationWarningModal.module.scss";
import i18n from "../../../../constants/i18n";

const DurationWarningModalView = ({
    hideModal,
    isVisible,
    showBlockTypeModal
}) => {
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.durationWarningModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p>{i18n.durationWarningModal.warningTitle}</p>
                    <p>{i18n.durationWarningModal.warningBody}</p>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={showBlockTypeModal}
                        className="button is-primary is-rounded"
                    >
                        {i18n.durationWarningModal.buttonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DurationWarningModalView;
