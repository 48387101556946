import React from "react";

import i18n from "../../../../constants/i18n";
import Input from "../../../Input";

const SpeechModalView = ({
    errors,
    touched,
    hideModal,
    isVisible,
    submitForm,
    isSubmitting
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.speechModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Input
                        errors={errors}
                        touched={touched}
                        name="speaker"
                        label={i18n.speechModal.speakerName}
                        placeholder={i18n.speechModal.speakerName}
                    />
                    <Input
                        errors={errors}
                        touched={touched}
                        name="duration"
                        label={i18n.speechModal.durationField}
                        placeholder={i18n.speechModal.durationField}
                    />
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.add}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default SpeechModalView;
