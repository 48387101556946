import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../../network";

import BlockProcessingModalView from "./BlockProcessingModalView";

class BlockProcessingModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    onFooterButtonClick = blockId => {
        this.props.hideModal();
        this.props.showBlockPreviewModal(blockId);
    };

    render() {
        const { blocks, hideModal, isVisible } = this.props;

        return (
            <BlockProcessingModalView
                blocks={blocks}
                hideModal={hideModal}
                isVisible={isVisible}
                onFooterButtonClick={this.onFooterButtonClick}
            />
        );
    }
}

export default withRouter(BlockProcessingModalContainer);
