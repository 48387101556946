import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../../network";
import routeNames from "../../../../constants/routeNames";

import CeremonyCreateSuccessModalView from "./CeremonyCreateSuccessModalView";

class CeremonyCreateSuccessModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    handleGoBackToOverview = () => {
        this.props.hideModal();
    };

    handleGoToTimeline = () => {
        this.props.hideModal();
        this.props.history.push(
            `${routeNames.locations}/${this.props.crematoryId}/timeline/${this.props.ceremonyId}`
        );
    };

    render() {
        return (
            <CeremonyCreateSuccessModalView
                handleGoBackToOverview={this.handleGoBackToOverview}
                handleGoToTimeline={this.handleGoToTimeline}
                hideModal={this.props.hideModal}
                isVisible={this.props.isVisible}
            />
        );
    }
}

export default withRouter(CeremonyCreateSuccessModalContainer);
