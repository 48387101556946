import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import routeNames from "../constants/routeNames";
import { isAuthenticatedSelector } from "../selectors/auth";

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={routeNames.login} />
                )
            }
        />
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: isAuthenticatedSelector(state)
    };
};

export default connect(mapStateToProps)(PrivateRoute);
