import i18n from "../../../constants/i18n";
import React from "react";
import Tippy from "@tippy.js/react";
import styles from "./Users.module.scss";

const UsersView = ({
    organizationUsers,
    onShowUser,
    isFetching,
    onAddButtonClick
}) => {
    return (
        <div className="container">
            {isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <div className="block">
                    <div className="level">
                        <div className="level-left">
                            <div className="box">
                                <h1 className="title is-4">
                                    {i18n.organizationDetail.users}
                                </h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <Tippy content={i18n.generic.add}>
                                <span
                                    onClick={onAddButtonClick}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lg fa-plus"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                    <table className="table is-bordered is-striped is-fullwidth is-narrow">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Naam</th>
                                <th>E-mail</th>
                                <th>Locatie(s)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizationUsers.map(user => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {user.locations.map(location => (
                                            <p key={location.id}>
                                                {location.name}
                                            </p>
                                        ))}
                                    </td>
                                    <td className="has-text-centered">
                                        <Tippy content={i18n.generic.show}>
                                            <span
                                                onClick={() =>
                                                    onShowUser(user.id)
                                                }
                                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                            >
                                                <i className="fas fa-lg fa-eye"></i>
                                            </span>
                                        </Tippy>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UsersView;
