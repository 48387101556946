import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import { extractApiErrorMessage, wait } from "../../../../helpers";
import ValidationError from "../../../../helpers/ValidationError";
import roles from "../../../../constants/roles";

import AdminManagementModalView from "./AdminManagementModalView";

class AdminManagementModalContainer extends Component {
    initialValues = {
        admins: []
    };

    validationSchema = Yup.object().shape({
        admins: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(i18n.validation.required),
                email: Yup.string()
                    .email(i18n.validation.email)
                    .required(i18n.validation.required)
            })
        )
    });

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            isFetching: true,
            admins: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        this.setState({ isFetching: true });

        await this.fetchAdminProfiles();

        if (this._isMounted) {
            this.setState({ isFetching: false });
        }
    }

    fetchAdminProfiles = async () => {
        try {
            let admins;

            if (this.props.targetRole === roles.admin) {
                const response = await this.api.getAdminProfiles();
                admins = response.data.data;
            }

            if (this.props.targetRole === roles.crematory) {
                const response = await this.api.getCrematoryById(
                    this.props.crematoryId
                );
                admins = response.data.data.administrators;
            }

            this.setState({ admins });
        } catch (error) {
            console.log(error);
        }
    };

    deleteAdmin = async id => {
        try {
            if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
                this.setState({ isFetching: true });

                await this.api.deleteUser(id);
                this.props.showToast({
                    body: i18n.generic.updateAdminsSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });

                await this.fetchAdminProfiles();

                if (this._isMounted) {
                    this.setState({ isFetching: false });
                }
            }
        } catch (e) {
            if (this._isMounted) {
                this.setState({ isFetching: false });
            }

            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onSubmit = async (values, formikBag) => {
        try {
            let results = [];
            const admins = values.admins.map(this.enhanceAdminFormValue);

            for (const admin of admins) {
                try {
                    await this.api.inviteAdmin(admin);
                    results.push({
                        failed: false,
                        errors: []
                    });
                } catch (e) {
                    if (e instanceof ValidationError) {
                        results.push({
                            failed: true,
                            errors: e.errors
                        });
                    }
                }
            }

            const hasAnyRequestSucceeded = results.some(
                result => result.failed === false
            );
            const hasAnyRequestFailed = results.some(result => result.failed);

            if (hasAnyRequestSucceeded && hasAnyRequestFailed) {
                // Some invitations were successful, update list of invited admins
                await this.fetchAdminProfiles();

                // Remove those items from the form that were succesfully added
                results.forEach(({ failed }, index) => {
                    if (!failed) {
                        formikBag.setFieldValue(
                            "admins",
                            values.admins.filter((_, i) => i !== index)
                        );
                        results = results.filter((_, i) => i !== index);
                    }
                });

                // Workaround to make sure we only proceed when Formik updated it's internal state.
                // SetFieldValue is clearing field errors for some reason.
                // https://github.com/jaredpalmer/formik/issues/784
                // https://github.com/jaredpalmer/formik/issues/1616
                await wait(200);
            }

            if (hasAnyRequestFailed) {
                results.forEach(({ failed, errors }, index) => {
                    if (failed) {
                        formikBag.setFieldError(
                            `admins[${index}]`,
                            errors,
                            true
                        );
                    }
                });
            }

            formikBag.setSubmitting(false);

            if (
                results.length &&
                results.every(result => result.failed === false)
            ) {
                this.props.hideModal();

                this.props.showToast({
                    body: i18n.generic.updateAdminsSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
            }
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            formikBag.setSubmitting(false);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    enhanceAdminFormValue = formAdminValue => {
        const role =
            this.props.targetRole === roles.admin ? "Admin" : "Crematorium";
        const willBeCrematoryAdmin = this.props.targetRole === roles.crematory;
        const crematoryId = this.props.crematoryId;

        return {
            role,
            ...formAdminValue,
            ...(willBeCrematoryAdmin ? { crematorium: crematoryId } : {})
        };
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                render={props => (
                    <AdminManagementModalView
                        admins={this.state.admins}
                        currentUserId={this.props.currentUserId}
                        hideModal={this.props.hideModal}
                        isFetching={this.state.isFetching}
                        isVisible={this.props.isVisible}
                        onDelete={this.deleteAdmin}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(AdminManagementModalContainer);
