import React from "react";

import Breadcrumb from "../Breadcrumb";
import ShowIf from "../ShowIf";
import Warning from "../Warning";
import i18n from "../../constants/i18n";

import styles from "./RoomsOverview.module.scss";
import Table from "./Table";

const Loader = () => (
    <div className={`is-relative ${styles.loaderContainer}`}>
        <div className="loader" />
    </div>
);

const Links = ({ isAdmin, onDeviceLinkClick, onAddRoomLinkClick }) => (
    <div className="level-right">
        <div className="level-item">
            <ShowIf condition={isAdmin}>
                <button
                    onClick={onDeviceLinkClick}
                    className="button is-secondary is-rounded"
                >
                    {i18n.generic.deviceManagement}
                </button>
            </ShowIf>
            <ShowIf condition={isAdmin}>
                <button
                    onClick={onAddRoomLinkClick}
                    className="button is-primary is-rounded"
                >
                    {i18n.roomOverview.addNewRoomButtonLabel}
                </button>
            </ShowIf>
        </div>
    </div>
);

const RoomsOverviewView = ({
    crematory,
    devices,
    isAdmin,
    onAddRoomLinkClick,
    onBackLinkClick,
    onRoomDeleteRequest,
    onRoomEditRequest,
    onDeviceLinkClick,
    rooms
}) => {
    return (
        <div className="container">
            <Breadcrumb
                label="Terug naar plechtigheden"
                onClick={onBackLinkClick}
            />
            <div className={`level ${styles.linkContainer}`}>
                <div className="level-left">
                    <h1>{i18n.roomOverview.roomOverview}</h1>
                </div>
                <ShowIf condition={crematory && crematory.item}>
                    <Links
                        isAdmin={isAdmin}
                        onDeviceLinkClick={onDeviceLinkClick}
                        onAddRoomLinkClick={onAddRoomLinkClick}
                    />
                </ShowIf>
            </div>
            {!crematory || crematory.isFetching ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <Table
                        isAdmin={isAdmin}
                        devices={devices}
                        rooms={rooms}
                        onDelete={onRoomDeleteRequest}
                        onEdit={onRoomEditRequest}
                    ></Table>
                    <ShowIf condition={!crematory.error && !rooms.length}>
                        <Warning>{i18n.generic.noResultsFound}</Warning>
                    </ShowIf>
                </React.Fragment>
            )}
            <ShowIf
                condition={
                    crematory && !crematory.isFetching && crematory.error
                }
            >
                <Warning>{i18n.generic.error}</Warning>
            </ShowIf>
        </div>
    );
};

export default RoomsOverviewView;
