import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

import routeNames from "./constants/routeNames";
import { isJSONString, isJSONObject } from "./helpers";
import { getAuthToken, clearAuthToken } from "./helpers/tokenStorage";
import { get } from "micro-dash";

import { baseURL } from "./constants";
import ValidationError from "./helpers/ValidationError";

export default function configureNetwork() {
    axios.defaults.baseURL = baseURL;

    axios.interceptors.request.use(
        function(config) {
            const token = getAuthToken();

            if (token) {
                config.headers["Authorization"] = `Bearer ${token.accessToken}`;
            }

            if (config.data instanceof FormData === false) {
                if (isJSONString(config.data) || isJSONObject(config.data)) {
                    config.data = decamelizeKeys(config.data);
                }
            }

            return config;
        },
        function(error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function(response) {
            response.data = camelizeKeys(response.data);
            return response;
        },
        function(error) {
            if (axios.isCancel(error)) {
                return;
            }

            const validationErrors = get(error, ["response", "data", "errors"]);
            if (validationErrors) {
                throw new ValidationError(camelizeKeys(validationErrors));
            }

            const isTriggeredByLoginPage = get(
                error,
                ["response", "request", "responseURL"],
                ""
            ).includes("login");

            if (
                get(error, ["response", "status"]) === 401 &&
                !isTriggeredByLoginPage
            ) {
                clearAuthToken();
                window.location.href = routeNames.login;

                return;
            }

            return Promise.reject(error);
        }
    );

    return axios;
}
