import React from "react";
import { endOfDay, format, isAfter, isBefore, parse } from "date-fns";
import Tippy from "@tippy.js/react";

import i18n from "../../../constants/i18n";
import ShowIf from "../../ShowIf";
import styles from "./TableItem.module.scss";
import StatusIndicator from "../../StatusIndicator";

const TableItemView = ({
    ceremony,
    onScriptButtonClick,
    onEdit,
    onSelect,
    onUnlock,
    room
}) => {
    if (!ceremony) {
        return null;
    }

    // Sometimes the ceremony takes longer than expected, probably because it started a bit later.
    // Allow the option to unlock the ceremony until midnight (= same day). After midnight (= next day),
    // it can no longer be unlocked.
    let shouldAllowUnlocking = false;
    if (
        ceremony.item.locked &&
        isBefore(
            new Date(),
            endOfDay(
                parse(ceremony.item.date, "yyyy-MM-dd HH:mm:ss", new Date())
            )
        )
    ) {
        shouldAllowUnlocking = true;
    }

    return (
        <tr>
            <td>
                <StatusIndicator
                    isActive={isAfter(new Date(ceremony.item.date), new Date())}
                    isLocked={ceremony.item.locked}
                />
            </td>
            <td>
                <h2>{ceremony.item.familyName}</h2>
            </td>
            <td>
                <span>{i18n.ceremonyType[ceremony.item.type]}</span>
            </td>
            <td>
                <span className="">
                    {room ? room.name : i18n.ceremonyOverview.tableItemNoRoom}
                </span>
            </td>
            <td>
                <span className="">
                    {format(
                        parse(
                            ceremony.item.date,
                            "yyyy-MM-dd HH:mm:ss",
                            new Date()
                        ),
                        "dd/MM/’yy - HH:mm"
                    )}
                </span>
            </td>
            <td>
                <div className={styles.alignCenter}>
                    <span className={`icon ${styles.icon}`}>
                        <i className="fas fa-lg fa-user"></i>
                    </span>
                    <span className="">
                        {ceremony.item.ceremonyMaster.name}
                    </span>
                </div>
            </td>
            <td>
                <div className={styles.alignCenter}>
                    <span className={`icon ${styles.icon}`}>
                        <i className="fas fa-lg fa-user"></i>
                    </span>
                    <span>{ceremony.item.relatives.length}</span>
                </div>
            </td>
            <td>
                <div className="level">
                    <div className="level-item">
                        {/* Not locked */}
                        <ShowIf condition={!ceremony.item.locked}>
                            <span
                                className={`icon ${styles.icon} ${styles.disabled}`}
                            >
                                <i className="fas fa-lock-open"></i>
                            </span>
                        </ShowIf>

                        {/* Locked AND not editable (= after midnight/next day) */}
                        <ShowIf
                            condition={
                                ceremony.item.locked && !shouldAllowUnlocking
                            }
                        >
                            <span
                                className={`icon ${styles.icon} ${styles.disabled}`}
                            >
                                <i className="fas fa-lock"></i>
                            </span>
                        </ShowIf>

                        {/* Locked AND editable (= before midnight) */}
                        <ShowIf
                            condition={
                                ceremony.item.locked && shouldAllowUnlocking
                            }
                        >
                            <Tippy content={i18n.generic.unlock}>
                                <span
                                    onClick={() => {
                                        onUnlock(ceremony.item.id);
                                    }}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lock"></i>
                                </span>
                            </Tippy>
                        </ShowIf>
                    </div>
                    <div className="level-item">
                        <Tippy content={i18n.generic.downloadScript}>
                            <span
                                onClick={() => onScriptButtonClick(ceremony)}
                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                            >
                                <i className="fas fa-lg fa-book-open"></i>
                            </span>
                        </Tippy>
                    </div>
                    {/* <div className="level-item">
            <Tippy content="Liveuitzending starten voor plechtigheid">
              <span onClick={() => {}} className={`icon is-clickable ${styles.icon} ${styles.clickable}`}>
                <i className="fas fa-lg fa-podcast"></i>
              </span>
            </Tippy>
          </div> */}
                    <div className="level-item">
                        <Tippy content={i18n.generic.edit}>
                            <span
                                onClick={() => onEdit(ceremony.item.id)}
                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                            >
                                <i className="fas fa-lg fa-pen"></i>
                            </span>
                        </Tippy>
                    </div>
                </div>
            </td>
            <td>
                <button
                    onClick={() => onSelect(ceremony.item.id)}
                    className="button is-rounded"
                >
                    {i18n.ceremonyOverview.tableItemOpenButtonLabel}
                </button>
            </td>
        </tr>
    );
};

export default TableItemView;
