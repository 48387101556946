/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import {
    DELETE_ROOM,
    PUT_ROOMS,
    DELETE_DEVICE
} from "../../constants/actionTypes";

const roomsById = produce((draft, action) => {
    switch (action.type) {
        case PUT_ROOMS:
            const rooms = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            rooms.forEach(room => {
                draft[room.id] = room;
            });
            break;

        case DELETE_ROOM: {
            delete draft[action.payload];
            break;
        }

        case DELETE_DEVICE: {
            const relatedRoom = Object.values(draft).find(
                room => room.device === action.payload
            );

            if (relatedRoom) {
                draft[relatedRoom.id].device = null;
            }
            break;
        }
    }
}, {});

const allRooms = produce((draft, action) => {
    switch (action.type) {
        case PUT_ROOMS:
            const rooms = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            rooms.forEach(room => {
                const isAlreadyPresent = draft.includes(room.id);
                if (!isAlreadyPresent) {
                    draft.push(room.id);
                }
            });
            break;

        case DELETE_ROOM: {
            return draft.filter(id => id !== action.payload);
        }
    }
}, []);

export default combineReducers({
    byId: roomsById,
    allIds: allRooms
});
