import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./CeremonyOverview.module.scss";
import i18n from "../../constants/i18n";
import Table from "./Table";
import TableFilter from "../TableFilter";
import Pagination from "../Pagination";
import Search from "../Search";
import ShowIf from "../ShowIf";
import routeNames from "../../constants/routeNames";

const CeremonyOverviewView = props => {
    return (
        <div className="container">
            <ShowIf condition={props.isAdminOrOrganization}>
                <div className={`${styles.backButton}`}>
                    <div
                        onClick={() =>
                            props.history.push(
                                `${routeNames.locationsPaginated}`.replace(
                                    ":pageNumber",
                                    sessionStorage.getItem(
                                        "crematoryOverviewPage"
                                    ) || 1
                                )
                            )
                        }
                    >
                        <span className="icon is-small">
                            <i className="fas fa-chevron-left"></i>
                        </span>
                        <span>{i18n.generic.goBackToOverview}</span>
                    </div>
                </div>
            </ShowIf>
            <div className="level">
                <div className="level-left">
                    <h1>{i18n.generic.ceremonyOverview}</h1>
                </div>
                <div className="level-right">
                    <div className={`level-item ${styles.linkContainer}`}>
                        <ShowIf condition={props.isAdmin}>
                            <button
                                onClick={props.onDeviceLinkClick}
                                className="button is-secondary is-rounded"
                            >
                                {i18n.generic.deviceManagement}
                            </button>
                        </ShowIf>
                        <button
                            onClick={props.onRoomLinkClick}
                            className="button is-secondary is-rounded"
                        >
                            {i18n.generic.roomManagement}
                        </button>
                        <ShowIf condition={!props.isAdmin}>
                            <button
                                onClick={props.onExternalCeremoniesLinkClick}
                                className={"button is-secondary is-rounded"}
                            >
                                {i18n.generic.externalCeremoniesLink}
                            </button>
                        </ShowIf>
                        <button
                            onClick={props.onAddButtonClick}
                            className="button is-primary is-rounded"
                        >
                            {i18n.generic.add}
                        </button>
                    </div>
                </div>
            </div>
            <div className={`level ${styles.searchLevel}`}>
                <div className="level-left">
                    <Search
                        onChange={props.onSearchChange}
                        value={props.searchQuery}
                        isLoading={props.isSearching}
                        placeholder={i18n.generic.searchCeremonies}
                    ></Search>
                </div>
                <div className="level-right">
                    <TableFilter
                        value={props.roomFilterActiveId}
                        onChange={props.onRoomFilterChange}
                        placeholder={
                            i18n.ceremonyOverview.roomFilterPlaceholder
                        }
                        items={props.rooms}
                    />
                </div>
            </div>
            {props.isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <Table
                        ceremonyIds={props.ceremonyIds}
                        onEdit={props.onEdit}
                        onScriptButtonClick={props.onScriptButtonClick}
                        onSelect={props.onSelect}
                        onUnlock={props.onUnlock}
                    ></Table>
                    <ShowIf
                        condition={
                            props.isFetching === false &&
                            !props.ceremonyIds.length
                        }
                    >
                        <div className={`${styles.warningContainer}`}>
                            <span className="icon has-text-warning is-medium">
                                <i className="fas fa-2x fa-exclamation-circle"></i>
                            </span>
                            <h3 className="has-centered-text">
                                {i18n.generic.noResultsFound}
                            </h3>
                        </div>
                    </ShowIf>
                    <Pagination
                        meta={props.meta}
                        onSelect={props.onPaginationSelect}
                    ></Pagination>
                </>
            )}
            <ShowIf condition={props.error}>
                <div className={`${styles.warningContainer}`}>
                    <span className="icon has-text-warning is-medium">
                        <i className="fas fa-2x fa-exclamation-circle"></i>
                    </span>
                    <h3 className="has-centered-text">{i18n.generic.error}</h3>
                </div>
            </ShowIf>
        </div>
    );
};

export default withRouter(CeremonyOverviewView);
