import React from "react";
import T from "prop-types";
import { Field, ErrorMessage } from "formik";

import ShowIf from "../../../ShowIf";

import styles from "./Input.module.scss";

const Input = ({
    errors,
    disabled,
    label,
    name,
    onDelete,
    placeholder,
    showDeleteButton,
    smallerMargin,
    touched,
    type
}) => {
    return (
        <div
            className={`field ${
                smallerMargin ? styles.fieldSmallerMargin : ""
            }`}
        >
            <label className="label">{label}</label>
            <div className="control has-icons-right">
                <Field
                    className={`input is-rounded ${
                        errors[name] && touched[name] ? "is-danger" : ""
                    }`}
                    name={name}
                    placeholder={placeholder}
                    type={type || "text"}
                    disabled={disabled}
                />
                <ShowIf condition={showDeleteButton}>
                    <span
                        onClick={onDelete}
                        className={`icon is-right ${styles.deleteButton}`}
                    >
                        <i className="fas fa-times" />
                    </span>
                </ShowIf>
            </div>
            <ErrorMessage
                name={name}
                component="p"
                className="help is-danger is-size-6"
            />
        </div>
    );
};

Input.propTypes = {
    errors: T.object.isRequired,
    label: T.string,
    name: T.string.isRequired,
    onDelete: T.func,
    placeholder: T.string,
    showDeleteButton: T.bool,
    smallerMargin: T.bool,
    touched: T.object.isRequired,
    type: T.string
};

export default Input;
