import * as Yup from "yup";
import { isNil } from "micro-dash";
import * as Sentry from "@sentry/browser";

import {
    INVALIDATE_TOTAL_DATA_USAGE,
    FETCH_TOTAL_DATA_USAGE_REQUEST,
    FETCH_TOTAL_DATA_USAGE_SUCCESS,
    FETCH_TOTAL_DATA_USAGE_FAILURE
} from "../constants/actionTypes";

export function invalidateTotalDataUsage() {
    return {
        type: INVALIDATE_TOTAL_DATA_USAGE
    };
}

export function fetchTotalDataUsageRequest() {
    return {
        type: FETCH_TOTAL_DATA_USAGE_REQUEST
    };
}

export function fetchTotalDataUsageSuccess(payload) {
    return {
        type: FETCH_TOTAL_DATA_USAGE_SUCCESS,
        payload
    };
}

export function fetchTotalDataUsageFailure(error) {
    return {
        type: FETCH_TOTAL_DATA_USAGE_FAILURE,
        error
    };
}

export function fetchTotalDataUsage() {
    const responseSchema = Yup.string().required();

    return function(dispatch, _, { api }) {
        dispatch(fetchTotalDataUsageRequest());

        return api
            .getTotalDataUsage()
            .then(async response => {
                const isResponseValid = await responseSchema.isValid(
                    response.data
                );

                if (isResponseValid) {
                    dispatch(fetchTotalDataUsageSuccess(response.data));
                } else {
                    try {
                        await responseSchema.validate(response.data);
                    } catch (error) {
                        console.error(error.errors);
                    }

                    throw new Error("Response data does not match the schema");
                }
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error(error);
                dispatch(fetchTotalDataUsageFailure(error));
            });
    };
}

function shouldFetchTotalDataUsage(state) {
    const totalDataUsage = state.totalDataUsage;

    if (totalDataUsage.isFetching) {
        return false;
    } else if (isNil(totalDataUsage.value)) {
        return true;
    } else {
        return totalDataUsage.didInvalidate;
    }
}

export function fetchTotalDataUsageIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchTotalDataUsage(getState())) {
            return dispatch(fetchTotalDataUsage());
        } else {
            return Promise.resolve();
        }
    };
}
