import React from "react";

import CeremonyOverview from "../../components/CeremonyOverview";

const CrematoriesRoute = () => {
    return (
        <div className="container">
            <CeremonyOverview></CeremonyOverview>
        </div>
    );
};

export default CrematoriesRoute;
