import { connect } from "react-redux";
import { hideModal, showModal } from "../../../../actions/modals";
import {
    getCeremonyBlocksWithAssets,
    getWalkInBlockId,
    getWalkOutBlockId
} from "../../../../selectors/blocks";
import modalTypes from "../../../../constants/modalTypes";
import BlockProcessingModalContainer from "./BlockProcessingModalContainer";
import { sortBy } from "micro-dash";
import roleTypes from "../../../../constants/roles";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);
    const isEndUser = state.user.profile.roles.includes(roleTypes.endUser);
    const isCrematoryAdmin = state.user.profile.roles.includes(
        roleTypes.crematory
    );
    const unorderedBlocks = getCeremonyBlocksWithAssets(state, ceremonyId).map(
        block => {
            if (block.id === walkInBlockId) {
                return { ...block, isWalkInBlock: true };
            }
            if (block.id === walkOutBlockId) {
                return { ...block, isWalkOutBlock: true };
            }
            return { ...block };
        }
    );

    const blocks = sortBy(unorderedBlocks, block => block.position);

    return {
        blocks,
        isVisible: true,
        isEndUser,
        isCrematoryAdmin,
        isAdmin
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const ceremonyId = parseInt(props.ceremonyId);

    return {
        hideModal: () => dispatch(hideModal()),
        showBlockPreviewModal: blockId => {
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_PREVIEW,
                    props: {
                        ceremonyId,
                        blockId
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockProcessingModalContainer);
