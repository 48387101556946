import React from "react";

import i18n from "../../../../constants/i18n";
import Select from "../../../Select";

const items = [
    {
        id: 2,
        name: "2 uren"
    },
    {
        id: 4,
        name: "4 uren"
    },
    {
        id: 6,
        name: "6 uren"
    }
];

const UnlockCeremonyModalView = ({
    hideModal,
    isVisible,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    values,
    submitForm,
    isSubmitting
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.unlockCeremonyModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Select
                        errors={errors}
                        touched={touched}
                        name="duration"
                        label={i18n.unlockCeremonyModal.dropdownLabel}
                        placeholder={
                            i18n.unlockCeremonyModal.dropdownPlaceholder
                        }
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        value={values.duration}
                        items={items}
                    />
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting || !values.duration}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default UnlockCeremonyModalView;
