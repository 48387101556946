import React from "react";
import { Link } from "react-router-dom";

import styles from "./ResetPassword.module.scss";
import routeNames from "../../constants/routeNames";

import ResetPasswordForm from "../../components/ResetPasswordForm";

const ResetPasswordRoute = props => {
    return (
        <>
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h1 className={`has-text-centered ${styles.title}`}>
                            Wachtwoord vergeten?
                        </h1>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-3">
                        <ResetPasswordForm {...props} />
                        <Link
                            className={`link ${styles.link} has-text-centered`}
                            to={routeNames.login}
                        >
                            Terug naar inloggen
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordRoute;
