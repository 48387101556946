import React from "react";

import useWindowWidth from "../../../../helpers/useWindowWidth";

import ScreenSizeModalView from "./ScreenSizeModalView";

const ScreenSizeModalContainer = () => {
    const width = useWindowWidth();

    if (width < 800) {
        return <ScreenSizeModalView isVisible={true} />;
    }

    return null;
};

export default ScreenSizeModalContainer;
