import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";

import BlockAmountWarningModalView from "./BlockAmountWarningModalView";

const mapStateToProps = () => {
    return {
        isVisible: true
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockAmountWarningModalView);
