import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { normalize } from "normalizr";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { omit } from "micro-dash";
import i18n from "../../../../constants/i18n";
import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import {
    enableBlurredBackground,
    processCeremonyFormData
} from "../../../../helpers/ceremonyTransformer";
import { ceremony as ceremonySchema } from "../../../../schemas";
import routeNames from "../../../../constants/routeNames";
import CeremonyModalView from "./CeremonyModalView";
import { extractApiErrorMessage } from "../../../../helpers";
import { showModal } from "../../../../actions/modals";
import modalTypes from "../../../../constants/modalTypes";
import { blurredBackgroundEnabledFrom } from "../../../../constants";

class CeremonyModalContainer extends Component {
    initialValues = {
        familyName: "",
        date: new Date(),
        waitingTime: 5,
        totalTime: 45,
        numberPhotos: 1,
        serviceCode: "",
        ceremonyMaster: {
            name: "",
            email: "",
            phone: ""
        },
        crematoryId: "",
        room: "",
        type: "Ceremony",
        liveStream: 0,
        recording: 1,
        signageEnabled: 1,
        signageInCollaborationWith: "",
        useBlurredPhotoBackground: 0
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (props.ceremony) {
            this.initialValues = {
                ...props.ceremony,
                waitingTime: props.ceremony.waitingTime / 60000
            };
        }

        this.blurredBackgroundEnabledDate = blurredBackgroundEnabledFrom;
    }

    baseValidationSchema = Yup.object().shape({
        familyName: Yup.string().required(i18n.validation.required),
        date: Yup.string().required(i18n.validation.required),
        type: Yup.string().required(i18n.validation.required),
        waitingTime: Yup.number().required(i18n.validation.required),
        totalTime: Yup.number().required(i18n.validation.required),
        numberPhotos: Yup.number()
            .typeError(i18n.validation.number)
            .required(i18n.validation.required)
            .positive()
            .integer(),
        liveStream: Yup.number().required(i18n.validation.required),
        room: Yup.number().required(i18n.validation.required),
        serviceCode: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, i18n.validation.alphanumeric)
            .max(20, i18n.validation.length20)
    });

    // Organization users can relocate a ceremony.
    crematorySchema = Yup.object().shape({
        crematoryId: Yup.number().required(i18n.validation.required)
    });

    createCeremonySchema = Yup.object().shape({
        ceremonyMaster: Yup.object().shape({
            name: Yup.string().required(i18n.validation.required),
            email: Yup.string()
                .email(i18n.validation.email)
                .required(i18n.validation.required),
            phone: Yup.string()
                .nullable()
                .matches(/^[+()\d-" "]+$/, {
                    message: i18n.validation.phoneNumber,
                    excludeEmptyString: true
                })
        })
    });

    getValidationSchema = () => {
        return this.props.organizationCrematories.length > 0
            ? this.baseValidationSchema
                  .concat(this.crematorySchema)
                  .concat(this.createCeremonySchema)
            : this.baseValidationSchema.concat(this.createCeremonySchema);
    };

    onSubmit = async (values, formikBag) => {
        if (this.props.ceremony) {
            await this.editCeremony(values, formikBag);
        } else {
            await this.addCeremony(values, formikBag);
        }
    };

    addCeremony = async (values, formikBag) => {
        const processedValues = processCeremonyFormData(false, values);
        const valuesWithBlurredBackgroundEnabled = enableBlurredBackground(
            processedValues
        );

        try {
            const {
                data: { data: createdCeremony }
            } = await this.api.postCeremony({
                ...valuesWithBlurredBackgroundEnabled,
                crematoriumId: valuesWithBlurredBackgroundEnabled.crematoryId
                    ? valuesWithBlurredBackgroundEnabled.crematoryId
                    : this.props.crematory.id
            });

            const { entities } = normalize(createdCeremony, ceremonySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            /**
             * Notice /reload path - it's used to reinitilize CeremonyOverview component
             * Paginated data has to be fetched again when new ceremony is added
             * Without this workaround we would need to directly connect modal and overview
             * so that modal would trigger data reload in overview.
             */
            this.props.history.push(
                `${routeNames.locations}/${this.props.crematory.id}/reload`
            );
            this.props.showSuccessModal({
                ceremonyId: createdCeremony.id,
                crematoryId: this.props.crematory.id
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
            } else {
                Sentry.captureException(e);
                console.error(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    editCeremony = async (values, formikBag) => {
        // if selected room does not have signage enabled, disable it for the ceremony.
        const roomToUpdate = this.props.rooms.find(
            room => room.id === values.room
        );
        if (!roomToUpdate.signageEnabled) {
            values.signageEnabled = 0;
            values.signageInCollaborationWith = null;
        }

        let processedValues = processCeremonyFormData(false, values);
        processedValues = {
            ...processedValues,
            crematoriumId: processedValues.crematoryId
        };
        processedValues = omit(processedValues, "mainImageId");

        // Avoid backend triggering invitational emails again
        if (
            this.props.ceremony.ceremonyMaster.name ===
                processedValues.ceremonyMasterName &&
            this.props.ceremony.ceremonyMaster.email ===
                processedValues.ceremonyMasterEmail &&
            this.props.ceremony.ceremonyMaster.phone ===
                processedValues.ceremonyMasterPhone
        ) {
            processedValues = omit(
                processedValues,
                "ceremonyMasterName",
                "ceremonyMasterEmail",
                "ceremonyMasterPhone"
            );
        }

        try {
            const res = await this.api.putCeremony(
                processedValues.id,
                processedValues
            );

            const {
                data: {
                    data: updatedCeremony,
                    meta: { message: infoMessage }
                }
            } = res;
            formikBag.setSubmitting(false);
            this.props.hideModal();

            if (processedValues.crematoriumId !== this.props.crematoryId) {
                this.props.onCeremonyRelocated(processedValues.id);
            } else {
                const { entities } = normalize(updatedCeremony, ceremonySchema);
                this.props.updateStoreEntities(entities);
            }

            this.props.showToast({
                body: i18n.generic.updateCeremonySuccess,
                title: "Success",
                themeClass: "is-success"
            });
            if (infoMessage) {
                this.props.showToast({
                    body: infoMessage,
                    title: "Warning",
                    themeClass: "is-warning"
                });
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    deleteCeremony = async (ceremonyId, crematoryId) => {
        this.props.hideModal();

        this.props.dispatch(
            showModal({
                type: modalTypes.DELETE_WARNING,
                props: { ceremonyId, crematoryId }
            })
        );
    };

    render() {
        const types = [
            { id: 1, name: i18n.ceremonyType.Ceremony, value: "Ceremony" },
            { id: 2, name: i18n.ceremonyType.Condolence, value: "Condolence" }
        ];

        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.getValidationSchema()}
                render={props => (
                    <CeremonyModalView
                        deleteCeremony={this.deleteCeremony}
                        hideModal={() => this.props.hideModal()}
                        isVisible={this.props.isVisible}
                        isEditing={Boolean(this.props.ceremony)}
                        rooms={this.props.rooms}
                        types={types}
                        organizationCrematories={
                            this.props.organizationCrematories
                        }
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(CeremonyModalContainer);
