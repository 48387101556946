import { connect } from "react-redux";

import CrematoryOverviewContainer from "./CrematoryOverviewContainer";
import roleTypes from "../../constants/roles";

const mapStateToProps = state => {
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);

    return {
        isAdmin
    };
};

export default connect(mapStateToProps)(CrematoryOverviewContainer);
