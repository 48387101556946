import {
    DELETE_BLOCK,
    PUT_BLOCKS,
    REORDER_BLOCKS
} from "../constants/actionTypes";

export function putBlocks(blocks) {
    return {
        type: PUT_BLOCKS,
        payload: blocks
    };
}

export function reorderBlocks(blockPositions) {
    return {
        type: REORDER_BLOCKS,
        payload: blockPositions
    };
}

export function deleteBlock(blockId) {
    return {
        type: DELETE_BLOCK,
        payload: blockId
    };
}
