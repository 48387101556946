import React from "react";

import UserDetail from "../../components/UserDetail";

const UserDetailRoute = props => {
    return (
        <div className="container">
            <UserDetail></UserDetail>
        </div>
    );
};

export default UserDetailRoute;
