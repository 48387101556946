import { connect } from "react-redux";

import CeremonyModalContainer from "./CeremonyModalContainer";

import { putNormalizedEntities } from "../../../../helpers";
import modalTypes from "../../../../constants/modalTypes";
import {
    deleteCeremony,
    relocateCeremony
} from "../../../../actions/ceremonies";
import { convertCeremonyEntityIntoInitialsValues } from "../../../../helpers/ceremonyTransformer";
import { hideModal, showModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { getCrematoryRooms } from "../../../../selectors/rooms";

const mapStateToProps = (state, props) => {
    const {
        entities: { ceremonies, crematories },
        user
    } = state;

    const { ceremonyId, crematoryId } = props;

    let ceremony = ceremonies.byId[ceremonyId];
    const crematory = crematories.byId[crematoryId].item;
    const currentUserId = user.profile.id;
    const isVisible = true;
    let rooms = getCrematoryRooms(state, crematoryId);
    rooms = rooms.filter(room => !room.hidden);

    const isEditing = Boolean(ceremonyId);

    if (isEditing) {
        ceremony = convertCeremonyEntityIntoInitialsValues(ceremony.item);
    }

    return {
        ceremony,
        crematory,
        currentUserId,
        isVisible,
        rooms
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        showToast: options => {
            dispatch(showToast(options));
        },
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        },
        hideModal: () => {
            dispatch(hideModal());
        },
        showSuccessModal: ({ ceremonyId, crematoryId }) =>
            dispatch(
                showModal({
                    type: modalTypes.CEREMONY_CREATE_SUCCESS,
                    props: { ceremonyId, crematoryId }
                })
            ),
        onCeremonyRelocated: id => {
            dispatch(relocateCeremony(id));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CeremonyModalContainer);
