import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import createApiService from "../../../../network";
import routeNames from "../../../../constants/routeNames";
import LockedTimelineModalView from "./LockedTimelineModalView";

class LockedTimelineModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    onCloseButtonClick = () => {
        this.props.hideModal();
        // If CrematoriumAdmin - back to ceremony overview
        // If EndUser - to ceremony select
        if (this.props.isCrematoryAdmin || this.props.isAdmin) {
            this.props.history.push(
                `${routeNames.locationDetailsPaginated}`
                    .replace(":crematoryID", this.props.crematoryId)
                    .replace(
                        ":pageNumber",
                        sessionStorage.getItem("ceremonyOverviewPage") || 1
                    )
            );
        } else if (this.props.isEndUser) {
            this.props.history.push(`${routeNames.selectCeremony}`);
        } else {
            return;
        }
    };

    onFooterButtonClick = blockId => {
        this.props.hideModal();
        this.props.showBlockPreviewModal(blockId);
    };

    render() {
        return (
            <LockedTimelineModalView
                areAssetsCleanedUp={this.props.areAssetsCleanedUp}
                ceremonyMaster={this.props.ceremonyMaster}
                isVisible={this.props.isVisible}
                onCloseButtonClick={this.onCloseButtonClick}
                onFooterButtonClick={this.onFooterButtonClick}
            />
        );
    }
}

export default withRouter(LockedTimelineModalContainer);
