import { createSelector } from "reselect";

import { getRoomsById } from "./rooms";

export const getCrematoryEntities = state => state.entities.crematories;

export const getCrematoriesById = state => getCrematoryEntities(state).byId;
export const getCrematoriesAllIds = state => getCrematoryEntities(state).allIds;
export const getCrematoryById = (state, props) =>
    getCrematoriesById(state)[props.crematoryId];

export const getCrematoryItemById = createSelector(
    [getCrematoryById, getRoomsById],
    (crematory, rooms) => {
        const crematoryRooms = crematory.item.rooms.map(id => rooms[id]);

        return { ...crematory.item, rooms: crematoryRooms };
    }
);
