import React from "react";

import i18n from "../../../../constants/i18n";
import styles from "./ScreenSizeModal.module.scss";
import warningIcon from "../../../../assets/screen_size_warning.svg";

const ScreenSizeModalView = ({ isVisible }) => (
    <div
        className={`modal ${isVisible ? "is-active" : ""} ${styles.modalRoot}`}
    >
        <div className="modal-background" />
        <div className="modal-card">
            <header className="modal-card-head">
                <h2 className="modal-card-title has-text-primary">
                    {i18n.screenSizeModal.title}
                </h2>
            </header>
            <section className="modal-card-body">
                <div className={styles.contentContainer}>
                    <img
                        className={styles.warningIcon}
                        src={warningIcon}
                        alt="Screen size warning"
                    />
                    <p>{i18n.screenSizeModal.body}</p>
                </div>
            </section>
        </div>
    </div>
);

export default ScreenSizeModalView;
