import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import createApiService from "../../network";
import { ceremony as ceremonySchema } from "../../schemas";
import { get } from "micro-dash";

class PollCeremonyLockStatusContainer extends Component {
    state = {
        isPolling: false
    };

    interval = 1000 * 60;
    timerId = null;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    startPolling() {
        this.setState({
            isPolling: true
        });

        this.runPolling();
    }

    stopPolling() {
        if (this._isMounted) {
            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }

            this.setState({
                isPolling: false
            });
        }
    }

    runPolling = () => {
        this.timerId = setTimeout(async () => {
            const { ceremonyId } = this.props;

            try {
                await this.fetchCeremony(ceremonyId);
                this.runPolling();
            } catch (e) {
                this.stopPolling();
                console.error(e);
                Sentry.captureException(e);
            }
        }, this.interval);
    };

    componentDidMount() {
        this._isMounted = true;

        this.startPolling();
    }

    componentWillUnmount() {
        this.stopPolling();

        this._isMounted = false;
    }

    fetchCeremony = async ceremonyId => {
        try {
            const {
                data: { data: ceremony }
            } = await this.api.getCeremonyById(ceremonyId);

            if (
                get(this.props.ceremony, ["item", "locked"]) !== ceremony.locked
            ) {
                const { entities } = normalize(ceremony, ceremonySchema);
                this.props.updateStoreEntities(entities);
            }
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    render() {
        return (
            <div className="poll-ceremony-lock-status">
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(PollCeremonyLockStatusContainer);
