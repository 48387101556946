import React, { Component } from "react";
import OrganizationDetailView from "./OrganizationDetailView";
import { withRouter } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import createApiService from "../../network";
import axios from "axios";
import { showModal } from "../../actions/modals";
import modalTypes from "../../constants/modalTypes";
import { normalize } from "normalizr";
import { organization as organizationSchema } from "../../schemas";
import { putNormalizedEntities } from "../../helpers";

class OrganizationDetailContainer extends Component {
    state = {
        organization: null,
        isFetching: true
    };

    constructor(props) {
        super(props);

        // Re-direct organization to default page if organization is not an admin.
        if (!this.props.isAdmin) {
            props.history.replace(routeNames.app);
        }

        this.state = {
            isFetching: true
        };

        this.api = createApiService(axios);
    }

    async componentWillMount() {
        if (this.state.organization === undefined) {
            this.getOrganization();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    getOrganization = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getOrganizationById(
                this.props.match.params.organizationID
            );

            const { data } = response.data;
            const { entities } = normalize([data], [organizationSchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            this.setState({
                organization: data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    render() {
        return (
            <OrganizationDetailView
                organization={this.state.organization}
                locations={this.state.locations}
                history={this.props.history}
                isFetching={this.state.isFetching}
                onEdit={id => {
                    this.props.dispatch(
                        showModal({
                            type: modalTypes.ORGANIZATION,
                            props: {
                                organizationId: id,
                                onComplete: () => {
                                    this.getOrganization();
                                }
                            }
                        })
                    );
                }}
            />
        );
    }
}

export default withRouter(OrganizationDetailContainer);
