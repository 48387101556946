import { combineReducers } from "redux";

import audioReducer from "./audio";
import blockReducer from "./blocks";
import ceremonyReducer from "./ceremonies";
import crematoryReducer from "./crematories";
import deviceReducer from "./devices";
import organizationReducer from "./organizations";
import photoReducer from "./photos";
import roomReducer from "./rooms";
import videoReducer from "./videos";
import userReducer from "./users";

const rootReducer = combineReducers({
    audioTracks: audioReducer,
    blocks: blockReducer,
    ceremonies: ceremonyReducer,
    crematories: crematoryReducer,
    devices: deviceReducer,
    organizations: organizationReducer,
    photos: photoReducer,
    rooms: roomReducer,
    videos: videoReducer,
    users: userReducer
});

export default rootReducer;
