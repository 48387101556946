import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { showModal } from "../../../../actions/modals";
import { putNormalizedEntities } from "../../../../helpers";
import modalTypes from "../../../../constants/modalTypes";

import ImageRotateModalContainer from "./BlockImageRotateModalContainer";

function getCalculatedAspectRatio(aspectRatioString) {
    const split = aspectRatioString.split(":");

    return split[0] / split[1];
}

const mapStateToProps = (state, props) => {
    const {
        entities: { blocks, ceremonies, crematories, photos }
    } = state;

    const { ceremonyId, photoId, blockId } = props;

    const ceremony = ceremonies.byId[ceremonyId].item;
    const crematory = crematories.byId[ceremony.crematoryId].item;
    const block = blocks.byId[blockId];
    const photo = photos.byId[photoId];

    return {
        aspectRatio: getCalculatedAspectRatio(crematory.aspectRatio),
        block,
        ceremony,
        crematory,
        isVisible: true,
        photo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => {
            dispatch(hideModal());
        },
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities),
        openBlockEditModal: (ceremonyId, blockId) => {
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_EDIT,
                    props: {
                        ceremonyId: ceremonyId,
                        blockId
                    }
                })
            );
        },
        showPhotoAdjustmentModal: (blockId, photoId, ceremonyId) => {
            dispatch(hideModal());
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_PHOTO_ADJUSTMENT,
                    props: {
                        ceremonyId: parseInt(ceremonyId),
                        blockId,
                        photoId
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageRotateModalContainer);
