import { connect } from "react-redux";

import modalTypes from "../../../../constants/modalTypes";
import { showModal, hideModal } from "../../../../actions/modals";

import BlockTypeModalContainer from "./BlockTypeModalContainer";

const mapDispatchToProps = (
    dispatch,
    { blockId, ceremonyId, isWalkInBlock, isWalkOutBlock, position }
) => {
    const propsToPass = {
        blockId,
        ceremonyId,
        isWalkInBlock,
        isWalkOutBlock,
        position
    };

    return {
        hideModal: () => {
            dispatch(hideModal());
        },
        showMusicUploadModal: () => {
            dispatch(
                showModal({ type: modalTypes.MUSIC_UPLOAD, props: propsToPass })
            );
        },
        showSpeakerModal: () => {
            dispatch(
                showModal({ type: modalTypes.SPEECH, props: propsToPass })
            );
        },
        showVideoUploadModal: () => {
            dispatch(
                showModal({ type: modalTypes.VIDEO_UPLOAD, props: propsToPass })
            );
        }
    };
};

export default connect(
    null,
    mapDispatchToProps
)(BlockTypeModalContainer);
