export const droppableIdPrefixes = {
    photos: "photos-droppable-",
    blocks: "blocks-droppable",
    blockPhoto: "block-photo-droppable-",
    bin: "bin"
};

export const draggableIdPrefixes = {
    photo: "photo-draggable-",
    block: "block-draggable-"
};
