import { omit } from "micro-dash";

import { isJSONString as isValidJsonString } from "../../helpers";

function isIterable(data) {
    return Array.isArray(data);
}

export function transformCeremonyResponse(response) {
    if (!isValidJsonString(response)) {
        return response;
    }

    const { data, ...rest } = JSON.parse(response);

    function transformer(ceremony) {
        return { ...ceremony };
    }

    if (isIterable(data)) {
        return { data: data.map(transformer), ...rest };
    } else {
        return { data: transformer(data), ...rest };
    }
}

export function transformCeremonyRequest(requestBody, headers) {
    function transformer(ceremony) {
        return omit({ ...ceremony }, "created_at", "updated_at");
    }

    if (isIterable(requestBody)) {
        return requestBody.map(transformer);
    } else {
        return transformer(requestBody);
    }
}
