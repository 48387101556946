import React from "react";

import TimelineOverview from "../../components/TimelineOverview";

const TimelineRoute = () => {
    return (
        <div className="container">
            <TimelineOverview></TimelineOverview>
        </div>
    );
};

export default TimelineRoute;
