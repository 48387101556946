import React, { Component } from "react";
import { isNumber, round } from "micro-dash";

import TotalDataUsageView from "./TotalDataUsageView";

class TotalDataUsageContainer extends Component {
    componentDidMount() {
        this.props.fetchDataIfNeeded();
    }

    componentDidUpdate(prevProps) {
        if (this.props.totalDataUsage !== prevProps.totalDataUsage) {
            this.props.fetchDataIfNeeded();
        }
    }

    render() {
        const { totalDataUsage } = this.props;
        const valueInGb = isNumber(totalDataUsage.value)
            ? round(totalDataUsage.value / 1000, 2)
            : null;

        return <TotalDataUsageView valueInGb={valueInGb}></TotalDataUsageView>;
    }
}

export default TotalDataUsageContainer;
