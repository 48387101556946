import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { putNormalizedEntities } from "../../../../helpers";

import MainCeremonyPhotoModalContainer from "./MainCeremonyPhotoModalContainer";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const ceremony = state.entities.ceremonies.byId[ceremonyId];
    const photos = Object.values(state.entities.photos.byId)
        .filter(photo => photo.ceremonyId === ceremonyId)
        .filter(photo => Boolean(photo.fileName));

    return {
        ceremony,
        photos,
        isVisible: true
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainCeremonyPhotoModalContainer);
