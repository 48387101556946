import React from "react";
import { Link } from "react-router-dom";

import styles from "./ChangePassword.module.scss";
import routeNames from "../../constants/routeNames";
import i18n from "../../constants/i18n";

import ResetPasswordForm from "../../components/ResetPasswordForm";

const ChangePasswordRoute = props => {
    return (
        <div className="container">
            <div className="columns">
                <div className="column">
                    <h1 className={`has-text-centered ${styles.title}`}>
                        {i18n.changePasswordScreen.title}
                    </h1>
                </div>
            </div>
            <div className="columns is-centered">
                <div className="column is-3">
                    <ResetPasswordForm {...props} updatePassword />
                    <Link
                        className={`link ${styles.link} has-text-centered`}
                        to={routeNames.app}
                    >
                        {i18n.changePasswordScreen.goBack}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordRoute;
