import React from "react";

// A helper component to allow adding gradient backgrounds to SVG icons
// This component is mounted in App.js and has an ID
// You can apply this gradient by using following CSS line:
// fill: url(#SVGLinearGradientBlue);
const SVGLinearGradientBlue = () => {
    return (
        <svg
            width="0"
            height="0"
            style={{ position: "absolute", top: "9999px", left: "9999px" }}
        >
            <linearGradient
                id="SVGLinearGradientBlue"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
                gradientTransform="rotate(-45)"
            >
                <stop
                    offset="0%"
                    style={{ stopColor: "#3494fe", stopOpacity: 1 }}
                />
                <stop
                    offset="100%"
                    style={{ stopColor: "#75e4fd", stopOpacity: 1 }}
                />
            </linearGradient>
        </svg>
    );
};

export default SVGLinearGradientBlue;
