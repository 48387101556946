import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { putNormalizedEntities } from "../../../../helpers";
import { getCrematoryRooms } from "../../../../selectors/rooms";

import DeviceModalContainer from "./DeviceModalContainer";

function getCrematoryById(state, crematoryId) {
    return state.entities.crematories.byId[crematoryId];
}

const mapStateToProps = (state, props) => {
    const crematory = getCrematoryById(state, props.crematoryId);
    const rooms = getCrematoryRooms(state, props.crematoryId);

    return {
        crematory,
        isVisible: true,
        rooms
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceModalContainer);
