import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";

import i18n from "../../../../constants/i18n";
import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import { ceremony as ceremonySchema } from "../../../../schemas";

import RelativesModalView from "./RelativesModalView";

class RelativesModalContainer extends Component {
    initialValues = {
        relatives: []
    };

    validationSchema = Yup.object().shape({
        relatives: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(i18n.validation.required),
                email: Yup.string()
                    .email(i18n.validation.email)
                    .required(i18n.validation.required)
            })
        )
    });

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            isFetching: false,
            relatives: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        this.setState({ isFetching: true });

        await this.fetchCeremony();

        if (this._isMounted) {
            this.setState({ isFetching: false });
        }
    }

    fetchCeremony = async () => {
        try {
            let relatives;

            const {
                data: { data: ceremony }
            } = await this.api.getCeremonyById(this.props.ceremonyId);

            this.state.relatives = ceremony.relatives;
        } catch (error) {
            console.log(error);
        }
    };

    deleteInvite = async email => {
        try {
            // Delete confirmation popup.
            if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
                // Show spinner.
                this.setState({ isFetching: true });

                // Call DELETE endpoint.
                const {
                    data: { data: ceremony }
                } = await this.api.deleteRelative(
                    this.props.ceremony.id,
                    email
                );
                this.props.showToast({
                    body: i18n.generic.updateCeremonySuccess,
                    title: "Success",
                    themeClass: "is-success"
                });

                // Update the number of relatives shown on the left side of the button.
                const { entities } = normalize(ceremony, ceremonySchema);
                this.props.updateStoreEntities(entities);

                // Update relatives shown in the form.
                this.state.relatives = ceremony.relatives;

                // Hide spinner.
                if (this._isMounted) {
                    this.setState({ isFetching: false });
                }
            }
        } catch (e) {
            if (this._isMounted) {
                this.setState({ isFetching: false });
            }

            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onSubmit = async (values, formikBag) => {
        try {
            const {
                data: { data: ceremony }
            } = await this.api.postRelatives(
                this.props.ceremonyId,
                values.relatives
            );

            const { entities } = normalize(ceremony, ceremonySchema);
            this.props.updateStoreEntities(entities);

            formikBag.setSubmitting(false);
            this.props.hideModal();

            this.props.showToast({
                body: i18n.generic.updateCeremonySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.onSubmit}
                render={props => (
                    <RelativesModalView
                        relatives={this.state.relatives}
                        hideModal={this.props.hideModal}
                        isFetching={this.state.isFetching}
                        isVisible={true}
                        onDelete={this.deleteInvite}
                        isAdmin={this.props.isAdmin}
                        isCrematoriumAdmin={this.props.isCrematory}
                        isCeremonyMasterId={
                            this.props.currentUserId ===
                            this.props.ceremonyMaster.id
                        }
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(RelativesModalContainer);
