import React, { Component } from "react";

class LogoutContainer extends Component {
    constructor(props) {
        super(props);

        props.onLogout();
    }

    render() {
        // Page will be refreshed after logging out.
        // Show anything before logout flow has been finished
        return <div></div>;
    }
}

export default LogoutContainer;
