import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import createApiService from "../../network";
import {
    audioTrack as audioTrackSchema,
    photo as photoSchema,
    video as videoSchema
} from "../../schemas";

class PollAssetsContainer extends Component {
    state = {
        isPolling: false
    };

    interval = 1000 * 12;
    timerId = null;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    startPolling() {
        this.setState({
            isPolling: true
        });

        this.runPolling();
    }

    stopPolling() {
        if (this._isMounted) {
            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }

            this.setState({
                isPolling: false
            });
        }
    }

    runPolling = () => {
        this.timerId = setTimeout(async () => {
            const { ceremonyId } = this.props;

            try {
                await Promise.all([
                    this.fetchAudioTracks(ceremonyId),
                    this.fetchPhotos(ceremonyId),
                    this.fetchVideos(ceremonyId)
                ]);
                this.runPolling();
            } catch (e) {
                this.stopPolling();
                console.error(e);
                Sentry.captureException(e);
            }
        }, this.interval);
    };

    componentDidMount() {
        this._isMounted = true;

        this.startPolling();
    }

    componentWillUnmount() {
        this.stopPolling();

        this._isMounted = false;
    }

    hasUnprocessedAssets = assets => {
        if (Array.isArray(assets)) {
            return assets.some(asset => !asset.fileName);
        } else {
            return Boolean(assets.fileName);
        }
    };

    fetchAudioTracks = async ceremonyId => {
        try {
            if (!this.hasUnprocessedAssets(this.props.audioTracks)) {
                return;
            }

            const {
                data: { data: audioTracks }
            } = await this.api.getCeremonyAudioTracks(ceremonyId);

            const { entities } = normalize(audioTracks, [audioTrackSchema]);
            this.props.updateStoreEntities(entities);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    fetchPhotos = async ceremonyId => {
        try {
            if (!this.hasUnprocessedAssets(this.props.photos)) {
                return;
            }

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(ceremonyId);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    fetchVideos = async ceremonyId => {
        try {
            if (!this.hasUnprocessedAssets(this.props.videos)) {
                return;
            }

            const {
                data: { data: videos }
            } = await this.api.getCeremonyVideos(ceremonyId);

            const { entities } = normalize(videos, [videoSchema]);
            this.props.updateStoreEntities(entities);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    render() {
        return <div className="poll-assets">{this.props.children}</div>;
    }
}

export default withRouter(PollAssetsContainer);
