/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";
import { get } from "micro-dash";

import {
    INVALIDATE_CEREMONY,
    FETCH_CEREMONY_REQUEST,
    FETCH_CEREMONY_SUCCESS,
    FETCH_CEREMONY_FAILURE,
    PUT_CEREMONIES,
    DELETE_CEREMONY,
    RELOCATE_CEREMONY
} from "../../constants/actionTypes";

const ceremoniesById = produce((draft, action) => {
    const ceremonyID = get(action, ["payload", "id"], action.payload);
    const ceremony = draft[ceremonyID];

    switch (action.type) {
        case INVALIDATE_CEREMONY: {
            if (ceremony) {
                ceremony.didInvalidate = true;
            }
            break;
        }

        case FETCH_CEREMONY_REQUEST: {
            if (ceremony) {
                ceremony.isFetching = true;
            }
            break;
        }

        case FETCH_CEREMONY_SUCCESS:
        case PUT_CEREMONIES: {
            const ceremonies = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            ceremonies.forEach(ceremony => {
                const existingCeremony = draft[ceremony.id];

                if (existingCeremony) {
                    existingCeremony.error = null;
                    existingCeremony.isFetching = false;
                    existingCeremony.didInvalidate = false;
                    existingCeremony.lastUpdated = Date.now();
                    existingCeremony.item = ceremony;
                } else {
                    draft[ceremony.id] = {
                        error: null,
                        isFetching: false,
                        didInvalidate: false,
                        lastUpdated: Date.now(),
                        item: ceremony
                    };
                }
            });
            break;
        }

        case FETCH_CEREMONY_FAILURE: {
            if (ceremony) {
                ceremony.isFetching = false;
                ceremony.error = action.error;
            }
            break;
        }

        case DELETE_CEREMONY: {
            delete draft[action.payload];
        }

        case RELOCATE_CEREMONY: {
            delete draft[action.payload];
        }
    }
}, {});

const allCeremonies = produce((draft, action) => {
    switch (action.type) {
        case FETCH_CEREMONY_SUCCESS:
        case PUT_CEREMONIES: {
            const ceremonies = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            ceremonies.forEach(ceremony => {
                const id = ceremony.id;
                if (!draft.find(item => item === id)) {
                    draft.push(ceremony.id);
                }
            });
            break;
        }

        case DELETE_CEREMONY: {
            draft.splice(draft.findIndex(id => id === action.payload), 1);
        }
    }
}, []);

export default combineReducers({
    byId: ceremoniesById,
    allIds: allCeremonies
});
