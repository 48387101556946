import { SHOW_TOAST, HIDE_TOAST } from "../constants/actionTypes";
import createToast from "../helpers/toastFactory";

/**
 * Action creator for opening up a toast.
 * @param {Object} options Options for showing up a toast.
 * @param {string} options.title Title, if skipped, toast header will not be rendered.
 * @param {string} options.body Body text.
 * @param {number} options.timeout Time in milliseconds after which the toast will be hidden.
 * @param {boolean} options.preventDismiss Whether to show a close button.
 * @param {string} options.themeClass Theme name, go to https://bulma.io/documentation/components/message/#colors for available options.
 */
export function showToast(options = {}) {
    return {
        type: SHOW_TOAST,
        payload: createToast(options)
    };
}

/**
 * Action creator for hiding a toast.
 * @param {number} payload ID of a toast.
 */
export function hideToast(payload) {
    return {
        type: HIDE_TOAST,
        payload
    };
}
