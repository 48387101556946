import { connect } from "react-redux";
import { hideToast } from "../../actions/toasts";

import ToastsView from "./ToastsView";

const mapDispatchToProps = dispatch => ({
    removeToast: id => dispatch(hideToast(id))
});

const mapStateToProps = state => ({
    toasts: state.toasts
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToastsView);
