import React from "react";

import ShowIf from "../ShowIf";
import placerholderIcon from "../../assets/mountains.svg";

import styles from "./TimelinePhoto.module.scss";
import i18n from "../../constants/i18n";
import { get } from "micro-dash";

function isPlaceholderPhoto(photo) {
    return photo.isPlaceholder;
}

function isPhotoBeingProcessed(photo) {
    return (
        !isPlaceholderPhoto(photo) &&
        !photo.fileName &&
        !photo.processingFailure
    );
}

function hasProcessingFailure(photo) {
    return photo.processingFailure;
}

const TimelinePhotoView = ({
    photo,
    showPhotoUploadModal,
    isSelected,
    onSelect
}) => (
    <div
        className={`is-relative ${styles.photoContainer} ${
            isPlaceholderPhoto(photo) ? styles.isPlaceholder : ""
        }`}
    >
        {isPhotoBeingProcessed(photo) ? (
            <div className={`loader-container ${styles.loaderContainer}`}>
                <div className="loader" />
            </div>
        ) : (
            <div>
                {hasProcessingFailure(photo) ? (
                    <div
                        className={`loader-container ${styles.loaderContainer}`}
                    >
                        <span
                            className="icon is-right has-text-danger"
                            title={i18n.generic.processingFailureMessage.replace(
                                "<FILENAME>",
                                photo.originalFileName
                            )}
                        >
                            <i className="fas fa-times-circle" />
                        </span>
                    </div>
                ) : (
                    <div onClick={() => onSelect(photo.id)}>
                        <div
                            onClick={() =>
                                isPlaceholderPhoto(photo) &&
                                showPhotoUploadModal()
                            }
                            className={`${styles.photo} ${
                                isSelected ? styles.isSelected : ""
                            }`}
                            style={{
                                backgroundImage: `url(${
                                    isPlaceholderPhoto(photo)
                                        ? placerholderIcon
                                        : `${photo.fileName}`
                                })`
                            }}
                        ></div>
                        <ShowIf condition={isSelected}>
                            <div className={styles.selectIconContainer}>
                                <span className="icon">
                                    <i className="fas fa-check"></i>
                                </span>
                            </div>
                        </ShowIf>
                    </div>
                )}
            </div>
        )}
    </div>
);

TimelinePhotoView.defaultProps = {
    isSelected: false,
    onSelect: () => {}
};

export default TimelinePhotoView;
