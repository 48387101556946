import React from "react";

import TableItem from "../TableItem";

const OrganizationTable = ({
    organizationIds,
    onEdit,
    onSelect,
    onShowOrganization
}) => {
    return (
        <div>
            <table className="table is-hoverable is-fullwidth">
                <tbody>
                    {organizationIds.map(id => (
                        <TableItem
                            key={id}
                            organizationId={id}
                            onEdit={onEdit}
                            onSelect={onSelect}
                            onShowOrganization={onShowOrganization}
                        ></TableItem>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrganizationTable;
