import React from "react";
import { ErrorMessage, FieldArray } from "formik";
import { isString, sortBy } from "micro-dash";
import Tippy from "@tippy.js/react";

import i18n from "../../../../constants/i18n";
import styles from "./AdminManagementModal.module.scss";

import Input from "../../../Input";
import FieldArrayAddButton from "../../../FieldArrayAddButton";
import ShowIf from "../../../ShowIf";

const AdminManagementModalView = ({
    admins,
    currentUserId,
    errors,
    hideModal,
    isFetching,
    isSubmitting,
    isVisible,
    onDelete,
    submitForm,
    touched,
    values
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.adminManagementModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p className={styles.description}>
                        {i18n.adminManagementModal.description}
                    </p>
                    <ShowIf condition={isFetching}>
                        <div className={`loader ${styles.loader}`} />
                    </ShowIf>
                    <ShowIf condition={!isFetching}>
                        <div className={styles.listContainer}>
                            {sortBy(admins, admin => admin.name).map(admin => (
                                <div key={admin.id} className={styles.listItem}>
                                    <div className={styles.listItemDetails}>
                                        <p className={styles.listItemName}>
                                            {admin.name}
                                        </p>
                                        <p className={styles.listItemEmail}>
                                            {admin.email}
                                        </p>
                                    </div>
                                    <div className={styles.listItemActions}>
                                        <ShowIf
                                            condition={!admin.emailVerifiedAt}
                                        >
                                            <Tippy
                                                content={
                                                    i18n.generic.notYetVerified
                                                }
                                            >
                                                <span
                                                    className={`icon ${styles.icon}`}
                                                >
                                                    <i className="far fa-clock"></i>
                                                </span>
                                            </Tippy>
                                        </ShowIf>
                                        <ShowIf
                                            condition={
                                                admin.id !== currentUserId
                                            }
                                        >
                                            <span
                                                onClick={() =>
                                                    onDelete(admin.id)
                                                }
                                                className={`icon ${styles.icon} ${styles.clickable}`}
                                            >
                                                <i className="fas fa-lg fa-trash-alt"></i>
                                            </span>
                                        </ShowIf>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <FieldArray
                            name="admins"
                            render={arrayHelpers => (
                                <div>
                                    {values.admins.map((_, index) => (
                                        <div
                                            key={index}
                                            className={`field ${styles.field}`}
                                        >
                                            <div
                                                className={
                                                    styles.inputContainer
                                                }
                                            >
                                                <Input
                                                    errors={errors}
                                                    name={`admins[${index}].name`}
                                                    placeholder={
                                                        i18n.generic.name
                                                    }
                                                    smallerMargin
                                                    touched={touched}
                                                />
                                                <Input
                                                    errors={errors}
                                                    name={`admins[${index}].email`}
                                                    placeholder={
                                                        i18n.generic.email
                                                    }
                                                    smallerMargin
                                                    touched={touched}
                                                />
                                            </div>
                                            <span
                                                onClick={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                                className={`icon is-right ${styles.deleteButton}`}
                                            >
                                                <i className="fas fa-times" />
                                            </span>
                                        </div>
                                    ))}
                                    <FieldArrayAddButton
                                        onClick={() =>
                                            arrayHelpers.push({
                                                name: "",
                                                email: ""
                                            })
                                        }
                                    />
                                    <ShowIf condition={isString(errors.admins)}>
                                        <ErrorMessage
                                            name="admins"
                                            component="p"
                                            className="help is-danger is-size-6"
                                        />
                                    </ShowIf>
                                </div>
                            )}
                        />
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default AdminManagementModalView;
