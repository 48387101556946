import { connect } from "react-redux";

import { hideModal, showModal } from "../../../../actions/modals";

import modalTypes from "../../../../constants/modalTypes";

import DurationWarningModalView from "./DurationWarningModalView";

const mapStateToProps = (state, props) => {
    return {
        isVisible: true
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        hideModal: () => dispatch(hideModal()),
        showBlockTypeModal: () => {
            dispatch(hideModal());

            dispatch(
                showModal({
                    type: modalTypes.BLOCK_TYPE,
                    props: {
                        ceremonyId: props.ceremonyId,
                        position: props.position
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DurationWarningModalView);
