import { connect } from "react-redux";

import { deleteBlock } from "../../../../actions/blocks";
import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";

import { putNormalizedEntities } from "../../../../helpers";
import {
    getWalkInBlockId,
    getWalkOutBlockId,
    getCeremonyBlockWithAssetsById,
    getCeremonyBlocksWithAssets
} from "../../../../selectors/blocks";

import SpeechModalContainer from "./SpeechModalContainer";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const blocksWithAssets = getCeremonyBlocksWithAssets(state, ceremonyId);
    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    let block = getCeremonyBlockWithAssetsById(state, {
        ceremonyId,
        blockId: props.blockId
    });

    if (block && block.id === walkInBlockId) {
        block = { ...block, isWalkInBlock: true };
    }
    if (block && block.id === walkOutBlockId) {
        block = { ...block, isWalkOutBlock: true };
    }

    return {
        block,
        blocks: blocksWithAssets,
        ceremony: state.entities.ceremonies.byId[ceremonyId],
        isEditing: Boolean(props.blockId),
        isVisible: true
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        onBlockDeleted: id => dispatch(deleteBlock(id)),
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeechModalContainer);
