import React from "react";
import i18n from "../../constants/i18n";
import styles from "./OrganizationDetail.module.scss";
import routeNames from "../../constants/routeNames";
import Locations from "./Locations";
import Users from "./Users";

const OrganizationDetailView = ({
    organization,
    history,
    onEdit,
    isFetching
}) => {
    return (
        <div className="container">
            {isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader" />
                </div>
            ) : (
                <div className="tile is-ancestor">
                    <div className="tile is-parent">
                        <div className="tile is-child box is-12">
                            <div className={`${styles.backButton}`}>
                                <div
                                    onClick={() =>
                                        history.push(
                                            `${routeNames.organizationsPaginated}`.replace(
                                                ":pageNumber",
                                                1
                                            )
                                        )
                                    }
                                >
                                    <span className="icon is-small">
                                        <i className="fas fa-chevron-left"></i>
                                    </span>
                                    <span>
                                        {
                                            i18n.organizationDetail
                                                .backToOrganizationsOverview
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="level">
                                <div className="level-left">
                                    <h1>
                                        {
                                            i18n.organizationDetail
                                                .organizationLabel
                                        }
                                        : {organization.name}
                                    </h1>
                                </div>
                                <div className="level-right">
                                    <button
                                        onClick={() => onEdit(organization.id)}
                                        className="button is-secondary is-rounded"
                                    >
                                        {i18n.organizationDetail.editButton}
                                    </button>
                                </div>
                            </div>

                            <table className="table is-bordered is-striped is-fullwidth">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .idLabel
                                                }
                                            </strong>
                                        </td>
                                        <td>{organization.id}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .nameLabel
                                                }
                                            </strong>
                                        </td>
                                        <td>{organization.name}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .address
                                                }
                                            </strong>
                                        </td>
                                        <td>{organization.address}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .zipCode
                                                }
                                            </strong>
                                        </td>
                                        <td>{organization.zipcode}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {i18n.organizationDetail.city}
                                            </strong>
                                        </td>
                                        <td>{organization.city}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .emailLabel
                                                }
                                            </strong>
                                        </td>
                                        <td>{organization.email}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {i18n.organizationDetail.phone}
                                            </strong>
                                        </td>
                                        <td>{organization.phoneNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                {
                                                    i18n.organizationDetail
                                                        .webcastExternalId
                                                }
                                            </strong>
                                        </td>
                                        <td>
                                            {organization.webcastApiExternalId
                                                ? organization.webcastApiExternalId
                                                : "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            <div className="level">
                <Locations />
            </div>
            <div className="level">
                <Users organization={organization} />
            </div>
        </div>
    );
};

export default OrganizationDetailView;
