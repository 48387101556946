import React from "react";

import styles from "./MainCeremonyPhotoModal.module.scss";

import TimelinePhoto from "../../../TimelinePhoto";
import Warning from "../../../Warning";
import ShowIf from "../../../ShowIf";
import i18n from "../../../../constants/i18n";

const MainCeremonyPhotoModalView = ({
    isVisible,
    hideModal,
    photos,
    setFieldValue,
    values,
    submitForm,
    isSubmitting,
    resetMainImage
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className={`modal-card ${styles.modalCard}`}>
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.mainCeremonyPhotoModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p className={styles.title}>
                        {i18n.mainCeremonyPhotoModal.body}
                    </p>
                    <ShowIf condition={!photos.length}>
                        <Warning>
                            {i18n.mainCeremonyPhotoModal.noPhotosUploaded}
                        </Warning>
                    </ShowIf>
                    <div className={styles.photoList}>
                        {photos.map(photo => (
                            <TimelinePhoto
                                key={photo.id}
                                photo={photo}
                                onSelect={photoId =>
                                    setFieldValue("mainImageId", photoId)
                                }
                                isSelected={photo.id === values.mainImageId}
                            />
                        ))}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={() => resetMainImage()}
                        className="button is-secondary is-rounded"
                    >
                        {i18n.mainCeremonyPhotoModal.removeMainImageLabel}
                    </button>
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting || !photos.length}
                        className="button is-primary is-rounded"
                    >
                        {i18n.mainCeremonyPhotoModal.footerButtonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default MainCeremonyPhotoModalView;
