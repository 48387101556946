import { Component } from "react";
import { get } from "micro-dash";
import * as Sentry from "@sentry/browser";

// If a photo will get updated fileName, Formik values will not get updated - therefore in place of cropped photo, there will be constantly a loader.
// This component checks whether any of the photo within the form, needs to get updated fileName field.
class PhotoProcessingWatcher extends Component {
    componentDidUpdate(prevProps) {
        const prevPhotos = get(prevProps, ["block", "photos"]);
        const currPhotos = get(this.props, ["block", "photos"]);

        try {
            if (prevPhotos && currPhotos) {
                const photosToBeUpdated = currPhotos.filter(currPhoto => {
                    const prevPhoto = prevPhotos.find(
                        photo => photo.id === currPhoto.id
                    );
                    return (
                        prevPhoto && prevPhoto.fileName !== currPhoto.fileName
                    );
                });

                if (photosToBeUpdated.length) {
                    const updatedPhotos = this.props.values.photos.map(
                        photo => {
                            const updatedPhoto = photosToBeUpdated.find(
                                newPhoto => newPhoto.id === photo.internalId
                            );

                            if (updatedPhoto) {
                                photo.fileName = updatedPhoto.fileName;
                                photo.processingFailure =
                                    updatedPhoto.processingFailure;
                            }

                            return photo;
                        }
                    );

                    this.props.setFieldValue("photos", updatedPhotos);
                }
            }
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
    }

    render() {
        return null;
    }
}

export default PhotoProcessingWatcher;
