/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { times } from "micro-dash";
import ShowIf from "../ShowIf";

const PaginationView = ({ meta, onSelect }) => {
    const buffer = 3;

    const pageNumbers = times(
        buffer * 2 + 1,
        number => meta.currentPage - buffer + number
    )
        .filter(number => number > 1)
        .filter(number => number < meta.lastPage);

    return (
        <nav
            className="pagination is-centered"
            role="navigation"
            aria-label="pagination"
        >
            <ShowIf condition={meta.currentPage !== 1}>
                <a
                    onClick={() => onSelect(meta.currentPage - 1)}
                    className="pagination-previous"
                >
                    <span className="icon">
                        <i className="fas fa-chevron-left"></i>
                    </span>
                </a>
            </ShowIf>
            <ul className="pagination-list">
                <li>
                    <a
                        onClick={() => onSelect(1)}
                        className={`pagination-link ${
                            1 === meta.currentPage ? "is-current" : ""
                        }`}
                    >
                        1
                    </a>
                </li>
                <ShowIf condition={pageNumbers[0] > 2}>
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                </ShowIf>
                {pageNumbers.map(number => (
                    <li key={number}>
                        <a
                            onClick={() => onSelect(number)}
                            className={`pagination-link ${
                                number === meta.currentPage ? "is-current" : ""
                            }`}
                            aria-label={`Goto page ${number}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
                <ShowIf
                    condition={
                        pageNumbers[pageNumbers.length - 1] < meta.lastPage - 1
                    }
                >
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                </ShowIf>
                <ShowIf condition={meta.lastPage !== 1}>
                    <li>
                        <a
                            onClick={() => onSelect(meta.lastPage)}
                            className={`pagination-link ${
                                meta.lastPage === meta.currentPage
                                    ? "is-current"
                                    : ""
                            }`}
                            aria-label={`Goto page ${meta.lastPage}`}
                        >
                            {meta.lastPage}
                        </a>
                    </li>
                </ShowIf>
            </ul>
            <ShowIf condition={meta.currentPage !== meta.lastPage}>
                <a
                    onClick={() => onSelect(meta.currentPage + 1)}
                    className="pagination-next"
                >
                    <span className="icon">
                        <i className="fas fa-chevron-right"></i>
                    </span>
                </a>
            </ShowIf>
        </nav>
    );
};

export default PaginationView;
