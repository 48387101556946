import React from "react";

import i18n from "../../constants/i18n";
import styles from "./EndUserCeremonyOverview.module.scss";

import Pagination from "../Pagination";
import ShowIf from "../ShowIf";
import Table from "./Table";

const EndUserCeremonyOverviewView = ({
    error,
    ceremonyIds,
    isFetching,
    meta,
    onPaginationSelect,
    onSelect
}) => {
    return (
        <div className="container">
            {isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <Table
                        ceremonyIds={ceremonyIds}
                        onSelect={onSelect}
                    ></Table>
                    <ShowIf
                        condition={isFetching === false && !ceremonyIds.length}
                    >
                        <div className={`${styles.warningContainer}`}>
                            <span className="icon has-text-warning is-medium">
                                <i className="fas fa-2x fa-exclamation-circle"></i>
                            </span>
                            <h3 className="has-centered-text">
                                {i18n.generic.noResultsFound}
                            </h3>
                        </div>
                    </ShowIf>
                    <Pagination
                        meta={meta}
                        onSelect={onPaginationSelect}
                    ></Pagination>
                </>
            )}
            <ShowIf condition={error}>
                <div className={`${styles.warningContainer}`}>
                    <span className="icon has-text-warning is-medium">
                        <i className="fas fa-2x fa-exclamation-circle"></i>
                    </span>
                    <h3 className="has-centered-text">{i18n.generic.error}</h3>
                </div>
            </ShowIf>
        </div>
    );
};

export default EndUserCeremonyOverviewView;
