/* eslint-disable default-case */

import produce from "immer";

import { SHOW_TOAST, HIDE_TOAST } from "../constants/actionTypes";

const initialState = [];

const reducer = produce((draft, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            draft.unshift(action.payload);
            break;
        case HIDE_TOAST:
            return draft.filter(toast => toast.id !== action.payload);
    }
}, initialState);

export default reducer;
