import i18n from "../../../../constants/i18n";

import React from "react";

const BlockAmountWarningView = ({ hideModal, isVisible }) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.blockAmountWarningModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p>{i18n.blockAmountWarningModal.warningTitle}</p>
                    <p>{i18n.blockAmountWarningModal.warningBody}</p>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={hideModal}
                        className="button is-primary is-rounded"
                    >
                        {i18n.blockAmountWarningModal.buttonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};
export default BlockAmountWarningView;
