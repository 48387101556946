import { SHOW_MODAL, HIDE_MODAL } from "../constants/actionTypes";

/**
 * Action creator for opening up a modal.
 * @param {Object} payload Options for showing up a modal.
 * @param {*} payload.props Props to be passed onto a modal.
 * @param {string} payload.type Type of the modal to be shown. Please use an enum for passing the value.
 */
export function showModal(payload) {
    return dispatch => {
        document.documentElement.classList.add("is-clipped");

        return dispatch({
            type: SHOW_MODAL,
            payload
        });
    };
}

export function hideModal() {
    return dispatch => {
        document.documentElement.classList.remove("is-clipped");

        return dispatch({
            type: HIDE_MODAL
        });
    };
}
