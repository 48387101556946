import T from "prop-types";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { draggableIdPrefixes } from "../constants";
import { CustomDragDropContext } from "../DragDropRoot";

function generateDraggableId(blockId) {
    return `${draggableIdPrefixes.block}${blockId}`;
}

const DraggableBlockView = ({ block, children, index }) => {
    const isNonMovableBlock = block.isWalkInBlock || block.isWalkOutBlock;

    return (
        <CustomDragDropContext.Consumer>
            {context => (
                <Draggable
                    draggableId={generateDraggableId(block.id)}
                    index={index}
                    isDragDisabled={
                        context.isDraggingPhoto || isNonMovableBlock
                    }
                >
                    {providedDraggable => (
                        <div
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            ref={providedDraggable.innerRef}
                        >
                            {children}
                            {providedDraggable.placeholder}
                        </div>
                    )}
                </Draggable>
            )}
        </CustomDragDropContext.Consumer>
    );
};

DraggableBlockView.propTypes = {
    block: T.shape({
        id: T.number,
        isWalkInBlock: T.bool,
        isWalkOutBlock: T.bool
    }),
    children: T.oneOfType([T.arrayOf(T.node), T.node]),
    index: T.number
};

export default DraggableBlockView;
