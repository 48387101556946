import { connect } from "react-redux";

import { deleteBlock } from "../../../../actions/blocks";
import { hideModal, showModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { putNormalizedEntities } from "../../../../helpers";
import modalTypes from "../../../../constants/modalTypes";
import {
    getCeremonyBlockWithAssetsById,
    getWalkInBlockId,
    getWalkOutBlockId
} from "../../../../selectors/blocks";

import BlockEditModalContainer from "./BlockEditModalContainer";
import { get } from "micro-dash";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const blockId = props.blockId;
    const ceremony = state.entities.ceremonies.byId[ceremonyId];

    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    let block = getCeremonyBlockWithAssetsById(state, { ceremonyId, blockId });
    // console.log(block.photos);

    if (block.id === walkInBlockId) {
        block = { ...block, isWalkInBlock: true };
    }
    if (block.id === walkOutBlockId) {
        block = { ...block, isWalkOutBlock: true };
    }

    return {
        block,
        ceremonyId,
        speechPhotoLimit: get(ceremony, ["item", "numberPhotos"]),
        photos: state.entities.photos,
        isVisible: true
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        },
        showToast: options => {
            dispatch(showToast(options));
        },
        hideModal: () => {
            dispatch(hideModal());
        },
        onBlockDeleted: id => {
            dispatch(deleteBlock(id));
        },
        showPhotoUploadModal: () => {
            dispatch(hideModal());
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_PHOTO_UPLOAD,
                    props: {
                        ceremonyId: parseInt(props.ceremonyId),
                        blockId: parseInt(props.blockId)
                    }
                })
            );
        },
        showBlockTypeModal: blockId =>
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_TYPE,
                    props: {
                        ceremonyId: parseInt(props.ceremonyId),
                        blockId,
                        isEditing: true,
                        isWalkInBlock: props.isWalkInBlock,
                        isWalkOutBlock: props.isWalkOutBlock
                    }
                })
            ),
        showPhotoAdjustmentModal: (blockId, photoId) => {
            dispatch(hideModal());
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_PHOTO_ADJUSTMENT,
                    props: {
                        ceremonyId: parseInt(props.ceremonyId),
                        blockId,
                        photoId
                    }
                })
            );
        },

        showVideoModal: blockId =>
            dispatch(
                showModal({
                    type: modalTypes.VIDEO_UPLOAD,
                    props: { ceremonyId: parseInt(props.ceremonyId), blockId }
                })
            ),
        showMusicModal: blockId =>
            dispatch(
                showModal({
                    type: modalTypes.MUSIC_UPLOAD,
                    props: { ceremonyId: parseInt(props.ceremonyId), blockId }
                })
            )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockEditModalContainer);
