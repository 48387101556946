import T from "prop-types";
import React from "react";

import { Droppable } from "react-beautiful-dnd";
import { droppableIdPrefixes } from "../constants";
import { CustomDragDropContext } from "../DragDropRoot";

function generateDroppableId(blockId) {
    return `${droppableIdPrefixes.blockPhoto}${blockId}`;
}

const DroppableBlockView = ({ block, render }) => {
    return (
        <CustomDragDropContext.Consumer>
            {context => (
                <Droppable
                    isDropDisabled={!context.isDraggingPhoto}
                    direction="horizontal"
                    droppableId={generateDroppableId(block.id)}
                >
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                            {render(snapshot, provided.placeholder)}
                        </div>
                    )}
                </Droppable>
            )}
        </CustomDragDropContext.Consumer>
    );
};

DroppableBlockView.propTypes = {
    block: T.shape({
        id: T.number
    }),
    render: T.func
};

export default DroppableBlockView;
