import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import styles from "./Toasts.module.scss";

import Toast from "./components/Toast";

class ToastsView extends Component {
    onDismiss = id => {
        this.props.removeToast(id);
    };

    render() {
        const { toasts } = this.props;

        return (
            <ul className={`${styles.toasts}`}>
                <TransitionGroup className="toasts-transition-group">
                    {toasts.map(toast => (
                        <CSSTransition
                            key={toast.id}
                            timeout={toast.timeout || 5000}
                            classNames={{
                                enter: styles.enter,
                                enterActive: styles.enterActive,
                                exit: styles.exit,
                                exitActive: styles.exitActive
                            }}
                        >
                            <Toast {...toast} onDismiss={this.onDismiss} />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </ul>
        );
    }
}

export default ToastsView;
