import React, { Component } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { normalize } from "normalizr";
import { withRouter } from "react-router-dom";
import { pick } from "micro-dash";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import { crematory as crematorySchema } from "../../../../schemas";
import i18n from "../../../../constants/i18n";

import ValidationError from "../../../../helpers/ValidationError";

import DeviceModalView from "./DeviceModalView";

const deviceTypes = [
    { id: 1, name: "Bediend door een Control System", value: "crestron" },
    { id: 2, name: "Lite", value: "lite" }
];

class DeviceModalContainer extends Component {
    initialValues = {
        name: "",
        roomId: "",
        roomNumber: "",
        type: 1
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (this.isEditing) {
            this.initialValues = pick(
                props.device,
                "id",
                "name",
                "roomId",
                "roomNumber",
                "type"
            );

            if (props.device.type === "crestron") {
                this.initialValues.type = 1;
            } else if (props.device.type === "lite") {
                this.initialValues.type = 2;
            } else {
                this.initialValues.type = 0;
            }
        }
    }

    validationSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        roomId: Yup.number().required(i18n.validation.required),
        roomNumber: Yup.string()
            .matches(/^[\d]+$/, {
                message: i18n.validation.roomNumber,
                excludeEmptyString: true
            })
            .required(i18n.validation.required),
        type: Yup.string().required(i18n.validation.required)
    });

    get isEditing() {
        return Boolean(this.props.device);
    }

    onSubmit = async (rawValues, formikBag) => {
        const values = {
            ...rawValues,
            type: deviceTypes
                .find(type => type.id === rawValues.type)
                .value.toLowerCase()
        };

        try {
            if (this.isEditing) {
                await this.api.putDevice(values);
            } else {
                await this.api.postDevice(values);
            }
            const response = await this.api.getCrematoryById(
                this.props.crematoryId
            );

            const { entities } = normalize(response.data.data, crematorySchema);
            this.props.updateStoreEntities(entities);

            formikBag.setSubmitting(false);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.createDeviceSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.processingFailure.deviceGenerationError,
                    title: "Error",
                    themeClass: "is-danger",
                    timeout: 10000,
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                render={props => (
                    <DeviceModalView
                        hideModal={this.props.hideModal}
                        isVisible={this.props.isVisible}
                        rooms={this.props.rooms}
                        types={deviceTypes}
                        isEditing={this.isEditing}
                        {...props}
                    ></DeviceModalView>
                )}
            />
        );
    }
}

export default withRouter(DeviceModalContainer);
