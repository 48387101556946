import React from "react";

import styles from "./JoyrideTooltip.module.scss";

import i18n from "../../constants/i18n";

const JoyrideTooltipView = ({
    index,
    isLastStep,
    step,
    size,
    primaryProps,
    skipProps,
    tooltipProps
}) => {
    return (
        <div {...tooltipProps}>
            <div className={`modal-card ${styles.modalCard}`}>
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.tutorial.tooltipTitle}
                    </h2>
                    <button
                        {...skipProps}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.progressContainer}>
                        {Array(size)
                            .fill()
                            .map((_, i) => (
                                <React.Fragment key={i}>
                                    <div
                                        className={`
                    ${styles.progressItem}
                    ${i > index ? styles.next : ""}
                    ${i === index ? styles.current : ""}
                    ${i < index ? styles.prev : ""}
                  `}
                                    >
                                        <span>{i + 1}</span>
                                    </div>
                                    {i + 1 !== size ? (
                                        <div
                                            className={`
                        ${styles.progressDash}
                        ${i > index ? styles.next : ""}
                        ${i === index ? styles.current : ""}
                        ${i < index ? styles.prev : ""}
                      `}
                                        />
                                    ) : null}
                                </React.Fragment>
                            ))}
                    </div>
                    <div>
                        <p className={styles.content}>{step.content}</p>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        {...primaryProps}
                        className="button is-primary is-rounded"
                    >
                        {isLastStep
                            ? i18n.tutorial.finish
                            : i18n.tutorial.nextStep}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default JoyrideTooltipView;
