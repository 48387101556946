import { connect } from "react-redux";

import BlockPhotoUploadModalContainer from "./BlockPhotoUploadModalContainer";
import { putNormalizedEntities } from "../../../../helpers";
import { putCeremonies } from "../../../../actions/ceremonies";
import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";

const mapStateToProps = (state, props) => {
    const { ceremonyId, blockId } = props;

    return {
        isVisible: true,
        ceremony: state.entities.ceremonies.byId[ceremonyId],
        block: state.entities.blocks.byId[blockId]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdatedCeremony: ceremony => dispatch(putCeremonies([ceremony])),
        hideModal: () => dispatch(hideModal()),
        showToast: options => dispatch(showToast(options)),
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockPhotoUploadModalContainer);
