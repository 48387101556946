import React from "react";

import styles from "./Warning.module.scss";

const Warning = ({ children }) => (
    <div className={`${styles.warningContainer}`}>
        <span className="icon has-text-warning is-medium">
            <i className="fas fa-2x fa-exclamation-circle"></i>
        </span>
        <h3 className="has-centered-text">{children}</h3>
    </div>
);

export default Warning;
