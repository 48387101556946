import { get, sortBy } from "micro-dash";

import blockTypes from "../constants/blockTypes";
import formatFilename from "./formatFilename";
import i18n from "../constants/i18n";

export function insertBlockAtIndex({ array, item, index }) {
    const sortedItemsByPosition = sortBy(array, [item => item.position]);
    return [
        ...sortedItemsByPosition.slice(0, index),
        item,
        ...sortedItemsByPosition.slice(index)
    ];
}

export async function updateBlockPositions({ blocks, putBlock, ceremonyId }) {
    const reorderedBlocks = blocks.map((block, index) => ({
        ...block,
        position: index
    }));
    const results = await Promise.all(
        reorderedBlocks.map(async block => putBlock(ceremonyId, block))
    );

    return results.map(result => result.data.data);
}

export function getBlockTitle(block) {
    if (block.isWalkInBlock) {
        return "Inloop";
    }

    if (block.isWalkOutBlock) {
        return "Uitloop";
    }

    if (block.type === blockTypes.AUDIO) {
        return formatFilename(
            get(
                block,
                ["audioTrack", "originalFileName"],
                i18n.blockTypeModal.musicType
            )
        );
    }

    if (block.type === blockTypes.VIDEO) {
        return formatFilename(
            get(
                block,
                ["video", "originalFileName"],
                i18n.blockTypeModal.videoType
            )
        );
    }

    if (block.type === blockTypes.SPEAKER) {
        const speakerName = block.speaker
            ? block.speaker
            : i18n.blockTypeModal.speechType;
        return formatFilename(speakerName);
    }
}

export function getBlockHeaderClassName(block, styles) {
    if (block.isWalkInBlock || block.isWalkOutBlock) {
        return styles.isGray;
    }

    if (block.type === blockTypes.AUDIO) {
        return styles.isPink;
    }

    if (block.type === blockTypes.VIDEO) {
        return styles.isPink;
    }

    if (block.type === blockTypes.SPEAKER) {
        return styles.isPurple;
    }

    return "";
}
