/* eslint-disable default-case */

import produce from "immer";

import {
    INVALIDATE_TOTAL_DATA_USAGE,
    FETCH_TOTAL_DATA_USAGE_REQUEST,
    FETCH_TOTAL_DATA_USAGE_SUCCESS,
    FETCH_TOTAL_DATA_USAGE_FAILURE
} from "../constants/actionTypes";

const initialState = {
    didInvalidate: false,
    error: null,
    isFetching: false,
    lastUpdated: null,
    value: null
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case INVALIDATE_TOTAL_DATA_USAGE:
            draft.didInvalidate = true;
            break;
        case FETCH_TOTAL_DATA_USAGE_REQUEST:
            draft.isFetching = true;
            break;
        case FETCH_TOTAL_DATA_USAGE_SUCCESS:
            draft.error = null;
            draft.isFetching = false;
            draft.didInvalidate = false;
            draft.lastUpdated = Date.now();
            draft.value = action.payload;
            break;
        case FETCH_TOTAL_DATA_USAGE_FAILURE:
            draft.isFetching = false;
            draft.error = action.error;
            break;
    }
}, initialState);

export default reducer;
