/**
 *
 * @param {ProgressEvent} event ProgressEvent
 * @param {*} setStatus - Formik's setStatus method
 */
const handleUploadProgress = (event, setStatus) => {
    if (event.lengthComputable) {
        let percentComplete = event.loaded / event.total;
        percentComplete = parseInt(percentComplete * 100);

        // Upload is just a part of the request, we still have to wait for server response
        // When upload is done, let's keep progress bar at 95% and move it to 100% after server returns a response.
        if (percentComplete < 5) {
            setStatus({ uploadProgress: 0 });
        } else {
            setStatus({ uploadProgress: percentComplete - 5 });
        }
    }
};

export default handleUploadProgress;
