import { cloneDeep } from "micro-dash";

export function processUserFormData(values, existingUser) {
    const formValues = cloneDeep(values);

    if (formValues.email === existingUser.email) {
        delete formValues.email;
    }

    if (formValues.locations) {
        formValues.locations = formValues.locations.map(
            location => location.value
        );
    }

    return formValues;
}
