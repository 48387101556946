import React from "react";
import ReactCrop from "react-image-crop";

import styles from "./BlockImageCropModal.module.scss";

import i18n from "../../../../constants/i18n";

const BlockImageCropModalView = ({
    crop,
    isSubmitting,
    isVisible,
    onCropChange,
    onImageLoaded,
    onSubmit,
    src,
    onReturnToImageAdjustmentModal
}) => {
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoADjustmentModal.cropTitle}
                    </h2>
                    <button
                        onClick={onReturnToImageAdjustmentModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.cropContainer}>
                        {src && (
                            <ReactCrop
                                crop={crop}
                                onChange={onCropChange}
                                onImageLoaded={onImageLoaded}
                                src={src}
                                keepSelection
                            />
                        )}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={onSubmit}
                        className={`button is-primary is-rounded ${
                            isSubmitting ? "is-loading" : ""
                        }`}
                    >
                        {i18n.photoADjustmentModal.cropAndReturnToBlockEdit}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default BlockImageCropModalView;
