import React from "react";
import ReactCrop from "react-image-crop";

import styles from "./LibraryImageCropModal.module.scss";

import i18n from "../../../../constants/i18n";
import ShowIf from "../../../ShowIf";

const LibraryImageCropModalView = ({
    crop,
    isSubmitting,
    isVisible,
    onCropChange,
    onImageLoaded,
    onCreateNewImage,
    onUpdateCurrentImage,
    src,
    onReturnToImageAdjustmentModal,
    isImageUsedInBlock,
    updateImageSettings
}) => {
    const [buttonClicked, setButtonClicked] = React.useState(false);

    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoADjustmentModal.cropTitle}
                    </h2>
                    <button
                        onClick={onReturnToImageAdjustmentModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <ShowIf condition={!buttonClicked}>
                        <div className={styles.AdjustmentContainer}>
                            {src && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={onCropChange}
                                    onImageLoaded={onImageLoaded}
                                    src={src}
                                    keepSelection
                                />
                            )}
                        </div>
                    </ShowIf>
                    <ShowIf condition={buttonClicked}>
                        <div>
                            <p>
                                {i18n.photoADjustmentModal.imageInBlockWarning}"
                                <strong>
                                    {
                                        i18n.photoADjustmentModal
                                            .updateCurrentImage
                                    }
                                </strong>
                                "{" "}
                                {i18n.photoADjustmentModal.imageInBlockWarning2}
                                "
                                <strong>
                                    {i18n.photoADjustmentModal.addAsNewImage}
                                </strong>
                                ".
                            </p>
                        </div>
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <ShowIf condition={!isSubmitting}>
                        <button
                            onClick={() => {
                                if (buttonClicked || !isImageUsedInBlock) {
                                    onUpdateCurrentImage();
                                } else {
                                    setButtonClicked(true);
                                }
                            }}
                            className={`button is-primary is-rounded ${
                                isSubmitting ? "is-loading" : ""
                            }`}
                        >
                            {i18n.photoADjustmentModal.updateCurrentImage}
                        </button>
                        <button
                            onClick={onCreateNewImage}
                            className={`button is-primary is-rounded ${
                                isSubmitting ? "is-loading" : ""
                            }`}
                        >
                            {i18n.photoADjustmentModal.addAsNewImage}
                        </button>
                    </ShowIf>
                    <ShowIf condition={isSubmitting}>
                        <button
                            className={`button is-primary is-rounded is-loading`}
                        >
                            {" "}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default LibraryImageCropModalView;
