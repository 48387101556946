import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import { ceremony as ceremonySchema } from "../../../../schemas";
import { extractApiErrorMessage } from "../../../../helpers";

import UnlockCeremonyModalView from "./UnlockCeremonyModalView";

class UnlockCeremonyModalContainer extends Component {
    initialValues = {
        duration: ""
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    validationSchema = Yup.object().shape({
        duration: Yup.number().required(i18n.validation.required)
    });

    onSubmit = async (values, formikBag) => {
        try {
            await this.api.unlockCeremony(
                this.props.ceremonyId,
                values.duration
            );

            formikBag.setSubmitting(false);

            const {
                data: { data: ceremony }
            } = await this.api.getCeremonyById(this.props.ceremonyId);

            const { entities } = normalize(ceremony, ceremonySchema);
            this.props.updateStoreEntities(entities);

            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.unlockCeremonySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            formikBag.setSubmitting(false);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                render={props => (
                    <UnlockCeremonyModalView
                        hideModal={this.props.hideModal}
                        isVisible={this.props.isVisible}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(UnlockCeremonyModalContainer);
