import React from "react";

import styles from "./TableItem.module.scss";
import i18n from "../../../constants/i18n";

const TableItemView = ({ user, startImpersonation, onShowUser }) => {
    if (!user) {
        return null;
    }

    return (
        <tr>
            <td>
                <h2>{user.item.name}</h2>
            </td>
            <td>
                {user.item.role.length === 0 ? "-" : i18n.roles[user.item.role]}
            </td>
            <td>
                <div className={styles.alignCenter}>
                    <span className={`icon ${styles.icon}`}>
                        <i className="fas fa-lg fa-envelope"></i>
                    </span>
                    <span>{user.item.email}</span>
                </div>
            </td>
            <td>{user.item.organizationName}</td>

            <td>
                <button
                    onClick={() => onShowUser(user.item.id)}
                    className="button is-rounded"
                >
                    {i18n.userOverview.showUser}
                </button>
            </td>
            <td>
                <button
                    onClick={() =>
                        startImpersonation(user.item.id, user.item.name)
                    }
                    className={`button is-rounded is-secondary ${styles.detailsButton}`}
                >
                    {i18n.userOverview.tableItemImpersonateButtonLabel}
                </button>
            </td>
        </tr>
    );
};

export default TableItemView;
