import { authTokenPath } from "../constants/storage";
import * as Sentry from "@sentry/browser";

export function getAuthToken() {
    try {
        let token = localStorage.getItem(authTokenPath);
        token = token ? JSON.parse(token) : null;

        return token;
    } catch (error) {
        Sentry.captureException(error);

        if (process.env.NODE_ENV !== "production") {
            console.error(
                "Couldn't write authentication token to LocalStorage."
            );
            console.error(error);
        }
    }
}

function setAuthToken(state) {
    try {
        localStorage.setItem(authTokenPath, JSON.stringify(state.auth.token));
    } catch (error) {
        Sentry.captureException(error);

        if (process.env.NODE_ENV !== "production") {
            console.error(
                "Couldn't write authentication token to LocalStorage."
            );
            console.error(error);
        }
    }
}

export function clearAuthToken() {
    localStorage.removeItem(authTokenPath);
}

export function persistAuthToken(store) {
    let currentAuthValue;
    store.subscribe(() => {
        let previousAuthValue = currentAuthValue;
        currentAuthValue = store.getState().auth;

        if (previousAuthValue !== currentAuthValue) {
            setAuthToken(store.getState());
        }
    });
}
