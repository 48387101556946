import React from "react";

import styles from "./BlockPreviewModal.module.scss";

import i18n from "../../../../constants/i18n";
import {
    getBlockTitle,
    getBlockHeaderClassName
} from "../../../../helpers/blockHelpers";

const BlockPreviewModalView = ({
    activeBlockIndex,
    block,
    blockArrayLength,
    disableNextBlockButton,
    disablePrevBlockButton,
    isVisible,
    onNextBlockRequest,
    onPrevBlockRequest,
    preview,
    onCloseButtonClick
}) => {
    const blocksLength = [...Array(blockArrayLength).keys()];

    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className={`modal-card ${styles.modalCard}`}>
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.previewModal.title}
                    </h2>
                    <button
                        onClick={onCloseButtonClick}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.header}>
                        <div className={styles.box}>
                            <div
                                onClick={onPrevBlockRequest}
                                className={`${styles.arrowButton} ${
                                    disablePrevBlockButton
                                        ? styles.disabled
                                        : ""
                                }`}
                            >
                                <span className={`icon ${styles.arrowCircle}`}>
                                    <i className="fas fa-chevron-left"></i>
                                </span>
                                <span>{i18n.previewModal.previous}</span>
                            </div>
                        </div>
                        <div className={styles.box}>
                            <div
                                className={`${
                                    styles.block
                                } ${getBlockHeaderClassName(block, styles)}`}
                            >
                                <span>{getBlockTitle(block)}</span>
                            </div>
                        </div>
                        <div className={styles.box}>
                            <div
                                onClick={onNextBlockRequest}
                                className={`${styles.arrowButton} ${
                                    disableNextBlockButton
                                        ? styles.disabled
                                        : ""
                                }`}
                            >
                                <span className={`icon ${styles.arrowCircle}`}>
                                    <i className="fas fa-chevron-right"></i>
                                </span>
                                <span>{i18n.previewModal.next}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.iframeContainer}>
                        {preview ? (
                            <iframe src={preview.url} title="preview"></iframe>
                        ) : null}
                    </div>
                    <div className={styles.steps}>
                        {blocksLength.map((_, index) => (
                            <div
                                key={index}
                                className={`${styles.step} ${
                                    index === activeBlockIndex
                                        ? styles.active
                                        : ""
                                }`}
                            ></div>
                        ))}
                    </div>
                </section>
                <footer className="modal-card-foot"></footer>
            </div>
        </div>
    );
};

export default BlockPreviewModalView;
