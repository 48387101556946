import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { last } from "micro-dash";

import createApiService from "../../../../network";

import BlockPreviewModalView from "./BlockPreviewModalView";
import routeNames from "../../../../constants/routeNames";

class BlockPreviewModalContainer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            previews: [],
            activeBlockIndex: 0
        };

        this.api = createApiService(axios);

        this.fetchPreview(this.state.activeBlockIndex);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    get activeBlock() {
        return this.props.blocks[this.state.activeBlockIndex];
    }

    get activePreview() {
        return this.state.previews.find(
            preview => preview.blockId === this.activeBlock.id
        );
    }

    isPreviewAlreadyLoaded = blockId => {
        return this.state.previews.some(preview => preview.blockId === blockId);
    };

    getBlockByIndex = index => this.props.blocks[index];

    componentWillUnmount() {
        this._isMounted = false;

        this.state.previews.forEach(preview => {
            window.URL.revokeObjectURL(preview.url);
        });
    }

    fetchPreview = async blockIndex => {
        const { ceremonyId } = this.props;
        const block = this.getBlockByIndex(blockIndex);

        if (this.isPreviewAlreadyLoaded(block.id)) {
            return;
        }

        const previewToken = last(block.previewToken.split("/"));
        const response = await this.api.getBlockPreview(
            ceremonyId,
            block.id,
            previewToken
        );
        const body = await response.blob();

        const iframeUrl = window.URL.createObjectURL(body);

        if (this._isMounted) {
            this.setState({
                previews: [
                    ...this.state.previews,
                    { blockId: block.id, url: iframeUrl }
                ]
            });
        }
    };

    onNextBlockRequest = () => {
        const newIndex = this.state.activeBlockIndex + 1;
        this.setState({ activeBlockIndex: newIndex }, () => {
            this.fetchPreview(newIndex);
        });
    };

    onPrevBlockRequest = () => {
        const newIndex = this.state.activeBlockIndex - 1;
        this.setState({ activeBlockIndex: newIndex }, () => {
            this.fetchPreview(newIndex);
        });
    };

    onCloseButtonClick = () => {
        this.props.hideModal();
        // If CrematoriumAdmin - back to ceremony overview
        if (this.props.isCrematoryAdmin || this.props.isAdmin) {
            return;
        }

        if (this.props.ceremony.item.locked) {
            this.props.showLockedTimelineModal({ areAssetsCleanedUp: false });
        }
    };

    render() {
        return (
            <BlockPreviewModalView
                activeBlockIndex={this.state.activeBlockIndex}
                block={this.props.blocks[this.state.activeBlockIndex]}
                blockArrayLength={this.props.blocks.length}
                disableNextBlockButton={
                    this.state.activeBlockIndex >= this.props.blocks.length - 1
                }
                disablePrevBlockButton={this.state.activeBlockIndex === 0}
                hideModal={this.props.hideModal}
                isVisible={this.props.isVisible}
                onNextBlockRequest={this.onNextBlockRequest}
                onPrevBlockRequest={this.onPrevBlockRequest}
                preview={this.activePreview}
                onCloseButtonClick={this.onCloseButtonClick}
            />
        );
    }
}

export default withRouter(BlockPreviewModalContainer);
