import React from "react";
import Tippy from "@tippy.js/react";

import styles from "./TableItem.module.scss";
import StatusIndicator from "../../StatusIndicator";
import ShowIf from "../../ShowIf";
import i18n from "../../../constants/i18n";
import { baseAssetURL } from "../../../constants";

const TableItemView = ({ crematory, onEdit, onSelect, isAdmin }) => {
    if (!crematory) {
        return null;
    }

    return (
        <tr>
            <td>
                <StatusIndicator
                    isActive={crematory.item.active}
                ></StatusIndicator>
            </td>
            <td>
                <div
                    className={`${styles.logo}`}
                    style={{
                        backgroundImage: `url(${baseAssetURL}${crematory.item.logo})`
                    }}
                ></div>
            </td>
            <td>
                <h2>{crematory.item.name}</h2>
            </td>
            <td>
                <span className="">
                    {crematory.item.city}, {crematory.item.region}
                </span>
            </td>
            <td>
                <div className={styles.alignCenter}>
                    <span className={`icon ${styles.icon}`}>
                        <i className="fas fa-lg fa-user"></i>
                    </span>
                    <span>
                        {crematory.item.ceremonyCount}{" "}
                        {crematory.item.ceremonyCount == 1
                            ? i18n.generic.eventSingular
                            : i18n.generic.eventPlural}
                    </span>
                </div>
            </td>
            <ShowIf condition={isAdmin}>
                <td>
                    <div className={styles.alignCenter}>
                        <span className={`icon ${styles.icon}`}>
                            <i className="fas fa-lg fa-hdd"></i>
                        </span>
                        <span>{crematory.item.dataUsage} MB</span>
                    </div>
                </td>
                <td>
                    <div className="level">
                        <div className="level-item">
                            <Tippy content={i18n.generic.edit}>
                                <span
                                    onClick={() => onEdit(crematory.item.id)}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lg fa-pen"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                </td>
            </ShowIf>
            <td>
                <button
                    onClick={() => onSelect(crematory.item.id)}
                    className={`button is-rounded is-secondary ${styles.detailsButton}`}
                >
                    {i18n.crematoryOverview.tableItemOpenButtonLabel}
                </button>
            </td>
        </tr>
    );
};

export default TableItemView;
