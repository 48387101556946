/* eslint-disable default-case */

import produce from "immer";

import { SHOW_MODAL, HIDE_MODAL } from "../constants/actionTypes";
import modalTypes from "../constants/modalTypes";

const initialState = [
    {
        isVisible: true,
        type: modalTypes.SCREEN_SIZE,
        props: {}
    },
    {
        isVisible: true,
        type: modalTypes.DEACTIVATED_CREMATORY,
        props: {}
    }
];

const reducer = produce((draft, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            draft.push({
                isVisible: true,
                type: action.payload.type,
                props: action.payload.props
            });
            break;
        case HIDE_MODAL:
            draft.pop();
    }
}, initialState);

export default reducer;
