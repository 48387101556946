import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";

import modalTypes from "../../constants/modalTypes";
import { fetchCrematoryIfNeeded } from "../../actions/crematories";
import { deleteRoom } from "../../actions/rooms";
import { showModal } from "../../actions/modals";
import { showToast } from "../../actions/toasts";
import { getCrematoryRooms } from "../../selectors/rooms";
import { getCrematoryDevices } from "../../selectors/devices";
import roles from "../../constants/roles";

import RoomsOverviewContainer from "./RoomsOverviewContainer";

function getCrematoryIdFromRouteParams(match) {
    const id = get(match, ["params", "crematoryID"], null);

    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = (state, props) => {
    const crematoryId = getCrematoryIdFromRouteParams(props.match);
    const crematory = state.entities.crematories.byId[crematoryId];
    const isAdmin = state.user.profile.roles.includes(roles.admin);
    let rooms = [];
    let devices = [];

    if (crematory && crematory.item) {
        rooms = getCrematoryRooms(state, crematoryId);
        devices = getCrematoryDevices(state, rooms.map(room => room.id));
    }

    if (!isAdmin) {
        rooms = rooms.filter(room => !room.hidden);
    }

    return {
        crematory,
        devices,
        rooms,
        isAdmin
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const crematoryId = getCrematoryIdFromRouteParams(props.match);

    return {
        fetchActiveCrematory: () =>
            dispatch(fetchCrematoryIfNeeded(crematoryId)),
        onRoomDeleted: id => dispatch(deleteRoom(id)),
        showForm: room =>
            dispatch(
                showModal({
                    type: modalTypes.ROOM,
                    props: { crematoryId, room }
                })
            ),
        showToast: options => {
            dispatch(showToast(options));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RoomsOverviewContainer)
);
