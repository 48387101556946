import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import EditUserDetailView from "./EditUserDetailView";
import { Formik } from "formik";
import * as Yup from "yup";
import i18n from "../../../constants/i18n";
import createApiService from "../../../network";
import axios from "axios";
import { normalize } from "normalizr";
import ValidationError from "../../../helpers/ValidationError";
import * as Sentry from "@sentry/browser";
import { extractApiErrorMessage } from "../../../helpers";
import { processUserFormData } from "../../../helpers/UserTransformer";
import ShowIf from "../../ShowIf/ShowIfView";
import { fetchUserById } from "../../../actions/user";
import _ from "lodash";
import { user } from "../../../schemas";
import routeNames from "../../../constants/routeNames";

class EditUserDetailContainer extends Component {
    initialValues = {
        name: "",
        email: "",
        phone: "",
        role: "",
        organizationId: "",
        locations: []
    };

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true
        };

        this.api = createApiService(axios);
    }

    editUserSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        email: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required),
        phone: Yup.string()
            .nullable()
            .matches(/^[+()\d-" "]+$/, {
                message: i18n.validation.phoneNumber,
                excludeEmptyString: true
            }),
        organization: Yup.number(),
        role: Yup.string().required(i18n.validation.required),
        locations: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required()
                })
            )
            .when("role", {
                is: value => {
                    return value === "Crematorium";
                },
                then: schema => schema.required(i18n.validation.required)
            })
            .when("role", {
                is: value => {
                    return value === "Organization";
                },
                then: schema => schema.required(i18n.validation.required)
            })
    });

    async componentWillMount() {
        if (this.state.organizations === undefined) {
            this.getOrganizations();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    async componentDidMount() {
        if (!this.props.userToEdit) {
            const userId = parseInt(this.props.match.params.userID);
            await this.props.dispatch(fetchUserById(userId));
        }
    }

    onSubmit = async (values, formikBag) => {
        if (this.props.userToEdit) {
            this.editUser(values, formikBag);
        }
    };

    getOrganizations = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getOrganizations();

            const { data } = response.data;
            this.setState({
                organizations: data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    editUser = async (values, formikBag) => {
        const { userToEdit } = this.props;
        const processedValues = processUserFormData(values, userToEdit);

        try {
            const {
                data: { data: updatedUser }
            } = await this.api.putUser(processedValues.id, processedValues);

            formikBag.setSubmitting(false);
            const { entities } = normalize(updatedUser, user);

            this.props.updateStoreEntities(entities);
            this.props.showToast({
                body: i18n.generic.updateUserSuccess,
                title: "Success",
                themeClass: "is-success"
            });
            this.props.history.push(
                `${routeNames.userDetail}`.replace(":userID", userToEdit.id)
            );
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        const roles = [
            { id: 0, name: "Eindgebruiker", value: "EndUser" },
            { id: 1, name: "Locatie beheerder", value: "Crematorium" },
            {
                id: 2,
                name: "Beheerder van meerdere locaties",
                value: "Organization"
            },
            { id: 3, name: "Admin", value: "Admin" }
        ];

        const { userToEdit } = this.props;

        if (!userToEdit) {
            return <></>;
        }

        const result = _.mergeWith({}, this.initialValues, userToEdit, (a, b) =>
            b === null ? a : undefined
        );

        result.locations = result.locations.map(location => {
            return {
                value: location.id.toString(),
                label: location.name
            };
        });

        return (
            <ShowIf condition={!this.state.isFetching}>
                <Formik
                    initialValues={result}
                    onSubmit={this.onSubmit}
                    validationSchema={this.editUserSchema}
                    render={props => (
                        <EditUserDetailView
                            history={this.props.history}
                            userToEdit={userToEdit}
                            roles={roles}
                            organizations={this.state.organizations}
                            isFetching={this.state.isFetching}
                            {...props}
                        />
                    )}
                />
            </ShowIf>
        );
    }
}

export default withRouter(EditUserDetailContainer);
