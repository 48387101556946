import React from "react";
import { Form } from "formik";

import i18n from "../../../../constants/i18n";

import Input from "../../../Input";
import Select from "../../../Select";

const DeviceModalView = ({
    isVisible,
    hideModal,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    values,
    submitForm,
    isSubmitting,
    rooms,
    types,
    isEditing
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {isEditing
                            ? i18n.deviceModal.titleEditMode
                            : i18n.deviceModal.titleCreateMode}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <Input
                            errors={errors}
                            touched={touched}
                            name="name"
                            label={i18n.generic.name}
                            placeholder={i18n.generic.name}
                        />
                        <Select
                            errors={errors}
                            touched={touched}
                            name="roomId"
                            label={i18n.generic.room}
                            placeholder={i18n.generic.room}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            value={values.roomId}
                            items={rooms}
                        />
                        <Select
                            errors={errors}
                            touched={touched}
                            name="type"
                            label={i18n.deviceModal.deviceTypeLabel}
                            placeholder={i18n.deviceModal.deviceTypePlaceholder}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            value={values.type}
                            items={types}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="roomNumber"
                            label={i18n.generic.roomNumber}
                            placeholder={i18n.generic.roomNumber}
                        />
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {i18n.generic.save}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DeviceModalView;
