import React, { Component } from "react";
import LocationSelectView from "./LocationSelectView";
import createApiService from "../../../../network";
import axios from "axios";
import ShowIf from "../../../ShowIf/ShowIfView";

class LocationSelectContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true
        };

        this.api = createApiService(axios);
    }

    async componentWillMount() {
        if (this.state.isFetching) {
            this.getLocations();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    getLocations = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getAllLocationsForUserManagement();
            const { data } = response.data;
            const locationValues = data.map(location => {
                return {
                    value: location.id.toString(),
                    label: location.name
                };
            });

            this.setState({
                locationsValues: locationValues,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    render() {
        return (
            <ShowIf condition={!this.state.isFetching}>
                <LocationSelectView
                    {...this.props}
                    options={this.state.locationsValues}
                />
            </ShowIf>
        );
    }
}

export default LocationSelectContainer;
