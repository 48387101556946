import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import createApiService from "../../../../network";

import DeactivatedCrematoryModalView from "./DeactivatedCrematoryModalView";

class DeactivatedCrematoryModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    render() {
        return <DeactivatedCrematoryModalView {...this.props} />;
    }
}

export default withRouter(DeactivatedCrematoryModalContainer);
