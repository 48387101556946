import React, { PureComponent } from "react";
import T from "prop-types";
import { ErrorMessage } from "formik";

import i18n from "../../constants/i18n";
import ShowIf from "../ShowIf";

import styles from "./UploadField.module.scss";
import { isString } from "micro-dash";
import { baseAssetURL } from "../../constants";

class UploadFieldView extends PureComponent {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    getImagePreview = () => {
        const { name, setFieldValue, value } = this.props;

        const imageSrc =
            value instanceof File ? window.URL.createObjectURL(value) : value;
        const imageOnLoad = e =>
            value instanceof File && window.URL.revokeObjectURL(e.target.src);

        return (
            <div className={styles.preview}>
                <div className={styles.previewInnerWrapper}>
                    <img
                        className={styles.previewImage}
                        src={imageSrc}
                        style={{ maxHeight: "64px" }}
                        onLoad={imageOnLoad}
                        alt="Uploaded file(s)"
                    />
                    <button
                        onClick={() => setFieldValue(name, null)}
                        className={`delete is-small ${styles.imagePreviewDeleteButton}`}
                    />
                </div>
            </div>
        );
    };

    getUploadedImagePreview = () => {
        const { name, setFieldValue, value } = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.previewInnerWrapper}>
                    <img
                        className={styles.previewImage}
                        src={baseAssetURL + value}
                        style={{ maxHeight: "64px" }}
                        alt="Uploaded File(s)"
                    />
                    <button
                        onClick={() => setFieldValue(name, null)}
                        className={`delete is-small ${styles.imagePreviewDeleteButton}`}
                    />
                </div>
            </div>
        );
    };

    getFilenamePreview = () => {
        const { name, setFieldValue, value } = this.props;

        return (
            <div className={styles.uploadContainer}>
                <span>{value.name || value.originalFileName}</span>
                <span
                    onClick={() => setFieldValue(name, null)}
                    className={`icon is-right ${styles.audioPreviewDeleteButton}`}
                >
                    <i className="fas fa-times" />
                </span>
            </div>
        );
    };

    onResetMultiFileInput = (e, setFieldValue, name) => {
        setFieldValue(name, "");
        this.inputRef.current.value = null;
        e.preventDefault();
    };

    render() {
        const {
            error,
            fileTypes,
            label,
            multiple,
            name,
            setFieldValue,
            setFieldTouched,
            value
        } = this.props;

        const isUploadedImage =
            (value &&
                value.fileName &&
                value.fileName.match(/gif|png|jpg|jpeg|heic|heif|webp/gi)) ||
            (value &&
                isString(value) &&
                value.match(/gif|png|jpg|jpeg|heic|heif|webp/gi));
        const isImage = value && value.type && value.type.includes("image");
        const isAudio = value && value.type && value.type.includes("audio");
        const isVideo = value && value.type && value.type.includes("video");

        return (
            <div className="field">
                <label className="label">{label}</label>
                {value && isUploadedImage
                    ? this.getUploadedImagePreview()
                    : null}
                {value && isImage ? this.getImagePreview() : null}
                {value && isAudio ? this.getFilenamePreview() : null}
                {value && isVideo ? this.getFilenamePreview() : null}

                <ShowIf
                    condition={!value || value instanceof FileList || error}
                >
                    <label className={styles.uploadContainer}>
                        <input
                            onChange={event => {
                                setFieldValue(
                                    name,
                                    event.target.files.length > 1
                                        ? event.target.files
                                        : event.target.files[0]
                                );
                            }}
                            className={styles.uploadInput}
                            type="file"
                            accept={fileTypes.toString()}
                            name={name}
                            ref={this.inputRef}
                            multiple={multiple}
                        />
                        <ShowIf condition={value instanceof FileList && !error}>
                            <div className={styles.uploadContainer}>
                                <span>{i18n.uploadField.multipleFiles}</span>
                                <span
                                    onClick={e =>
                                        this.onResetMultiFileInput(
                                            e,
                                            setFieldValue,
                                            name
                                        )
                                    }
                                    className={`icon is-right ${styles.audioPreviewDeleteButton}`}
                                >
                                    <i className="fas fa-times" />
                                </span>
                            </div>
                        </ShowIf>
                        <ShowIf
                            condition={
                                value instanceof FileList === false || error
                            }
                        >
                            <button
                                onClick={() => this.inputRef.current.click()}
                                className={`button is-secondary is-rounded ${styles.uploadButton}`}
                                type="button"
                            >
                                <span>Kies bestand</span>
                                <span className="icon">
                                    <i className="fas fa-cloud-upload-alt"></i>
                                </span>
                            </button>
                        </ShowIf>
                    </label>
                </ShowIf>
                <ErrorMessage
                    name={name}
                    component="p"
                    className="help is-danger is-size-6"
                />
            </div>
        );
    }
}

UploadFieldView.propTypes = {
    fileTypes: T.arrayOf(T.string).isRequired,
    label: T.string,
    multiple: T.bool,
    name: T.string.isRequired,
    setFieldValue: T.func.isRequired,
    value: T.any
};

export default UploadFieldView;
