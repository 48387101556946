export default function millisToMinutesAndSeconds(millis) {
    if (Number.isNaN(millis)) return "00:00";

    // There are tracks with duration 239700 that's 3:59.7 which will be displayed as 3:60
    // Round millis to thousands (= full seconds), before splitting it into minutes and seconds.
    millis = (millis / 1000).toFixed(0) * 1000;

    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    const result = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;

    return result;
}
