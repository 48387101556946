import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import routeNames from "../constants/routeNames";

function VerifyEmailGuard({ user, children }) {
    return user.emailVerifiedAt ? (
        children
    ) : (
        <Redirect to={routeNames.verifyEmail} />
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.profile
    };
};

export default connect(mapStateToProps)(VerifyEmailGuard);
