import { connect } from "react-redux";

import { logout } from "../../actions/auth";
import LogoutContainer from "./LogoutContainer";

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout());
        }
    };
};

export default connect(
    undefined,
    mapDispatchToProps
)(LogoutContainer);
