import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import { round } from "micro-dash";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import {
    photo as photoSchema,
    block as blockSchema
} from "../../../../schemas";
import { extractApiErrorMessage } from "../../../../helpers";

import LibraryImageCropModalView from "./LibraryImageCropModalView";

class LibraryImageCropModalContainer extends Component {
    constructor(props) {
        super(props);

        this.imageRef = React.createRef();
        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            crop: {
                width: 320,
                x: 160,
                y: 80,
                aspect: props.aspectRatio
            },
            isSubmitting: false
        };
    }

    onCropChange = crop => {
        this.setState({ crop });

        const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
        const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
        this.imageRef.current = { scaleX: scaleX, scaleY: scaleY };
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCreateNewImage = async () => {
        try {
            this.setState({ isSubmitting: true });

            const targetX = this.state.crop.x * this.imageRef.current.scaleX;
            const targetY = this.state.crop.y * this.imageRef.current.scaleY;
            const targetWidth =
                this.state.crop.width * this.imageRef.current.scaleX;
            const targetHeight =
                this.state.crop.height * this.imageRef.current.scaleY;

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("width", round(targetWidth));
            formData.append("height", round(targetHeight));
            formData.append("x", round(targetX));
            formData.append("y", round(targetY));
            formData.append("updateExistingImage", false);

            const {
                data: { data: croppedPhoto }
            } = await this.api.postPhotoAndCrop(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.newImage,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onUpdateCurrentImage = async () => {
        try {
            this.setState({ isSubmitting: true });

            const targetX = this.state.crop.x * this.imageRef.current.scaleX;
            const targetY = this.state.crop.y * this.imageRef.current.scaleY;
            const targetWidth =
                this.state.crop.width * this.imageRef.current.scaleX;
            const targetHeight =
                this.state.crop.height * this.imageRef.current.scaleY;

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("width", round(targetWidth));
            formData.append("height", round(targetHeight));
            formData.append("x", round(targetX));
            formData.append("y", round(targetY));
            formData.append("updateExistingImage", true);

            const {
                data: { data: croppedPhoto }
            } = await this.api.postPhotoAndCrop(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updatedImage,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    isImageUsedInBlock = imageId => {
        for (const block of this.props.blocks) {
            if (block.imageIds.includes(imageId)) {
                return true;
            }
        }
        return false;
    };

    onReturnToImageAdjustmentModal = () => {
        this.props.hideModal();
        this.props.showLibraryPhotoAdjustmentModal(
            this.props.photo.id,
            this.props.ceremonyId
        );
    };

    render() {
        return (
            <LibraryImageCropModalView
                crop={this.state.crop}
                hideModal={this.props.hideModal}
                isSubmitting={this.state.isSubmitting}
                isVisible={this.props.isVisible}
                photoInternalId={this.props.photo.id}
                onCropChange={this.onCropChange}
                onImageLoaded={this.onImageLoaded}
                updateImageSettings={this.updateImageSettings}
                onUpdateCurrentImage={this.onUpdateCurrentImage}
                onCreateNewImage={this.onCreateNewImage}
                onReturnToImageAdjustmentModal={
                    this.onReturnToImageAdjustmentModal
                }
                isImageUsedInBlock={this.isImageUsedInBlock(
                    this.props.photo.id
                )}
                src={this.state.src}
            />
        );
    }
}

export default withRouter(LibraryImageCropModalContainer);
