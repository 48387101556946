import React from "react";

import TableItem from "../TableItem";

const CeremonyTable = ({ ceremonyIds, onSelect }) => {
    return (
        <div>
            <table className="table is-hoverable is-fullwidth">
                <tbody>
                    {ceremonyIds.map(id => (
                        <TableItem
                            key={id}
                            ceremonyId={id}
                            onSelect={onSelect}
                        ></TableItem>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CeremonyTable;
