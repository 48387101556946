import React from "react";
import { Link } from "react-router-dom";

import styles from "./Login.module.scss";
import routeNames from "../../constants/routeNames";

import LoginForm from "../../components/LoginForm";

const LoginRoute = props => {
    return (
        <>
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h1 className={`has-text-centered ${styles.title}`}>
                            Inloggen
                        </h1>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-3">
                        <LoginForm {...props}></LoginForm>
                        <Link
                            className={`link ${styles.link} has-text-centered`}
                            to={routeNames.forgotPassword}
                        >
                            Wachtwoord vergeten?
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginRoute;
