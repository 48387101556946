import T from "prop-types";

const ShowIfView = ({ condition, children }) => {
    if (condition) {
        return children;
    } else {
        return null;
    }
};

ShowIfView.propTypes = {
    condition: T.any,
    children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired
};

export default ShowIfView;
