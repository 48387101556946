import React, { Component } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Formik } from "formik";
import { normalize } from "normalizr";
import { withRouter } from "react-router-dom";
import { get, omit } from "micro-dash";
import * as Sentry from "@sentry/browser";

import i18n from "../../../../constants/i18n";
import routeNames from "../../../../constants/routeNames";

import createApiService from "../../../../network";
import ValidationError from "../../../../helpers/ValidationError";
import { processOrganizationFormData } from "../../../../helpers/organizationTransformer";
import { organization as organizationSchema } from "../../../../schemas";

import OrganizationModalView from "./OrganizationModalView";
import {
    extractApiErrorMessage,
    putNormalizedEntities
} from "../../../../helpers";

class OrganizationModalContainer extends Component {
    initialValues = {
        name: "",
        address: "",
        zipcode: "",
        city: "",
        email: "",
        phoneNumber: "",
        webcastApiExternalId: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            searchQuery: ""
        };

        this.api = createApiService(axios);

        if (props.organization) {
            this.initialValues = props.organization;
        }
    }

    baseValidationSchema = Yup.object().shape({
        name: Yup.string().required(i18n.validation.required),
        address: Yup.string().required(i18n.validation.required),
        zipcode: Yup.mixed().required(i18n.validation.required),
        city: Yup.string().required(i18n.validation.required),
        email: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required),
        phoneNumber: Yup.mixed().required(i18n.validation.required)
    });

    async componentDidMount() {
        if (this.getIsEditing()) {
            this.setState({
                isFetching: false
            });
        }
    }

    getIsEditing = () => {
        return Boolean(this.props.organization);
    };

    getValidationSchema = () => {
        return this.baseValidationSchema;
    };

    onSubmit = async (values, formikBag) => {
        if (this.getIsEditing()) {
            this.editOrganization(values, formikBag);
        } else {
            this.addOrganization(values, formikBag);
        }
    };

    addOrganization = async (values, formikBag) => {
        const processedValues = processOrganizationFormData(false, values);

        try {
            const {
                data: { data: createdOrganization }
            } = await this.api.postOrganization({
                ...processedValues,
                active: 1
            });

            formikBag.setSubmitting(false);
            this.props.showToast({
                body: i18n.generic.createOrganizationSuccess,
                title: "Success",
                themeClass: "is-success"
            });
            this.props.hideModal();

            // Force redux re-load by changing url like: CeremonyModalContainer.js addCeremony() /reload.
            this.props.history.push(`${routeNames.organizations}/page/1`);
            this.props.history.push(`${routeNames.organizations}`);
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    editOrganization = async (values, formikBag) => {
        const processedValues = processOrganizationFormData(
            true,
            values,
            this.props.organization
        );

        try {
            const {
                data: { data: updatedOrganization }
            } = await this.api.putOrganization(
                processedValues.id,
                processedValues
            );

            formikBag.setSubmitting(false);
            const { entities } = normalize(
                updatedOrganization,
                organizationSchema
            );

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updateOrganizationSuccess,
                title: "Success",
                themeClass: "is-success"
            });
            this.props.onComplete && this.props.onComplete();
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    deleteOrganization = async id => {
        if (window.confirm(i18n.generic.deleteItemConfirmationPrompt)) {
            try {
                const response = await this.api.deleteOrganization(id);
                this.props.hideModal();
                this.props.showToast({
                    body: get(response, ["data", "message"], ""),
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.onOrganizationDeleted(id);
            } catch (e) {
                Sentry.captureException(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.getValidationSchema()}
                render={props => (
                    <OrganizationModalView
                        deleteOrganization={this.deleteOrganization}
                        hideModal={this.props.hideModal}
                        isVisible={true}
                        isFetching={this.state.isFetching}
                        isEditing={this.getIsEditing()}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(OrganizationModalContainer);
