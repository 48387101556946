import React from "react";

import styles from "./StatusIndicator.module.scss";

const StatusIndicatorView = props => {
    let className;

    if (props.isLocked && props.isActive) {
        className = styles.isLocked;
    }

    if (props.isLocked && !props.isActive) {
        className = styles.isInactive;
    }

    if (!props.isLocked && !props.isActive) {
        className = styles.isInactive;
    }

    if (!props.isLocked && props.isActive) {
        className = styles.isActive;
    }

    return <div className={`${styles.indicator} ${className}`}></div>;
};

export default StatusIndicatorView;
