import { DELETE_DEVICE, PUT_DEVICES } from "../constants/actionTypes";

export function deleteDevice(payload) {
    return {
        type: DELETE_DEVICE,
        payload
    };
}

export function putDevices(devices) {
    return {
        type: PUT_DEVICES,
        payload: devices
    };
}
