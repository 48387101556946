import React from "react";
import T from "prop-types";

import styles from "./FieldArrayAddButton.module.scss";

const FieldArrayAddButtonView = ({ onClick }) => {
    return (
        <div className={styles.container}>
            <button
                onClick={onClick}
                className={`${styles.button} button is-secondary is-small`}
                type="button"
            >
                <span className="icon is-small">
                    <i className="fas fa-plus"></i>
                </span>
            </button>
        </div>
    );
};

FieldArrayAddButtonView.propTypes = {
    onClick: T.func.isRequired
};

export default FieldArrayAddButtonView;
