/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import { PUT_VIDEOS } from "../../constants/actionTypes";

const videosById = produce((draft, action) => {
    switch (action.type) {
        case PUT_VIDEOS:
            const videos = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            videos.forEach(video => {
                draft[video.id] = video;
            });
            break;
    }
}, {});

const allVideos = produce((draft, action) => {
    switch (action.type) {
        case PUT_VIDEOS:
            const videos = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            videos.forEach(video => {
                const isAlreadyPresent = draft.includes(video.id);
                if (!isAlreadyPresent) {
                    draft.push(video.id);
                }
            });
            break;
    }
}, []);

export default combineReducers({
    byId: videosById,
    allIds: allVideos
});
