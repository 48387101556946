const modalTypes = {
    /* PLOP_INJECT_MODAL_TYPE */
    BLOCK_PHOTO_ADJUSTMENT: "BLOCK_PHOTO_ADJUSTMENT",
    BLOCK_IMAGE_CROP: "BLOCK_IMAGE_CROP",
    BLOCK_IMAGE_ROTATE: "BLOCK_IMAGE_ROTATE",
    LIBRARY_PHOTO_ADJUSTMENT: "LIBRARY_PHOTO_ADJUSTMENT",
    LIBRARY_IMAGE_CROP: "LIBRARY_IMAGE_CROP",
    LIBRARY_IMAGE_ROTATE: "LIBRARY_IMAGE_ROTATE",
    UNLOCK_CEREMONY_MODAL: "UNLOCK_CEREMONY_MODAL",
    ADMIN_MANAGEMENT: "ADMIN_MANAGEMENT",
    ORGANIZATION: "ORGANIZATION",
    CEREMONY_CREATE_SUCCESS: "CEREMONY_CREATE_SUCCESS",
    BLOCK_PREVIEW: "BLOCK_PREVIEW",
    BLOCK_PROCESSING: "BLOCK_PROCESSING",
    DURATION_WARNING: "DURATION_WARNING",
    BLOCK_AMOUNT_WARNING: "BLOCK_AMOUNT_WARNING",
    ORGANIZATION_USER_MANAGEMENT: "ORGANIZATION_USER_MANAGEMENT",
    DEACTIVATED_CREMATORY: "DEACTIVATED_CREMATORY",
    LOCKED_TIMELINE: "LOCKED_TIMELINE",
    RELATIVES: "RELATIVES",
    BLOCK_EDIT: "BLOCK_EDIT",
    VIDEO_UPLOAD: "VIDEO_UPLOAD",
    MUSIC_UPLOAD: "MUSIC_UPLOAD",
    MAIN_CEREMONY_PHOTO: "MAIN_CEREMONY_PHOTO",
    SPEECH: "SPEECH",
    DEVICE: "DEVICE",
    ROOM: "ROOM",
    CREMATORY: "CREMATORY",
    CEREMONY: "CEREMONY",
    PHOTO_UPLOAD: "PHOTO_UPLOAD",
    BLOCK_PHOTO_UPLOAD: "BLOCK_PHOTO_UPLOAD",
    SCREEN_SIZE: "SCREEN_SIZE",
    BLOCK_TYPE: "BLOCK_TYPE",
    DELETE_WARNING: "DELETE_WARNING"
};

export default modalTypes;
