import React, { useEffect, useState } from "react";

import ShowIfView from "../../../ShowIf";

const ToastView = ({
    id,
    title,
    body,
    timeout,
    preventDismiss,
    onDismiss,
    themeClass
}) => {
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (!timeoutId) {
            setTimeoutId(
                setTimeout(() => {
                    onDismiss(id);
                }, timeout || 5000)
            );
        }

        return function cleanup() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [onDismiss, timeoutId, id]);

    return (
        <article className={`message is-toast ${themeClass}`}>
            <div className="message-header">
                <p>{title}</p>
                <ShowIfView condition={!preventDismiss}>
                    <button
                        onClick={() => onDismiss(id)}
                        className="delete"
                        aria-label="delete"
                    />
                </ShowIfView>
            </div>
            <div className="message-body">{body}</div>
        </article>
    );
};

export default ToastView;
