import React from "react";
import T from "prop-types";
import { ErrorMessage } from "formik";

import styles from "./AspectRatioField.module.scss";

const constants = {
    169: "16:9",
    1610: "16:10",
    43: "4:3"
};

const AspectRatioFieldView = ({ label, name, setFieldValue, value }) => {
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <div className={styles.container}>
                    <button
                        onClick={() => setFieldValue(name, constants[169])}
                        className={`${styles.item} ${
                            value === constants[169] ? styles.active : ""
                        }`}
                        type="button"
                    >
                        {constants[169]}
                    </button>
                    <button
                        onClick={() => setFieldValue(name, constants[1610])}
                        className={`${styles.item} ${
                            value === constants[1610] ? styles.active : ""
                        }`}
                        type="button"
                    >
                        {constants[1610]}
                    </button>
                    <button
                        onClick={() => setFieldValue(name, constants[43])}
                        className={`${styles.item} ${
                            value === constants[43] ? styles.active : ""
                        }`}
                        type="button"
                    >
                        {constants[43]}
                    </button>
                </div>
            </div>
            <ErrorMessage
                name={name}
                component="p"
                className="help is-danger is-size-6"
            />
        </div>
    );
};

AspectRatioFieldView.propTypes = {
    label: T.string,
    name: T.string.isRequired,
    setFieldValue: T.func.isRequired,
    value: T.string
};

export default AspectRatioFieldView;
