import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { putNormalizedEntities } from "../../helpers";

import PollCeremonyAssetsContainer from "./PollCeremonyAssetsContainer";

const mapStateToProps = (state, props) => {
    const {
        entities: { audioTracks, photos, videos }
    } = state;
    const ceremonyId = parseInt(props.match.params.ceremonyID);

    const audioTracksArray = Object.values(audioTracks.byId).filter(
        audioTrack => audioTrack.ceremonyId === ceremonyId
    );
    const photosArray = Object.values(photos.byId).filter(
        photo => photo.ceremonyId === ceremonyId
    );
    const videosArray = Object.values(videos.byId).filter(
        video => video.ceremonyId === ceremonyId
    );

    return {
        audioTracks: audioTracksArray,
        photos: photosArray,
        videos: videosArray
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PollCeremonyAssetsContainer)
);
