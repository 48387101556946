import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";

import DeleteWarningModalContainer from "./DeleteWarningModalContainer";
import { showToast } from "../../../../actions/toasts";
import { deleteCeremony } from "../../../../actions/ceremonies";

const mapStateToProps = (state, props) => {
    return {
        isVisible: true
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        hideModal: () => dispatch(hideModal()),
        showToast: options => {
            dispatch(showToast(options));
        },
        onCeremonyDeleted: id => {
            dispatch(deleteCeremony(id));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteWarningModalContainer);
