import { PUT_PHOTOS, DELETE_PHOTO } from "../constants/actionTypes";

export function putPhotos(photos) {
    return {
        type: PUT_PHOTOS,
        payload: photos
    };
}

export function deletePhoto(photoId) {
    return {
        type: DELETE_PHOTO,
        payload: photoId
    };
}
