import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import { round } from "micro-dash";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import {
    photo as photoSchema,
    block as blockSchema
} from "../../../../schemas";
import { extractApiErrorMessage } from "../../../../helpers";

import BlockImageCropModalView from "./BlockImageCropModalView";

class BlockImageCropModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            crop: {
                width: 320,
                x: 160,
                y: 80,
                aspect: props.aspectRatio
            },
            isSubmitting: false
        };
    }

    onCropChange = crop => {
        this.setState({ crop });
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onSubmit = async () => {
        try {
            this.setState({ isSubmitting: true });

            const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
            const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
            const targetX = this.state.crop.x * scaleX;
            const targetY = this.state.crop.y * scaleY;
            const targetWidth = this.state.crop.width * scaleX;
            const targetHeight = this.state.crop.height * scaleY;

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("width", round(targetWidth));
            formData.append("height", round(targetHeight));
            formData.append("x", round(targetX));
            formData.append("y", round(targetY));

            const {
                data: { data: croppedPhoto }
            } = await this.api.postPhotoAndCrop(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            const existingPhotoIndex = this.props.block.imageIds.findIndex(
                item => item === this.props.photo.id
            );
            const imageIds = [...this.props.block.imageIds];
            imageIds[existingPhotoIndex] = croppedPhoto.id;

            const {
                data: { data: updatedBlock }
            } = await this.api.putBlock(this.props.ceremony.id, {
                ...this.props.block,
                imageIds
            });

            const { entities: blockEntities } = normalize(
                updatedBlock,
                blockSchema
            );
            this.props.updateStoreEntities(blockEntities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.openBlockEditModal(
                this.props.ceremony.id,
                this.props.block.id
            );
            this.props.showToast({
                body: i18n.generic.updatedBlockSuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onReturnToImageAdjustmentModal = () => {
        this.props.hideModal();
        this.props.showPhotoAdjustmentModal(
            this.props.blockId,
            this.props.photo.id,
            this.props.ceremonyId
        );
    };

    render() {
        return (
            <BlockImageCropModalView
                crop={this.state.crop}
                hideModal={this.props.hideModal}
                isSubmitting={this.state.isSubmitting}
                isVisible={this.props.isVisible}
                photoInternalId={this.props.photo.id}
                onCropChange={this.onCropChange}
                onImageLoaded={this.onImageLoaded}
                onSubmit={this.onSubmit}
                onReturnToImageAdjustmentModal={
                    this.onReturnToImageAdjustmentModal
                }
                src={this.state.src}
            />
        );
    }
}

export default withRouter(BlockImageCropModalContainer);
