import { connect } from "react-redux";

import { fetchTotalDataUsageIfNeeded } from "../../actions/totalDataUsage";
import TotalDataUsageContainer from "./TotalDataUsageContainer";

const mapStateToProps = state => {
    return {
        totalDataUsage: state.totalDataUsage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDataIfNeeded: () => {
            dispatch(fetchTotalDataUsageIfNeeded());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalDataUsageContainer);
