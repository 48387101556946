import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import EndUserCeremonyOverviewContainer from "./EndUserCeremonyOverviewContainer";

const mapStateToProps = (state, props) => {
    const ceremonies = Object.values(state.entities.ceremonies.byId);

    return {
        ceremonies
    };
};

export default withRouter(
    connect(mapStateToProps)(EndUserCeremonyOverviewContainer)
);
