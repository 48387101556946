import React from "react";

import i18n from "../../constants/i18n";
import styles from "./ExternalCeremonyOverview.module.scss";

import Pagination from "../Pagination";
import ShowIf from "../ShowIf";
import Table from "./Table";

const ExternalCeremonyOverviewView = ({
    error,
    ceremonyIds,
    isFetching,
    meta,
    onPaginationSelect,
    onSelect,
    onBackToOverviewButtonClick
}) => {
    return (
        <div className="container">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <div className={` ${styles.backButton}`}>
                            <div onClick={onBackToOverviewButtonClick}>
                                <span className="icon is-small">
                                    <i className="fas fa-chevron-left" />
                                </span>
                                <span>{i18n.generic.backToOverview}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isFetching ? (
                <div className={`is-relative ${styles.loaderContainer}`}>
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <Table
                        ceremonyIds={ceremonyIds}
                        onSelect={onSelect}
                    ></Table>
                    <ShowIf
                        condition={isFetching === false && !ceremonyIds.length}
                    >
                        <div className={`${styles.warningContainer}`}>
                            <span className="icon has-text-warning is-medium">
                                <i className="fas fa-2x fa-exclamation-circle"></i>
                            </span>
                            <h3 className="has-centered-text">
                                {i18n.generic.noResultsFound}
                            </h3>
                        </div>
                    </ShowIf>
                    <ShowIf
                        condition={isFetching === false && ceremonyIds.length}
                    >
                        <Pagination
                            meta={meta}
                            onSelect={onPaginationSelect}
                        ></Pagination>
                    </ShowIf>
                </>
            )}
            <ShowIf condition={error}>
                <div className={`${styles.warningContainer}`}>
                    <span className="icon has-text-warning is-medium">
                        <i className="fas fa-2x fa-exclamation-circle"></i>
                    </span>
                    <h3 className="has-centered-text">{i18n.generic.error}</h3>
                </div>
            </ShowIf>
        </div>
    );
};

export default ExternalCeremonyOverviewView;
