import { DELETE_ROOM, PUT_ROOMS } from "../constants/actionTypes";

export function deleteRoom(payload) {
    return {
        type: DELETE_ROOM,
        payload
    };
}

export function putRooms(rooms) {
    return {
        type: PUT_ROOMS,
        payload: rooms
    };
}
