import React from "react";

import styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({ onClick, label }) => (
    <div onClick={onClick} className={styles.container}>
        <span className="icon is-small">
            <i className="fas fa-chevron-left"></i>
        </span>
        <span>{label}</span>
    </div>
);

export default Breadcrumb;
