/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";
import { get } from "micro-dash";

import {
    INVALIDATE_ORGANIZATION,
    FETCH_ORGANIZATION_REQUEST,
    FETCH_ORGANIZATION_SUCCESS,
    FETCH_ORGANIZATION_FAILURE,
    PUT_ORGANIZATIONS,
    DELETE_ORGANIZATION
} from "../../constants/actionTypes";

const organizationsById = produce((draft, action) => {
    const organizationID = get(action, ["payload", "id"], action.payload);
    const organization = draft[organizationID];

    switch (action.type) {
        case INVALIDATE_ORGANIZATION: {
            if (organization) {
                organization.didInvalidate = true;
            }
            break;
        }

        case FETCH_ORGANIZATION_REQUEST: {
            if (organization) {
                organization.isFetching = true;
            } else {
                draft[organizationID] = {
                    error: null,
                    isFetching: true,
                    didInvalidate: false,
                    lastUpdated: Date.now(),
                    item: null
                };
            }
            break;
        }

        case FETCH_ORGANIZATION_SUCCESS:
        case PUT_ORGANIZATIONS: {
            const organization = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            organization.forEach(organization => {
                const existingOrganization = draft[organization.id];

                if (existingOrganization) {
                    existingOrganization.error = null;
                    existingOrganization.isFetching = false;
                    existingOrganization.didInvalidate = false;
                    existingOrganization.lastUpdated = Date.now();
                    existingOrganization.item = organization;
                } else {
                    draft[organization.id] = {
                        error: null,
                        isFetching: false,
                        didInvalidate: false,
                        lastUpdated: Date.now(),
                        item: organization
                    };
                }
            });
            break;
        }

        case FETCH_ORGANIZATION_FAILURE: {
            if (organization) {
                organization.isFetching = false;
                organization.error = action.error;
                organization.lastUpdated = Date.now();
            }
            break;
        }

        case DELETE_ORGANIZATION:
            {
                delete draft[action.payload];
            }

            return;
    }
}, {});

const allOrganizations = produce((draft, action) => {
    switch (action.type) {
        case FETCH_ORGANIZATION_SUCCESS:
        case PUT_ORGANIZATIONS: {
            const organization = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            organization.forEach(organization => {
                const id = organization.id;
                if (!draft.find(item => item === id)) {
                    draft.push(organization.id);
                }
            });

            break;
        }

        case DELETE_ORGANIZATION: {
            draft.splice(draft.findIndex(id => id === action.payload), 1);
        }
    }
}, []);

export default combineReducers({
    byId: organizationsById,
    allIds: allOrganizations
});
