import React from "react";

import styles from "./TableItem.module.scss";
import i18n from "../../../constants/i18n";
import Tippy from "@tippy.js/react";

const TableItemView = ({ organization, onEdit, onShowOrganization }) => {
    if (!organization) {
        return null;
    }

    return (
        <tr>
            <td>
                <h2>{organization.item.name}</h2>
            </td>
            <td>
                {organization.item.address}
                <br />
                {organization.item.zipcode}
                <br />
                {organization.item.city}
            </td>
            <td>
                <div className={styles.alignCenter}>
                    <span className={`icon ${styles.icon}`}>
                        <i className="fas fa-lg fa-envelope"></i>
                    </span>
                    <span>{organization.item.email}</span>
                </div>
            </td>
            <td>
                <span className={`icon ${styles.icon}`}>
                    <i className="fas fa-lg fa-phone"></i>
                </span>
                <span>{organization.item.phoneNumber}</span>
            </td>
            <td>
                <button
                    onClick={() => onShowOrganization(organization.item.id)}
                    className="button is-rounded"
                >
                    {i18n.organizationOverview.showOrganization}
                </button>
            </td>
            <td>
                <div className="level">
                    <div className="level-item">
                        <Tippy content={i18n.generic.edit}>
                            <span
                                onClick={() => onEdit(organization.item.id)}
                                className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                            >
                                <i className="fas fa-lg fa-pen"></i>
                            </span>
                        </Tippy>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default TableItemView;
