import { isBoolean, get, omit, isString, cloneDeep, merge } from "micro-dash";
import { decamelizeKeys } from "humps";

export function convertBooleanToNumber(boolean) {
    return +boolean;
}

export function convertRoomsToObjectProperties(rooms) {
    return rooms
        .map(room => Object.entries(room))
        .reduce((acc, entries, index) => {
            entries.forEach(entry => {
                let value = entry[1];
                if (isBoolean(value)) {
                    value = convertBooleanToNumber(value);
                }
                acc[`rooms[${index}][${entry[0]}]`] = value;
            });
            return acc;
        }, {});
}

export function processLogoField(isEditing, values) {
    if (isEditing) {
        if (!values.logo) {
            values.logo = null;
        }

        if (isString(values.logo)) {
            return omit(values, "logo");
        }

        return values;
    } else {
        if (!values.logo) {
            return omit(values, "logo");
        }

        return values;
    }
}

export function processCrematoryFormData(
    isEditing,
    incomingValues,
    existingCrematory
) {
    let values = cloneDeep(incomingValues);

    values = merge(values, convertRoomsToObjectProperties(values.rooms));
    values = omit(values, "rooms");

    if (!isEditing) {
        values.organizationId = values.organization;
        delete values.organization;
    }

    // decamelize() will convert instances of File class to empty object
    // Explicitly revert correct value to this field
    values = decamelizeKeys(values);
    values.logo = incomingValues.logo;
    values = processLogoField(isEditing, values);

    if (isEditing) {
        const existingAdminEmail = get(existingCrematory, [
            "administrators",
            "0",
            "email"
        ]);
        const existingAdminName = get(existingCrematory, [
            "administrators",
            "0",
            "name"
        ]);

        if (existingAdminEmail === values.crematoriumAdminEmail) {
            values = omit(values, "crematoriumAdminEmail");
        }

        if (existingAdminName === values.crematoriumAdminName) {
            values = omit(values, "crematoriumAdminName");
        }
    }

    return values;
}
