import React from "react";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import App from "./App";
import configureStore from "./configureStore";
import configureNetwork from "./configureNetwork";

if (process.env.NODE_ENV === "production") {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

const transportLayer = configureNetwork();
const store = configureStore(undefined, transportLayer);

const renderApp = () =>
    render(
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>,
        document.getElementById("root")
    );

if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./App", renderApp);
}

renderApp();
