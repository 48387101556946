import React from "react";
import T from "prop-types";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
    handleBlur,
    disabled,
    label,
    name,
    setFieldValue,
    value,
    reversed,
    errors,
    touched
}) => (
    <label
        className={`checkbox ${styles.label} ${
            reversed ? styles.reverse : ""
        } ${errors[name] && touched[name] ? "is-danger" : ""}`}
        disabled={disabled}
    >
        {label}
        <input
            type="checkbox"
            onChange={event =>
                setFieldValue(name, Number(event.target.checked) || 0)
            }
            onBlur={handleBlur}
            name={name}
            checked={value}
            className={`${styles.input} ${
                errors[name] && touched[name] ? "is-danger" : ""
            }`}
            disabled={disabled}
        />{" "}
    </label>
);

Checkbox.propTypes = {
    handleBlur: T.func.isRequired,
    inline: T.bool,
    label: T.string,
    name: T.string.isRequired,
    reversed: T.bool,
    setFieldValue: T.func.isRequired,
    value: T.any.isRequired
};

export default Checkbox;
