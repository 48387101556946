import React from "react";
import { DragDropContext } from "react-beautiful-dnd";

const DragDropRootView = ({
    children,
    onBeforeCapture,
    onDragStart,
    onDragEnd
}) => {
    return (
        <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            {children}
        </DragDropContext>
    );
};

export default DragDropRootView;
