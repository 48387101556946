import React, { Component } from "react";
import axios from "axios";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { normalize } from "normalizr";
import { get, isUndefined, sortBy } from "micro-dash";

import i18n from "../../../../constants/i18n";
import config from "../../../../constants/config";
import blockTypes from "../../../../constants/blockTypes";
import createApiService from "../../../../network";
import {
    video as videoSchema,
    block as blockSchema
} from "../../../../schemas";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import { insertBlockAtIndex } from "../../../../helpers/blockHelpers";
import handleUploadProgress from "../../../../helpers/handleUploadProgress";

import VideoUploadModalView from "./VideoUploadModalView";

const supportedFormats = ["video/*"];

class VideoUploadModalContainer extends Component {
    initialValues = {
        video: null
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (props.isEditing) {
            this.initialValues.video = props.block.video;
        }
    }

    baseValidationSchema = Yup.object().shape({
        video: Yup.mixed()
            .required(i18n.validation.required)
            .test("fileType", i18n.validation.fileType, value => {
                if (!value) return false;

                return value.type.startsWith("video/");
            })
            .test(
                "maxUploadFileSize",
                i18n.validation.maxUploadFileSize,
                value => {
                    if (!value) return false;

                    return value.size <= config.oc.maxUploadFileSize;
                }
            )
    });

    mapValidationErrors = (e, formikBag) => {
        const fileError = get(e, ["errors", "files.0", "0"]);
        if (fileError) {
            formikBag.setFieldError("video", fileError);
        } else {
            formikBag.setErrors(e.errors);
        }
    };

    onSubmit = (values, formikBag) => {
        formikBag.setStatus({ progress: 0 });

        if (this.props.isWalkInBlock || this.props.isWalkOutBlock) {
            return this.onUpdateWalkInOrOutBlock(values, formikBag);
        }

        if (this.props.isEditing) {
            this.onEdit(values, formikBag);
        } else {
            this.onCreate(values, formikBag);
        }
    };

    onCreate = async (values, formikBag) => {
        const { ceremony } = this.props;

        try {
            const { data } = await this.api.postMedia(
                ceremony.item.id,
                { "files[0]": values.video },
                e => handleUploadProgress(e, formikBag.setStatus)
            );
            const video = data.data;
            const duration = data.duration[0];

            const { entities: videoEntities } = normalize(video, videoSchema);
            this.props.updateStoreEntities(videoEntities);

            const {
                data: { data: block }
            } = await this.api.postBlock(ceremony.item.id, {
                type: blockTypes.VIDEO,
                duration: duration,
                videoId: video.id,
                position: this.props.position
            });

            const blockIds = insertBlockAtIndex({
                array: this.props.blocks,
                item: block,
                index: this.props.position
            })
                .map((block, index) => ({ ...block, position: index }))
                .map(block => block.id);

            await this.refreshTimelineBlocks(blockIds);

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.createVideoBlockSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            console.error(e);
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onEdit = async (values, formikBag) => {
        const { ceremony } = this.props;

        try {
            const { data } = await this.api.postMedia(
                ceremony.item.id,
                { "files[0]": values.video },
                e => handleUploadProgress(e, formikBag.setStatus)
            );
            const video = data.data;
            const duration = data.duration[0];

            const { entities: videoEntities } = normalize(video, videoSchema);
            this.props.updateStoreEntities(videoEntities);

            const {
                data: { data: block }
            } = await this.api.putBlock(ceremony.item.id, {
                ...this.props.block,
                videoId: video.id,
                duration: duration
            });

            const { entities } = normalize(block, blockSchema);
            this.props.updateStoreEntities(entities);

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.updatedBlockSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onUpdateWalkInOrOutBlock = async (values, formikBag) => {
        const { ceremony } = this.props;
        const initialBlock = this.props.block;

        try {
            const position = this.props.isWalkInBlock
                ? 0
                : this.props.blocks.length - 1;

            const { data } = await this.api.postMedia(
                ceremony.item.id,
                { "files[0]": values.video },
                e => handleUploadProgress(e, formikBag.setStatus)
            );
            const video = data.data;
            const duration = data.duration[0];

            const { entities: videoEntities } = normalize(video, videoSchema);
            this.props.updateStoreEntities(videoEntities);

            const {
                data: { data: block }
            } = await this.api.putBlock(ceremony.item.id, {
                ...this.props.block,
                type: blockTypes.VIDEO,
                duration: duration,
                audioId: null,
                videoId: video.id,
                imageIds: [],
                position
            });

            await this.refreshTimelineBlocks();

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.addVideoFileSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    /**
     * Reresh timeline blocks.
     *
     * @TODO Refactor this at a later moment.
     * This a copy-paste from TimelineOverviewContainer.js
     * Find a way to call that method, using ref, dispatch or something similar.
     * Passing this method using props is not feasible, because this component is used in a lot of places.
     *
     * Everytime that a block is: added, removed or sorted.
     * Refresh the blocks to keep the data in sync (otherwise the user may see outdated data
     * when using different browser tabs).
     *
     * @param blockIds|null       List of current or preferred order blockIds. If no ID's are given, the positions are server-side regenerated.
     * @returns {Promise<void>}
     */
    refreshTimelineBlocks = async blockIds => {
        // Get block ID's from browser.
        if (isUndefined(blockIds)) {
            blockIds = sortBy(this.props.blocks, block => block.position).map(
                block => block.id
            );
        }

        // Submit the new positions of the blocks.
        const {
            data: { data: blocks }
        } = await this.api.reorderBlocks(this.props.ceremony.item.id, blockIds);

        // Check if the submitted block positions matches the blocks from the server.
        let hasDeletedBlocks = false;
        blockIds.forEach(blockId => {
            let block = blocks.find(block => block.id === blockId);
            if (isUndefined(block)) {
                this.props.onBlockDeleted(blockId);
                hasDeletedBlocks = true;
            }
        });

        // If the positions doesn't match, inform the user that the browser data is outdated since last update.
        if (hasDeletedBlocks || blockIds.length !== blocks.length) {
            this.props.showToast({
                body: i18n.timelineOverview.blocksModifiedSinceLastUpdate,
                title: "Warning",
                themeClass: "is-warning"
            });
        }

        // Store the data.
        const { entities } = normalize(blocks, [blockSchema]);
        this.props.updateStoreEntities(entities);
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.baseValidationSchema}
                render={props => (
                    <VideoUploadModalView
                        hideModal={this.props.hideModal}
                        isEditing={this.props.isEditing}
                        isVisible={this.props.isVisible}
                        supportedFormats={supportedFormats}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default withRouter(VideoUploadModalContainer);
