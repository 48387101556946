import { connect } from "react-redux";
import { get } from "micro-dash";

import { hideModal, showModal } from "../../../../actions/modals";
import roleTypes from "../../../../constants/roles";

import LockedTimelineModalContainer from "./LockedTimelineModalContainer";
import modalTypes from "../../../../constants/modalTypes";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const crematoryId = parseInt(props.crematoryId);

    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);
    const isEndUser = state.user.profile.roles.includes(roleTypes.endUser);
    const isCrematoryAdmin = state.user.profile.roles.includes(
        roleTypes.crematory
    );

    return {
        crematoryId,
        ceremonyMaster: get(
            state,
            [
                "entities",
                "ceremonies",
                "byId",
                ceremonyId,
                "item",
                "ceremonyMaster"
            ],
            {}
        ),
        isAdmin,
        isCrematoryAdmin,
        isEndUser,
        isVisible: true
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const ceremonyId = parseInt(props.ceremonyId);

    return {
        hideModal: () => dispatch(hideModal()),
        showBlockPreviewModal: blockId => {
            dispatch(
                showModal({
                    type: modalTypes.BLOCK_PREVIEW,
                    props: {
                        ceremonyId,
                        blockId
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LockedTimelineModalContainer);
