import React from "react";
import { Form } from "formik";

import Input from "../Input";

import styles from "./ResetPasswordForm.module.scss";
import Checkbox from "../Checkbox";
import i18n from "../../constants/i18n";

const ResetPasswordFormView = ({
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    values,
    updatePassword
}) => {
    return (
        <Form>
            {updatePassword ? (
                <Input
                    errors={errors}
                    touched={touched}
                    name="current"
                    label="Huidig wachtwoord"
                    placeholder="Huidig wachtwoord"
                    type="password"
                />
            ) : (
                <Input
                    errors={errors}
                    touched={touched}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                />
            )}

            <Input
                errors={errors}
                touched={touched}
                name="password"
                label="Nieuw Wachtwoord"
                placeholder="Nieuw Wachtwoord"
                type="password"
            />
            <Input
                errors={errors}
                touched={touched}
                name="passwordConfirmation"
                label="Herhaal wachtwoord"
                placeholder="Herhaal wachtwoord"
                type="password"
            />

            <div className="columns is-centered">
                <div className={`column is-narrow`}>
                    <a
                        href="/Algemene-Voorwaarden-Online-Ceremonie-website.pdf"
                        target="_blank"
                    >
                        Klik hier voor de algemene voorwaarden
                    </a>
                </div>
            </div>

            <div className="columns is-centered">
                <div
                    className={`column is-narrow ${styles.submitBtnContainer}`}
                >
                    <Checkbox
                        errors={errors}
                        touched={touched}
                        name="termsAndConditions"
                        value={values.termsAndConditions}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        label={i18n.loginForm.termsAndConditionsAgreement}
                        reversed
                        required="required"
                    />
                </div>
            </div>

            <div className="columns is-centered">
                <div
                    className={`column is-narrow ${styles.submitBtnContainer}`}
                >
                    <button
                        type="submit"
                        className={`button is-primary is-rounded ${
                            styles.submitBtn
                        } ${isSubmitting ? "is-loading" : ""}`}
                        disabled={isSubmitting}
                    >
                        resetten
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default ResetPasswordFormView;
