import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import i18n from "../../constants/i18n";
import styles from "./ForgotPasswordForm.module.scss";

const ForgotPasswordFormView = props => (
    <Formik
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
        render={({ errors, isSubmitting, status, touched }) => (
            <Form>
                <div className={`field ${styles.field}`}>
                    <label className="label has-text-centered has-text-primary">
                        {i18n.forgotPasswordForm.emailLabel}
                    </label>
                    <div className="control has-icons-left has-icons-right">
                        <Field
                            className={`input is-rounded ${
                                errors.email && touched.email ? "is-danger" : ""
                            }`}
                            type="email"
                            name="email"
                        />
                        {errors.email && touched.email ? (
                            <span className="icon is-right has-text-danger">
                                <i className="fas fa-exclamation-circle "></i>
                            </span>
                        ) : null}
                    </div>
                    <ErrorMessage
                        name="email"
                        component="p"
                        className="help is-danger is-size-6"
                    />
                </div>

                <div className="columns is-centered">
                    <div
                        className={`column is-narrow ${styles.submitBtnContainer}`}
                    >
                        {status && status.succeeded ? (
                            <button
                                type="button"
                                className={`button is-primary is-rounded ${styles.submitBtn}`}
                            >
                                <span className="icon is-small">
                                    <i className="fas fa-check"></i>
                                </span>
                                <span>{i18n.forgotPasswordForm.sent}</span>
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className={`button is-primary is-rounded ${
                                    styles.submitBtn
                                } ${isSubmitting ? "is-loading" : ""}`}
                                disabled={isSubmitting}
                            >
                                {i18n.forgotPasswordForm.reset}
                            </button>
                        )}
                    </div>
                </div>
            </Form>
        )}
    />
);

export default ForgotPasswordFormView;
