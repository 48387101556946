import React from "react";
import T from "prop-types";

import styles from "./List.module.scss";

import AudioPlayer from "./Player";

const List = ({
    onSelect,
    playbackActive,
    playbackDuration,
    playbackTime,
    playbackTrackID,
    selectedTrackID,
    tracks,
    ...rest
}) => {
    return (
        <div className={`${styles.container}`}>
            {tracks.map(track => (
                <AudioPlayer
                    key={track.trackToken}
                    currentTime={playbackTime}
                    duration={playbackDuration}
                    onSelect={onSelect}
                    playing={track.trackToken === playbackTrackID}
                    selected={track.trackToken === selectedTrackID}
                    track={track}
                    {...rest}
                />
            ))}
        </div>
    );
};

List.defaultProps = {
    onSelect: () => {},
    tracks: []
};

List.propTypes = {
    onSelect: T.func.isRequired,
    playbackActive: T.bool,
    playbackDuration: T.number,
    playbackTime: T.number,
    playbackTrackID: T.string,
    selectedTrackID: T.string,
    setPlaybackTime: T.func.isRequired,
    togglePlayback: T.func.isRequired,
    tracks: T.arrayOf(
        T.shape({
            artist: T.string,
            title: T.string.isRequired,
            trackToken: T.string
        }).isRequired
    )
};

export default List;
