import { connect } from "react-redux";
import { get } from "micro-dash";

import roleTypes from "../../../../constants/roles";
import { logout } from "../../../../actions/auth";
import { hideModal } from "../../../../actions/modals";

import DeactivatedCrematoryModalContainer from "./DeactivatedCrematoryModalContainer";

const mapStateToProps = (state, props) => {
    const isCrematoryAdmin = get(
        state,
        ["user", "profile", "roles"],
        []
    ).includes(roleTypes.crematory);
    const isCrematoryInactive = get(
        Object.values(state.entities.crematories.byId),
        [0, "item", "active"]
    );

    return {
        isVisible:
            props.force || (isCrematoryAdmin && isCrematoryInactive === 0)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        logout: () => dispatch(logout())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeactivatedCrematoryModalContainer);
