import { connect } from "react-redux";
import { get, pick, omit } from "micro-dash";

import OrganizationModalContainer from "./OrganizationModalContainer";
import { putNormalizedEntities } from "../../../../helpers";
import { deleteOrganization } from "../../../../actions/organizations";
import { hideModal } from "../../../../actions/modals";
import { getOrganizationItemById } from "../../../../selectors/organizations";
import { showToast } from "../../../../actions/toasts";

const allowedFields = [
    "id",
    "name",
    "address",
    "zipcode",
    "city",
    "email",
    "phoneNumber",
    "webcastApiExternalId"
];

const mapStateToProps = (state, props) => {
    const isEditing = Boolean(props.organizationId);
    let organizationItem = null;

    if (isEditing) {
        organizationItem = getOrganizationItemById(state, props);
        organizationItem = pick.apply(this, [
            organizationItem,
            ...allowedFields
        ]);
        organizationItem = omit(
            {
                ...organizationItem
            },
            "administrators"
        );
    }

    return {
        organization: organizationItem
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        },
        showToast: options => {
            dispatch(showToast(options));
        },
        hideModal: () => {
            dispatch(hideModal());
        },
        onOrganizationDeleted: id => {
            dispatch(deleteOrganization(id));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationModalContainer);
