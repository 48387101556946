import React from "react";
import styles from "../Select/Select.module.scss";
import { ErrorMessage, useFormikContext } from "formik";
import ShowIfView from "../../../ShowIf";
import { FormNotice } from "../../../FormNotice/FormNotice";
import Select from "react-select";
import T from "prop-types";

const LocationSelect = ({
    label,
    name,
    notice,
    options,
    isMulti,
    roleChanged
}) => {
    const {
        setFieldValue,
        touched,
        handleBlur,
        initialValues,
        errors,
        values
    } = useFormikContext();

    const customStyles = {
        control: provided => ({
            ...provided,
            borderRadius: "25px",
            height: "auto",
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "2.25em",
            paddingRight: "2.25em",
            fontSize: "15px",
            fontWeight: 300
        }),
        menu: styles => ({
            ...styles,
            zIndex: 9999
        })
    };

    return (
        <div className={`field ${styles.field}`}>
            <label className="label">{label}</label>
            <div className="control has-icons-right">
                <div
                    className={`select ${
                        errors[name] && touched[name] ? "is-danger" : ""
                    } ${styles.isBig}`}
                >
                    <Select
                        name={name}
                        styles={customStyles}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => <div></div>
                        }}
                        defaultValue={
                            !roleChanged && initialValues.locations
                                ? initialValues.locations
                                : null
                        }
                        value={values.locations}
                        isMulti={isMulti}
                        onBlur={handleBlur}
                        onChange={value => {
                            if (Array.isArray(value)) {
                                setFieldValue(name, value);
                            } else {
                                setFieldValue(name, [value]);
                            }
                        }}
                        options={options}
                    />
                </div>
                <ErrorMessage name={name}>
                    <span className="icon is-right has-text-danger">
                        <i className="fas fa-exclamation-circle " />
                    </span>
                </ErrorMessage>
            </div>
            <ErrorMessage
                name={name}
                component="p"
                className="help is-danger is-size-6"
            />
            <ShowIfView condition={notice}>
                <FormNotice>{notice}</FormNotice>
            </ShowIfView>
        </div>
    );
};

Select.propTypes = {
    options: T.arrayOf(
        T.shape({
            label: T.string.isRequired,
            value: T.string.isRequired
        })
    ).isRequired
};

export default LocationSelect;
