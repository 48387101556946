/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";
import { get } from "micro-dash";

import {
    INVALIDATE_CREMATORY,
    FETCH_CREMATORY_REQUEST,
    FETCH_CREMATORY_SUCCESS,
    FETCH_CREMATORY_FAILURE,
    PUT_CREMATORIES,
    DELETE_CREMATORY
} from "../../constants/actionTypes";

const crematoriesById = produce((draft, action) => {
    const crematoryID = get(action, ["payload", "id"], action.payload);
    const crematory = draft[crematoryID];

    switch (action.type) {
        case INVALIDATE_CREMATORY: {
            if (crematory) {
                crematory.didInvalidate = true;
            }
            break;
        }

        case FETCH_CREMATORY_REQUEST: {
            if (crematory) {
                crematory.isFetching = true;
            } else {
                draft[crematoryID] = {
                    error: null,
                    isFetching: true,
                    didInvalidate: false,
                    lastUpdated: Date.now(),
                    item: null
                };
            }
            break;
        }

        case FETCH_CREMATORY_SUCCESS:
        case PUT_CREMATORIES: {
            const crematories = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            crematories.forEach(crematory => {
                const existingCrematory = draft[crematory.id];

                if (existingCrematory) {
                    existingCrematory.error = null;
                    existingCrematory.isFetching = false;
                    existingCrematory.didInvalidate = false;
                    existingCrematory.lastUpdated = Date.now();
                    existingCrematory.item = crematory;
                } else {
                    draft[crematory.id] = {
                        error: null,
                        isFetching: false,
                        didInvalidate: false,
                        lastUpdated: Date.now(),
                        item: crematory
                    };
                }
            });
            break;
        }

        case FETCH_CREMATORY_FAILURE: {
            if (crematory) {
                crematory.isFetching = false;
                crematory.error = action.error;
                crematory.lastUpdated = Date.now();
            }
            break;
        }

        case DELETE_CREMATORY: {
            delete draft[action.payload];
        }
    }
}, {});

const allCrematories = produce((draft, action) => {
    switch (action.type) {
        case FETCH_CREMATORY_SUCCESS:
        case PUT_CREMATORIES: {
            const crematories = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            crematories.forEach(crematory => {
                const id = crematory.id;
                if (!draft.find(item => item === id)) {
                    draft.push(crematory.id);
                }
            });
            break;
        }

        case DELETE_CREMATORY: {
            draft.splice(draft.findIndex(id => id === action.payload), 1);
        }
    }
}, []);

export default combineReducers({
    byId: crematoriesById,
    allIds: allCrematories
});
