import React from "react";
import T from "prop-types";

import styles from "./Player.module.scss";

import formatMediaDuration from "../../../../../helpers/formatMediaDuration";

const AudioPlayer = ({
    className,
    currentTime,
    duration,
    onSelect,
    playing,
    selected,
    setPlaybackTime,
    togglePlayback,
    track
}) => {
    const onSeekbarClick = event => {
        event.persist();
        event.stopPropagation();

        const target = event.target.dataset.progressRoot
            ? event.target
            : event.target.closest("[data-progress-root]");
        const rect = target.getBoundingClientRect();
        const x = event.clientX - rect.left;

        setPlaybackTime(duration * (x / rect.width));
    };

    const icon = playing ? "fas fa-pause" : "fas fa-play";

    return (
        <div
            className={`${className} ${styles.container} ${
                selected ? styles.selected : ""
            }`}
            onClick={() => {
                if (!getSelection().toString()) {
                    onSelect(track);
                }
            }}
        >
            {selected ? (
                <div className={styles.selectedIcon}>
                    <span className="icon">
                        <i className="fas fa-check"></i>
                    </span>
                </div>
            ) : null}
            <div className={styles.action}>
                <button
                    className={`button is-secondary ${styles.actionButton}`}
                    onClick={event => {
                        event.stopPropagation();
                        togglePlayback(!playing, track);
                    }}
                    type="button"
                >
                    <span className="icon" key={icon}>
                        {playing ? (
                            <i className={icon}></i>
                        ) : (
                            <i className={icon}></i>
                        )}
                    </span>
                </button>
            </div>
            <div className={styles.main}>
                <div className={styles.meta}>
                    <div className={styles.name}>
                        <p className={styles.title}>{track.title}</p>
                        {!playing ? (
                            <p className={styles.subtitle}>{track.artist}</p>
                        ) : null}
                    </div>
                    {playing && duration ? (
                        <p className={styles.duration}>
                            {formatMediaDuration(currentTime * 1000)} /{" "}
                            {formatMediaDuration(duration * 1000)}
                        </p>
                    ) : null}
                </div>

                {playing && duration ? (
                    <div className={styles.progress}>
                        <div
                            className={`${styles.bar}`}
                            data-progress-root
                            onClick={onSeekbarClick}
                        >
                            <div
                                className={`${styles.innerBar}`}
                                style={{
                                    width: `${Math.round(
                                        (100 * currentTime) / duration
                                    )}%`
                                }}
                            ></div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

AudioPlayer.defaultProps = {
    className: ""
};

AudioPlayer.propTypes = {
    className: T.string,
    currentTime: T.number,
    duration: T.number,
    onSelect: T.func.isRequired,
    playing: T.bool.isRequired,
    selected: T.bool,
    setPlaybackTime: T.func.isRequired,
    togglePlayback: T.func.isRequired,
    track: T.shape({
        artist: T.string,
        title: T.string.isRequired,
        trackToken: T.string
    }).isRequired
};

export default AudioPlayer;
