import React from "react";
import styles from "./FormNotice.module.scss";

export function FormNotice({ children }) {
    return (
        <div className={styles.formNoticeContainer}>
            <span className="icon has-text-info is-large">
                <i className="fas fa-2x fa-exclamation-circle" />
            </span>
            <p className="help is-info is-size-6">{children}</p>
        </div>
    );
}
