import React, { Component } from "react";
import LocationsView from "./LocationsView";
import routeNames from "../../../constants/routeNames";
import createApiService from "../../../network";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { showModal } from "../../../actions/modals";
import modalTypes from "../../../constants/modalTypes";
import { normalize } from "normalizr";
import { putNormalizedEntities } from "../../../helpers";
import { crematory as crematorySchema } from "../../../schemas";

class LocationsContainer extends Component {
    state = {
        locations: null,
        isFetching: true
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    componentDidMount() {
        if (this.state.locations === null) {
            this.getLocations();
        } else {
            this.setState({
                isFetching: false
            });
        }
    }

    getLocations = async () => {
        this.setState({
            isFetching: true
        });

        try {
            const { data } = await this.api.getLocationsByOrganizationId(
                this.props.match.params.organizationID
            );

            const { entities } = normalize(data.data, [crematorySchema]);
            putNormalizedEntities(this.props.dispatch, entities);

            this.setState({
                locations: data.data,
                isFetching: false,
                error: null
            });
        } catch (error) {
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    onAddButtonClick = () => {
        this.props.dispatch(
            showModal({
                type: modalTypes.CREMATORY,
                props: {
                    organizationId: this.props.match.params.organizationID
                }
            })
        );
    };

    render() {
        return (
            <LocationsView
                locations={this.state.locations}
                isFetching={this.state.isFetching}
                onAddButtonClick={this.onAddButtonClick}
                onShowLocation={id =>
                    this.props.history.push(
                        `${routeNames.locationDetails}/`.replace(
                            ":crematoryID",
                            id
                        )
                    )
                }
                onEdit={id => {
                    this.props.dispatch(
                        showModal({
                            type: modalTypes.CREMATORY,
                            props: {
                                crematoryId: id,
                                onComplete: () => {
                                    this.getLocations();
                                }
                            }
                        })
                    );
                }}
            />
        );
    }
}

export default withRouter(LocationsContainer);
