import { isAfter, parseISO } from "date-fns";
import { createSelector } from "reselect";

export const tokenSelector = state => state.auth.token;

export const hasTokenExpiredSelector = createSelector(
    tokenSelector,
    token => {
        if (!token) return false;

        const now = new Date();
        const expiresAt = parseISO(token.expiresAt);
        return isAfter(now, expiresAt);
    }
);

export const isAuthenticatedSelector = createSelector(
    tokenSelector,
    hasTokenExpiredSelector,
    (token, hasTokenExpired) => {
        return token && !hasTokenExpired;
    }
);
