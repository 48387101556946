import { schema } from "normalizr";
import { omit } from "micro-dash";

export const device = new schema.Entity(
    "devices",
    {},
    {
        processStrategy: (entity, parent) => {
            if (parent === entity) return entity;
            return { ...entity, roomId: parent.id };
        }
    }
);

export const room = new schema.Entity(
    "rooms",
    { device },
    {
        processStrategy: (entity, parent) => {
            if (parent === entity) return entity;
            if (parent.crematoryId) {
                return { ...entity, crematoryId: parent.crematoryId };
            }

            return { ...entity, crematoryId: parent.id };
        }
    }
);

export const block = new schema.Entity(
    "blocks",
    {},
    {
        processStrategy: entity => {
            return omit(
                {
                    ...entity,
                    ceremonyId: parseInt(entity.ceremonyId)
                },
                "ceremony"
            );
        }
    }
);

export const crematoryInsideCeremony = new schema.Entity(
    "crematories",
    {},
    {
        processStrategy: (entity, parent) => {
            return { ...entity, ceremonies: [parent.id] };
        }
    }
);

export const ceremonyWithCrematorium = new schema.Entity(
    "ceremonies",
    {
        crematorium: crematoryInsideCeremony,
        blocks: [block],
        room
    },
    {
        processStrategy: entity => {
            return omit(
                { ...entity, crematoryId: entity.crematorium.id },
                "users"
            );
        }
    }
);

export const ceremony = new schema.Entity(
    "ceremonies",
    {
        crematorium: crematoryInsideCeremony,
        blocks: [block],
        room
    },
    {
        processStrategy: entity => {
            return omit(
                { ...entity, crematoryId: entity.crematorium.id },
                "crematorium",
                "users"
            );
        }
    }
);

export const photo = new schema.Entity(
    "photos",
    {},
    {
        processStrategy: entity => {
            return omit(
                { ...entity, ceremonyId: entity.ceremony.id },
                "ceremony"
            );
        }
    }
);

export const audioTrack = new schema.Entity(
    "audioTracks",
    {},
    {
        processStrategy: entity => {
            return omit(
                { ...entity, ceremonyId: entity.ceremony.id },
                "ceremony"
            );
        }
    }
);

export const video = new schema.Entity(
    "videos",
    {},
    {
        processStrategy: entity => {
            return omit(
                { ...entity, ceremonyId: entity.ceremony.id },
                "ceremony"
            );
        }
    }
);

export const crematory = new schema.Entity("crematories", {
    ceremonies: [ceremony],
    rooms: [room],
    devices: [device]
});

export const user = new schema.Entity(
    "users",
    {},
    {
        processStrategy: (entity, parent) => {
            return { ...entity, users: [parent.id] };
        }
    }
);

export const organization = new schema.Entity(
    "organizations",
    {},
    {
        processStrategy: (entity, parent) => {
            return { ...entity, organizations: [parent.id] };
        }
    }
);
