export const getDeviceEntities = state => state.entities.devices;

export const getDevicesById = state => getDeviceEntities(state).byId;
export const getDevicesAllIds = state => getDeviceEntities(state).allIds;
export const getDeviceById = (state, props) =>
    getDevicesById(state)[props.deviceId];

export const getCrematoryDevices = (state, crematoryRoomIds) => {
    return Object.values(state.entities.devices.byId).filter(device =>
        crematoryRoomIds.includes(device.roomId)
    );
};
