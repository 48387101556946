import React from "react";

import OrganizationDetailView from "../../components/OrganizationDetail";

const OrganizationDetail = props => {
    return (
        <div className="container">
            <OrganizationDetailView {...props}></OrganizationDetailView>
        </div>
    );
};

export default OrganizationDetail;
