import * as Yup from "yup";
import * as Sentry from "@sentry/browser";

import {
    INVALIDATE_USER,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_BY_ID_SUCCESS,
    FETCH_USER_BY_ID_FAILURE,
    FETCH_USER_BY_ID_REQUEST
} from "../constants/actionTypes";
import { fetchUserAssignedCrematory } from "./crematories";
import { login } from "./auth";
import routeNames from "../constants/routeNames";
import roles from "../constants/roles";
import { fetchUserAssignedCeremonies } from "./ceremonies";
import { showToast } from "./toasts";
import { extractApiErrorMessage } from "../helpers";
import i18n from "../constants/i18n";

export function invalidateUser() {
    return {
        type: INVALIDATE_USER
    };
}

export function fetchUserRequest() {
    return {
        type: FETCH_USER_REQUEST
    };
}

export function fetchUserSuccess(payload) {
    return {
        type: FETCH_USER_SUCCESS,
        payload
    };
}

export function fetchUserFailure(error) {
    return {
        type: FETCH_USER_FAILURE,
        error
    };
}

export function fetchUserByIdSuccess(payload) {
    return {
        type: FETCH_USER_BY_ID_SUCCESS,
        payload
    };
}

export function fetchUserByIdFailure(error) {
    return {
        type: FETCH_USER_BY_ID_FAILURE,
        error
    };
}

export function fetchUserByIdRequest() {
    return {
        type: FETCH_USER_BY_ID_REQUEST
    };
}

export function fetchUserById(userId) {
    const responseSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        emailVerifiedAt: Yup.string().nullable(),
        id: Yup.number().required(),
        name: Yup.string().nullable(),
        roles: Yup.array()
            .of(Yup.string())
            .required()
    });

    return function(dispatch, _, { api }) {
        dispatch(fetchUserByIdRequest(userId));

        return api
            .getUserProfileById(userId)
            .then(async response => {
                let isResponseValid = false;

                if (response.data.data) {
                    isResponseValid = await responseSchema.isValid(
                        response.data.data
                    );
                }

                if (isResponseValid) {
                    dispatch(fetchUserByIdSuccess(response.data.data));
                    return response.data.data;
                } else {
                    try {
                        await responseSchema.validate(response.data);
                    } catch (error) {
                        console.error(error.errors);
                    }

                    throw new Error("Response data does not match the schema");
                }
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error(error);
                dispatch(fetchUserByIdFailure(error));
            });
    };
}

export function fetchUser() {
    const responseSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        emailVerifiedAt: Yup.string().nullable(),
        id: Yup.number().required(),
        name: Yup.string().nullable(),
        roles: Yup.array()
            .of(Yup.string())
            .required()
    });

    return function(dispatch, _, { api }) {
        dispatch(fetchUserRequest());

        return api
            .getUserProfile()
            .then(async response => {
                let isResponseValid = false;

                if (response.data.data) {
                    isResponseValid = await responseSchema.isValid(
                        response.data.data
                    );
                }

                if (isResponseValid) {
                    dispatch(fetchUserSuccess(response.data.data));
                    return response.data.data;
                } else {
                    try {
                        await responseSchema.validate(response.data);
                    } catch (error) {
                        console.error(error.errors);
                    }

                    throw new Error("Response data does not match the schema");
                }
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error(error);
                dispatch(fetchUserFailure(error));
            });
    };
}

async function fetchAssignedEntities(dispatch, getState, props, user) {
    // Clear entities in the Redux state from the original admin user.
    const entities = [
        "audioTracks",
        "blocks",
        "ceremonies",
        "crematories",
        "devices",
        "photos",
        "rooms",
        "videos",
        "users"
    ];

    const state = getState();
    entities.forEach(entity => {
        state.entities[entity].allIds = [];
        state.entities[entity].byId = {};
    });

    // If user is not verified, redirect to verify e-mail page.
    if (!user.emailVerifiedAt) {
        props.history.push(routeNames.verifyEmail);
        return;
    }

    // Fetch assign crematories & ceremonies.
    if (user.roles.includes(roles.crematory)) {
        await dispatch(fetchUserAssignedCrematory());
    }

    if (user.roles.includes(roles.endUser)) {
        await dispatch(fetchUserAssignedCeremonies());
    }

    // Redirect to user "dashboard" page (this is role specific).
    props.history.replace(routeNames.app);

    return Promise.resolve();
}

export function startImpersonation(userId, username, props) {
    return async function(dispatch, getState, { api }) {
        if (
            window.confirm(
                i18n.userOverview.impersonateConfirmationPrompt.replace(
                    "<NAME>",
                    username
                )
            )
        ) {
            try {
                // Impersonate & retrieve new token.
                const response = await api.impersonateUser(userId);

                // Store the token in localstorage.
                dispatch(login(response.data));

                // Fetch user data & relevant data (crematoriums, ceremonies etc.) and store it in Redux state.entities.
                dispatch(fetchUser())
                    .then(async user =>
                        fetchAssignedEntities(dispatch, getState, props, user)
                    )
                    .catch(e => {
                        Sentry.captureException(e);
                        dispatch(
                            showToast({
                                body: extractApiErrorMessage(e),
                                title: "Error",
                                themeClass: "is-danger"
                            })
                        );
                    });

                // Show toast message that the impersonation is active.
                dispatch(
                    showToast({
                        body: i18n.userOverview.impersonateSuccess.replace(
                            "<NAME>",
                            username
                        ),
                        title: "Success",
                        themeClass: "is-success"
                    })
                );
            } catch (e) {
                // Show error if impersonation has failed (token expired, wrong role, users that cannot be impersonated etc.).
                Sentry.captureException(e);
                dispatch(
                    showToast({
                        body: extractApiErrorMessage(e),
                        title: "Error",
                        themeClass: "is-danger"
                    })
                );
            }
        }
    };
}

export function stopImpersonation(props) {
    // Almost the same as startImpersonation(), but with different endpoint & messages.
    return async function(dispatch, getState, { api }) {
        if (
            window.confirm(
                i18n.userOverview.stopImpersonateConfirmationPrompt.replace(
                    "<NAME>",
                    props.username
                )
            )
        ) {
            try {
                const response = await api.impersonateStop();

                dispatch(login(response.data));
                dispatch(fetchUser())
                    .then(async user =>
                        fetchAssignedEntities(dispatch, getState, props, user)
                    )
                    .catch(e => {
                        Sentry.captureException(e);
                        dispatch(
                            showToast({
                                body: extractApiErrorMessage(e),
                                title: "Error",
                                themeClass: "is-danger"
                            })
                        );
                    });

                dispatch(
                    showToast({
                        body: i18n.userOverview.stopImpersonateSuccess.replace(
                            "<NAME>",
                            props.username
                        ),
                        title: "Success",
                        themeClass: "is-success"
                    })
                );
            } catch (e) {
                Sentry.captureException(e);
                dispatch(
                    showToast({
                        body: extractApiErrorMessage(e),
                        title: "Error",
                        themeClass: "is-danger"
                    })
                );
            }
        }
    };
}
