import React from "react";

import ExternalCeremonies from "../../components/ExternalCeremonyOverview";

const ExternalCeremoniesRoute = () => {
    return (
        <div className="container">
            <ExternalCeremonies></ExternalCeremonies>
        </div>
    );
};

export default ExternalCeremoniesRoute;
