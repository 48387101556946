import { connect } from "react-redux";
import UsersContainer from "./UsersContainer";
import { get } from "micro-dash";

function getOrganizationIdFromRouteParams(match) {
    const id = get(match, ["params", "organizationID"], null);
    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = state => {
    const organizationDetailId = getOrganizationIdFromRouteParams(state.match);

    return {
        organization: state.entities.organizations.byId[organizationDetailId]
    };
};
export default connect(mapStateToProps)(UsersContainer);
