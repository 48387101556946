const routeNames = {
    login: "login",
    forgotPassword: "forgot-password",
    resetPassword: "reset-password",
    logout: "logout",
    selectCeremony: "select-ceremony",
    locations: "locations",
    ceremonies: "ceremonies",
    timeline: "timeline",
    verifyEmail: "verify-email",
    rooms: "rooms",
    devices: "devices",
    app: "app",
    users: "users",
    organizations: "organizations",
    userDetailView: "user-detail",
    invitedCeremonies: `external-ceremonies`
};

const routes = {
    login: "/login",
    forgotPassword: "/forgot-password",
    resetPassword: "/password-reset/:token",
    locationsFirstPage: "/app/locations/page/1",
    logout: "/logout",
    app: "/app",
    verifyEmail: `/${routeNames.app}/${routeNames.verifyEmail}`,
    selectCeremony: `/${routeNames.app}/${routeNames.selectCeremony}`,
    invitedCeremonies: `/${routeNames.app}/${routeNames.ceremonies}/${routeNames.invitedCeremonies}/page/:pageNumber`,
    locationsPaginated: `/${routeNames.app}/${routeNames.locations}/page/:pageNumber`,
    locations: `/${routeNames.app}/${routeNames.locations}`,
    locationDetails: `/${routeNames.app}/${routeNames.locations}/:crematoryID`,
    locationDetailsPaginated: `/${routeNames.app}/${routeNames.locations}/:crematoryID/page/:pageNumber`,
    timeline: `/${routeNames.app}/${routeNames.locations}/:crematoryID/timeline/:ceremonyID`,
    devices: `/${routeNames.app}/${routeNames.locations}/:crematoryID/${routeNames.devices}`,
    rooms: `/${routeNames.app}/${routeNames.locations}/:crematoryID/${routeNames.rooms}`,
    searchUsersPaginated: `/${routeNames.app}/${routeNames.users}/page/:pageNumber/search/:searchQuery`,
    usersPaginated: `/${routeNames.app}/${routeNames.users}/page/:pageNumber`,
    userDetail: `/${routeNames.app}/${routeNames.userDetailView}/:userID`,
    editUserDetail: `/${routeNames.app}/${routeNames.userDetailView}/:userID/edit`,
    usersImpersonateUser: `/${routeNames.app}/${routeNames.users}/impersonate/:userID`,
    searchOrganizationsPaginated: `/${routeNames.app}/${routeNames.organizations}/page/:pageNumber/search/:searchQuery`,
    organizations: `/${routeNames.app}/${routeNames.organizations}`,
    organizationDetail: `/${routeNames.app}/${routeNames.organizations}/:organizationID`,
    organizationsPaginated: `/${routeNames.app}/${routeNames.organizations}/page/:pageNumber`
};

export default routes;
