import React, { Component } from "react";
import axios from "axios";
import { normalize } from "normalizr";
import { debounce } from "micro-dash";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import createApiService from "../../network";
import CeremonyOverviewView from "./CeremonyOverviewView";
import { ceremony as ceremonySchema } from "../../schemas";
import { putNormalizedEntities } from "../../helpers";
import routeNames from "../../constants/routeNames";
import { fetchCrematoryIfNeeded } from "../../actions/crematories";
import { showModal } from "../../actions/modals";
import modalTypes from "../../constants/modalTypes";
import i18n from "../../constants/i18n";

class CeremonyOverviewContainer extends Component {
    state = {
        currentIds: [],
        isFetching: true,
        isSearching: false,
        searchQuery: "",
        roomFilterActiveId: "",
        meta: null,
        links: null,
        organizationCrematories: []
    };

    _isMounted = false;

    constructor(props) {
        super(props);

        this.api = createApiService(axios);
    }

    async componentDidMount() {
        this._isMounted = true;

        if (!this.props.crematory) {
            await this.props.dispatch(
                fetchCrematoryIfNeeded(
                    parseInt(this.props.match.params.crematoryID)
                )
            );
        }

        this.fetchCeremonies(this.props.match.params.pageNumber);

        // If the user belongs to an organization, fetch crematoriums so it can be used to create a
        // drop-down-box to make it possible to relocate a ceremony.
        if (this.props.organizationId > 0) {
            this.getCrematoriesByOrganizationUserId(
                this.props.organizationId,
                this.props.currentUserId
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.match.params.pageNumber !==
            this.props.match.params.pageNumber
        ) {
            this.fetchCeremonies(this.props.match.params.pageNumber);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCeremonies = async (pageNumber = 1, forceReload = false) => {
        if (
            !forceReload &&
            this.state.meta &&
            pageNumber === this.state.meta.currentPage
        ) {
            return;
        }

        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getCeremoniesPaginated(
                this.props.match.params.crematoryID,
                pageNumber
            );

            const { data, meta, links } = response.data;
            this.normalizeAndPutEntities(data);

            if (this._isMounted) {
                this.setState({
                    currentIds: data.map(ceremony => ceremony.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    getCrematoriesByOrganizationUserId = async (organizationId, userId) => {
        this.setState({
            isFetching: true
        });

        try {
            const response = await this.api.getCrematoriesByOrganizationUserId(
                organizationId,
                userId
            );

            const { data, meta, links } = response.data;
            this.state.organizationCrematories = data;

            if (this._isMounted) {
                this.setState({
                    currentIds: data.map(room => room.id),
                    meta,
                    links,
                    isFetching: false,
                    error: null
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            if (this._isMounted) {
                this.setState({
                    isFetching: false,
                    error
                });
            }
        }
    };

    getCeremoniesByRoom = async ({ roomId, pageNumber }) => {
        if (roomId === "") {
            this.searchCeremony(this.props.match.params.crematoryID, "");
            return;
        }

        try {
            const response = await this.api.getCeremoniesByRoom(
                this.props.match.params.crematoryID,
                roomId,
                pageNumber
            );

            const { data, meta, links } = response.data;
            this.normalizeAndPutEntities(data);

            this.setState({
                currentIds: data.map(ceremony => ceremony.id),
                meta,
                links,
                isFetching: false,
                error: null
            });
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            this.setState({
                isFetching: false,
                error
            });
        }
    };

    searchCeremony = async query => {
        this.setState({ isFetching: true, isSearching: true });

        try {
            const response = await this.api.searchCeremony(
                this.props.match.params.crematoryID,
                query
            );

            const { data, meta, links } = response.data;
            this.normalizeAndPutEntities(data);

            this.setState({
                currentIds: data.map(ceremony => ceremony.id),
                meta,
                links,
                isFetching: false,
                isSearching: false,
                error: null
            });
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);

            this.setState({
                isFetching: false,
                isSearching: false,
                error
            });
        }
    };

    debouncedOnSearch = debounce(event => {
        this.searchCeremony(event.target.value);
    }, 500);

    onSearch = event => {
        event.persist();
        this.debouncedOnSearch(event);
        this.setState({
            searchQuery: event.target.value,
            roomFilterActiveId: ""
        });
    };

    onPaginationSelect = async pageNumber => {
        if (this.state.roomFilterActiveId) {
            this.getCeremoniesByRoom({
                roomId: this.state.roomFilterActiveId,
                pageNumber
            });
        } else {
            this.props.history.push(
                `${routeNames.locationDetailsPaginated}`
                    .replace(":pageNumber", pageNumber)
                    .replace(
                        ":crematoryID",
                        this.props.match.params.crematoryID
                    )
            );
        }
    };

    onAddButtonClick = () => {
        this.props.dispatch(
            showModal({
                type: modalTypes.CEREMONY,
                props: {
                    crematoryId: +this.props.match.params.crematoryID,
                    organizationCrematories: this.state.organizationCrematories
                }
            })
        );
    };

    onDeviceLinkClick = () => {
        this.props.history.push(
            `${routeNames.locations}/${this.props.match.params.crematoryID}/devices`
        );
    };

    onRoomLinkClick = () => {
        this.props.history.push(
            `${routeNames.locations}/${this.props.match.params.crematoryID}/rooms`
        );
    };

    onExternalCeremoniesLinkClick = () => {
        this.props.history.push(
            `${routeNames.invitedCeremonies}`.replace(":pageNumber", 1)
        );
    };

    onRoomFilterChange = newValue => {
        if (!newValue) {
            this.setState({
                roomFilterActiveId: "",
                searchQuery: ""
            });
            this.fetchCeremonies(1, true);
            return;
        }

        this.setState({
            roomFilterActiveId: newValue,
            searchQuery: ""
        });

        this.getCeremoniesByRoom({ roomId: newValue });
    };

    normalizeAndPutEntities = rawData => {
        const { entities } = normalize(rawData, [ceremonySchema]);
        putNormalizedEntities(this.props.dispatch, entities);
    };

    downloadScript = async ceremony => {
        const response = await this.api.getCeremonyScript_RAW(ceremony.item.id);
        const body = await response.blob();
        const url = URL.createObjectURL(body);

        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${ceremony.item.familyName} - ${i18n.generic.script}.pdf`;
        anchor.click();

        URL.revokeObjectURL(url);
    };

    onUnlock = ceremonyId => {
        this.props.dispatch(
            showModal({
                type: modalTypes.UNLOCK_CEREMONY_MODAL,
                props: { ceremonyId }
            })
        );
    };

    render() {
        if (!this.props.crematory) {
            return null;
        }

        return (
            <CeremonyOverviewView
                ceremonyIds={this.state.currentIds}
                crematory={this.props.crematory}
                error={this.state.error}
                isAdmin={this.props.isAdmin}
                isAdminOrOrganization={
                    this.props.isAdmin || this.props.isOrganization
                }
                isFetching={this.state.isFetching}
                isSearching={this.state.isSearching}
                meta={this.state.meta}
                onAddButtonClick={this.onAddButtonClick}
                onDeviceLinkClick={this.onDeviceLinkClick}
                onRoomLinkClick={this.onRoomLinkClick}
                onExternalCeremoniesLinkClick={
                    this.onExternalCeremoniesLinkClick
                }
                onEdit={id => {
                    this.props.dispatch(
                        showModal({
                            type: modalTypes.CEREMONY,
                            props: {
                                crematoryId: +this.props.match.params
                                    .crematoryID,
                                ceremonyId: id,
                                organizationCrematories: this.state
                                    .organizationCrematories
                            }
                        })
                    );
                }}
                onPaginationSelect={this.onPaginationSelect}
                onSearchChange={this.onSearch}
                onSelect={id => {
                    sessionStorage.setItem(
                        "ceremonyOverviewPage",
                        this.props.match.params.pageNumber
                    );
                    this.props.history.push(
                        `${routeNames.locations}/${this.props.match.params.crematoryID}/timeline/${id}`
                    );
                }}
                onUnlock={id => this.onUnlock(id)}
                searchQuery={this.state.searchQuery}
                roomFilterActiveId={this.state.roomFilterActiveId}
                onRoomFilterChange={this.onRoomFilterChange}
                rooms={this.props.rooms}
                onScriptButtonClick={this.downloadScript}
            ></CeremonyOverviewView>
        );
    }
}

export default withRouter(CeremonyOverviewContainer);
