import React from "react";

import styles from "./DeleteWarningModal.module.scss";
import i18n from "../../../../constants/i18n";

const DeleteWarningModalView = ({
    hideModal,
    isVisible,
    deleteCeremony,
    handleChange,
    isChecked
}) => {
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.deleteWarningModal.title}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p>{i18n.deleteWarningModal.warningTitle}</p>
                    <p>{i18n.deleteWarningModal.warningBody}</p>
                    <label>
                        <input type={"checkbox"} onChange={handleChange} />
                        {i18n.deleteWarningModal.iAmSure}
                    </label>
                </section>

                <footer className="modal-card-foot">
                    <button
                        onClick={hideModal}
                        className="button is-primary is-rounded"
                    >
                        {i18n.deleteWarningModal.backButtonLabel}
                    </button>
                    <button
                        disabled={!isChecked}
                        onClick={deleteCeremony}
                        className="button is-danger is-rounded"
                    >
                        {i18n.deleteWarningModal.deleteButtonLabel}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DeleteWarningModalView;
