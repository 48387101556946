import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import { normalize } from "normalizr";
import { Formik } from "formik";
import { debounce, get, isUndefined, sortBy } from "micro-dash";

import i18n from "../../../../constants/i18n";
import createApiService from "../../../../network";
import { insertBlockAtIndex } from "../../../../helpers/blockHelpers";
import blockTypes from "../../../../constants/blockTypes";
import {
    audioTrack as audioTrackSchema,
    block as blockSchema
} from "../../../../schemas";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import handleUploadProgress from "../../../../helpers/handleUploadProgress";

import MusicUploadModalView from "./MusicUploadModalView";
import { mediaStreamBaseUrl } from "../../../../constants";

const supportedFormats = ["audio/*"];

class MusicUploadModalContainer extends Component {
    initialValues = {
        audioTrack: null,
        libraryTrack: "",
        libraryArtist: "",
        librarySongName: "",
        libraryDuration: 0 // In miliseconds
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.audioRef = React.createRef();
        this.state = {
            hasSearchedAtLeastOnce: false,
            libraryItems: [], // Track duration is in milliseconds
            libraryLoading: false,
            libraryQuery: "",
            playbackActive: false,
            playbackDuration: 0, // In seconds
            playbackTime: 0,
            playbackTrackID: null
        };
    }

    componentDidMount() {
        this.audioRef.current.addEventListener("abort", this.onPlaybackAbort);
        this.audioRef.current.addEventListener("ended", this.onPlaybackEnd);
        this.audioRef.current.addEventListener(
            "timeupdate",
            this.onPlaybackTimeUpdate
        );
    }

    componentWillUnmount() {
        this.audioRef.current.removeEventListener(
            "abort",
            this.onPlaybackAbort
        );
        this.audioRef.current.removeEventListener("ended", this.onPlaybackEnd);
        this.audioRef.current.removeEventListener(
            "timeupdate",
            this.onPlaybackTimeUpdate
        );
    }

    componentDidUpdate(_, prevState) {
        if (
            this.state.playbackActive !== prevState.playbackActive ||
            this.state.playbackTrackID !== prevState.playbackTrackID
        ) {
            this.state.playbackActive
                ? this.audioRef.current.play()
                : this.audioRef.current.pause();
        }
    }

    togglePlayback = (playbackActive, track) => {
        if (playbackActive === false) {
            this.setState({ playbackActive: false, playbackTrackID: null });
            return;
        }

        this.audioRef.current.src = mediaStreamBaseUrl + track.trackToken;
        this.audioRef.current.load();
        this.setState({
            playbackActive: true,
            playbackTrackID: track.trackToken,
            playbackDuration: Math.round(track.duration / 1000)
        });
    };

    setPlaybackTime = time => {
        this.audioRef.current.currentTime = time;
    };

    onPlaybackAbort = () => {
        this.togglePlayback(false);
    };

    onPlaybackEnd = () => {
        this.togglePlayback(false);
        this.audioRef.current.currentTime = 0;
    };

    onPlaybackTimeUpdate = () => {
        this.setState({ playbackTime: this.audioRef.current.currentTime });
    };

    searchMusic = async () => {
        if (this.state.libraryQuery.length < 2) {
            this.setState({
                libraryLoading: false,
                libraryItems: []
            });
            return;
        }

        this.setState({ libraryLoading: true });

        try {
            const { data } = await this.api.searchMusic(
                this.state.libraryQuery.trim()
            );

            this.setState({
                libraryLoading: false,
                libraryItems: data,
                hasSearchedAtLeastOnce: true
            });
        } catch (error) {
            this.setState({
                libraryLoading: false
            });
        }
    };

    requestMusicSearch = debounce(() => {
        this.searchMusic();
    }, 500);

    onQueryChange = event => {
        event.persist();
        this.setState(
            { libraryQuery: event.target.value, libraryLoading: true },
            () => {
                this.requestMusicSearch(this.state.libraryQuery);
            }
        );
    };

    baseValidationSchema = Yup.object().shape({
        libraryTrack: Yup.string().nullable(),
        audioTrack: Yup.mixed()
            .test("fileType", i18n.validation.fileType, function(value) {
                if (
                    (!value && !this.resolve(Yup.ref("libraryTrack"))) ||
                    (!value && this.resolve(Yup.ref("libraryTrack")))
                ) {
                    return true;
                }

                if (!value) return false;

                return value.type.startsWith("audio/");
            })
            .test(
                "condition",
                i18n.musicUploadModal.validationOneOfFields,
                function(value) {
                    return !(!this.resolve(Yup.ref("libraryTrack")) && !value);
                }
            )
    });

    mapValidationErrors = (e, formikBag) => {
        const fileError = get(e, ["errors", "files.0", "0"]);
        if (fileError) {
            formikBag.setFieldError("audioTrack", fileError);
        } else {
            formikBag.setErrors(e.errors);
        }
    };

    onSubmit = (values, formikBag) => {
        formikBag.setStatus({ progress: 0 });

        if (this.props.isWalkInBlock || this.props.isWalkOutBlock) {
            return this.onUpdateWalkInOrOutBlock(values, formikBag);
        }

        if (this.props.isEditing) {
            this.onEdit(values, formikBag);
        } else {
            this.onCreate(values, formikBag);
        }
    };

    uploadFile = async ({ ceremonyId, file, formikBag }) => {
        const { data } = await this.api.postMedia(
            ceremonyId,
            { "files[0]": file },
            e => handleUploadProgress(e, formikBag.setStatus)
        );
        const audioTrack = data.data;

        const { entities: audioTrackEntities } = normalize(
            audioTrack,
            audioTrackSchema
        );
        this.props.updateStoreEntities(audioTrackEntities);

        return { audioTrack, duration: data.duration[0] };
    };

    importAudioLibraryTrack = async ({
        ceremonyId,
        trackToken,
        artist,
        title,
        duration
    }) => {
        const { data } = await this.api.importAudioLibraryTrack({
            ceremonyId,
            trackToken,
            artist,
            title
        });
        const audioTrack = data.data;

        const { entities: audioTrackEntities } = normalize(
            audioTrack,
            audioTrackSchema
        );
        this.props.updateStoreEntities(audioTrackEntities);

        return { audioTrack, duration };
    };

    onCreate = async (values, formikBag) => {
        const { ceremony } = this.props;

        try {
            const { audioTrack, duration } = values.audioTrack
                ? await this.uploadFile({
                      ceremonyId: ceremony.item.id,
                      file: values.audioTrack,
                      formikBag
                  })
                : await this.importAudioLibraryTrack({
                      ceremonyId: ceremony.item.id,
                      trackToken: values.libraryTrack,
                      artist: values.libraryArtist,
                      title: values.librarySongName,
                      duration: values.libraryDuration
                  });

            const {
                data: { data: block }
            } = await this.api.postBlock(ceremony.item.id, {
                type: blockTypes.AUDIO,
                duration: duration,
                audioId: audioTrack.id,
                position: this.props.position
            });

            const blockIds = insertBlockAtIndex({
                array: this.props.blocks,
                item: block,
                index: this.props.position
            })
                .map((block, index) => ({ ...block, position: index }))
                .map(block => block.id);

            await this.refreshTimelineBlocks(blockIds);

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.createBlockSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onEdit = async (values, formikBag) => {
        const { ceremony } = this.props;

        try {
            const { audioTrack, duration } = values.audioTrack
                ? await this.uploadFile({
                      ceremonyId: ceremony.item.id,
                      file: values.audioTrack,
                      formikBag
                  })
                : await this.importAudioLibraryTrack({
                      ceremonyId: ceremony.item.id,
                      trackToken: values.libraryTrack,
                      artist: values.libraryArtist,
                      title: values.librarySongName,
                      duration: values.libraryDuration
                  });

            const {
                data: { data: block }
            } = await this.api.putBlock(ceremony.item.id, {
                ...this.props.block,
                audioId: audioTrack.id,
                duration: duration
            });

            const { entities } = normalize(block, blockSchema);
            this.props.updateStoreEntities(entities);

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.updatedBlockSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onUpdateWalkInOrOutBlock = async (values, formikBag) => {
        const { ceremony } = this.props;
        const initialBlock = this.props.block;

        try {
            const position = this.props.isWalkInBlock
                ? 0
                : this.props.blocks.length - 1;

            const { audioTrack, duration } = values.audioTrack
                ? await this.uploadFile({
                      ceremonyId: ceremony.item.id,
                      file: values.audioTrack,
                      formikBag
                  })
                : await this.importAudioLibraryTrack({
                      ceremonyId: ceremony.item.id,
                      trackToken: values.libraryTrack,
                      artist: values.libraryArtist,
                      title: values.librarySongName,
                      duration: values.libraryDuration
                  });

            const {
                data: { data: block }
            } = await this.api.putBlock(ceremony.item.id, {
                ...this.props.block,
                type: blockTypes.AUDIO,
                duration: duration,
                audioId: audioTrack.id,
                videoId: null,
                imageIds: initialBlock.imageIds,
                position
            });

            await this.refreshTimelineBlocks();

            formikBag.setStatus({ uploadProgress: 100 });

            // Wait for progress bar transition
            setTimeout(() => {
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: i18n.generic.addMusicFileSuccess,
                    title: "Success",
                    themeClass: "is-success"
                });
                this.props.hideModal();
            }, 350);
        } catch (e) {
            if (e instanceof ValidationError) {
                this.mapValidationErrors(e, formikBag);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    /**
     * Reresh timeline blocks.
     *
     * @TODO Refactor this at a later moment.
     * This a copy-paste from TimelineOverviewContainer.js
     * Find a way to call that method, using ref, dispatch or something similar.
     * Passing this method using props is not feasible, because this component is used in a lot of places.
     *
     *
     * Everytime that a block is: added, removed or sorted.
     * Refresh the blocks to keep the data in sync (otherwise the user may see outdated data
     * when using different browser tabs).
     *
     * @param blockIds|null       List of current or preferred order blockIds. If no ID's are given, the positions are server-side regenerated.
     * @returns {Promise<void>}
     */
    refreshTimelineBlocks = async blockIds => {
        // Get block ID's from browser.
        if (isUndefined(blockIds)) {
            blockIds = sortBy(this.props.blocks, block => block.position).map(
                block => block.id
            );
        }

        // Submit the new positions of the blocks.
        const {
            data: { data: blocks }
        } = await this.api.reorderBlocks(this.props.ceremony.item.id, blockIds);

        // Check if the submitted block positions matches the blocks from the server.
        let hasDeletedBlocks = false;
        blockIds.forEach(blockId => {
            let block = blocks.find(block => block.id === blockId);
            if (isUndefined(block)) {
                this.props.onBlockDeleted(blockId);
                hasDeletedBlocks = true;
            }
        });

        // If the positions doesn't match, inform the user that the browser data is outdated since last update.
        if (hasDeletedBlocks || blockIds.length !== blocks.length) {
            this.props.showToast({
                body: i18n.timelineOverview.blocksModifiedSinceLastUpdate,
                title: "Warning",
                themeClass: "is-warning"
            });
        }

        // Store the data.
        const { entities } = normalize(blocks, [blockSchema]);
        this.props.updateStoreEntities(entities);
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.baseValidationSchema}
                render={props => (
                    <>
                        <MusicUploadModalView
                            hideModal={this.props.hideModal}
                            isVisible={this.props.isVisible}
                            supportedFormats={supportedFormats}
                            isEditing={this.props.isEditing}
                            block={this.props.block}
                            isWalkInBlock={this.props.isWalkInBlock}
                            isWalkOutBlock={this.props.isWalkOutBlock}
                            togglePlayback={this.togglePlayback}
                            setPlaybackTime={this.setPlaybackTime}
                            onQueryChange={this.onQueryChange}
                            {...this.state}
                            {...props}
                        />
                        <audio
                            autoPlay={false}
                            loop={false}
                            muted={false}
                            preload="none"
                            ref={this.audioRef}
                        />
                    </>
                )}
            />
        );
    }
}

export default withRouter(MusicUploadModalContainer);
