import React from "react";
import EditUserDetail from "../../components/UserDetail/Edit/";

const EditUserDetailRoute = props => {
    return (
        <div className="container">
            <EditUserDetail></EditUserDetail>
        </div>
    );
};

export default EditUserDetailRoute;
