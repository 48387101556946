import { createSelector } from "reselect";

export const getOrganizationEntities = state => state.entities.organizations;
export const getOrganizationsById = state =>
    getOrganizationEntities(state).byId;
export const getOrganizationsAllIds = state =>
    getOrganizationEntities(state).allIds;
export const getOrganizationById = (state, props) =>
    getOrganizationsById(state)[props.organizationId];

export const getOrganizationItemById = createSelector(
    [getOrganizationById],
    organization => {
        return { ...organization.item };
    }
);
