import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { normalize } from "normalizr";
import * as Sentry from "@sentry/browser";

import createApiService from "../../../../network";
import i18n from "../../../../constants/i18n";
import {
    photo as photoSchema,
    block as blockSchema
} from "../../../../schemas";
import { extractApiErrorMessage } from "../../../../helpers";

import LibraryImageRotateModalView from "./LibraryImageRotateModalView";

class LibraryImageRotateModalContainer extends Component {
    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        this.state = {
            src: props.photo.fileNameNormalized || props.photo.fileName,
            rotation: 0,
            scale: 1,
            isSubmitting: false
        };
    }

    onRotationChange = (width, height) => {
        let targetRotation;
        let targetScale;

        if (this.state.rotation === -270) {
            targetRotation = 0;
        } else {
            targetRotation = this.state.rotation - 90;
        }

        if (Math.abs(targetRotation) % 180) {
            targetScale = height / width;
        } else {
            targetScale = 1;
        }

        this.setState({ rotation: targetRotation, scale: targetScale });
    };

    onCreateNewImage = async () => {
        try {
            // If image is not rotated, return to block edit modal
            if (this.state.rotation === 0) {
                this.props.hideModal();
                return;
            }

            this.setState({ isSubmitting: true });

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("orientation", this.state.rotation * -1);

            const {
                data: { data: rotatedImage }
            } = await this.api.postPhotoAndRotate(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.newImage,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    onUpdateCurrentImage = async () => {
        try {
            // If image is not rotated, return to block edit modal
            if (this.state.rotation === 0) {
                this.props.hideModal();
                return;
            }

            this.setState({ isSubmitting: true });

            const formData = new FormData();
            formData.append("imageId", this.props.photo.id);
            formData.append("orientation", this.state.rotation * -1);
            formData.append("updateExistingImage", true);

            const {
                data: { data: rotatedImage }
            } = await this.api.postPhotoAndRotate(
                this.props.ceremony.id,
                formData
            );

            const {
                data: { data: photos }
            } = await this.api.getCeremonyPhotos(this.props.ceremony.id);

            const { entities } = normalize(photos, [photoSchema]);
            this.props.updateStoreEntities(entities);

            this.setState({ isSubmitting: false });

            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updatedImage,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            this.setState({ isSubmitting: false });
            Sentry.captureException(e);
            console.error(e);
            this.props.showToast({
                body: extractApiErrorMessage(e),
                title: "Error",
                themeClass: "is-danger"
            });
        }
    };

    isImageUsedInBlock = imageId => {
        for (const block of this.props.blocks) {
            if (block.imageIds.includes(imageId)) {
                return true;
            }
        }
        return false;
    };

    onReturnToImageAdjustmentModal = () => {
        this.props.hideModal();
        this.props.showLibraryPhotoAdjustmentModal(
            this.props.photo.id,
            this.props.ceremonyId
        );
    };

    render() {
        return (
            <LibraryImageRotateModalView
                hideModal={this.props.hideModal}
                isSubmitting={this.state.isSubmitting}
                isVisible={this.props.isVisible}
                onRotationChange={this.onRotationChange}
                onCreateNewImage={this.onCreateNewImage}
                onUpdateCurrentImage={this.onUpdateCurrentImage}
                onReturnToImageAdjustmentModal={
                    this.onReturnToImageAdjustmentModal
                }
                rotation={this.state.rotation}
                scale={this.state.scale}
                src={this.state.src}
                isImageUsedInBlock={this.isImageUsedInBlock(
                    this.props.photo.id
                )}
            />
        );
    }
}

export default withRouter(LibraryImageRotateModalContainer);
