import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import { normalize } from "normalizr";
import { omit } from "micro-dash";

import i18n from "../../../../constants/i18n";
import createApiService from "../../../../network";
import { ceremony as ceremonySchema } from "../../../../schemas";
import ValidationError from "../../../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../../../helpers";
import { processCeremonyFormData } from "../../../../helpers/ceremonyTransformer";

import MainCeremonyPhotoModalView from "./MainCeremonyPhotoModalView";

class MainCeremonyPhotoModalContainer extends Component {
    initialValues = {
        mainImageId: null
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        if (props.ceremony) {
            this.initialValues.mainImageId = props.ceremony.item.mainImageId;
        }
    }

    validationSchema = Yup.object().shape({
        mainImageId: Yup.number().required(i18n.validation.required)
    });

    resetMainImage = async () => {
        let processedValues = processCeremonyFormData(
            false,
            {
                ...this.props.ceremony.item,
                mainImageId: null
            },
            true
        );

        processedValues = omit(
            processedValues,
            "ceremonyMasterName",
            "ceremonyMasterEmail",
            "ceremonyMasterPhone"
        );

        try {
            const {
                data: { data: updatedCeremony }
            } = await this.api.putCeremony(processedValues.id, processedValues);

            const { entities } = normalize(updatedCeremony, ceremonySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updateCeremonySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
            } else {
                Sentry.captureException(e);

                console.error(e);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    onSubmit = async (values, formikBag) => {
        let processedValues = processCeremonyFormData(
            false,
            {
                ...this.props.ceremony.item,
                mainImageId: values.mainImageId
            },
            true
        );
        processedValues = omit(
            processedValues,
            "ceremonyMasterName",
            "ceremonyMasterEmail",
            "ceremonyMasterPhone"
        );

        try {
            const {
                data: { data: updatedCeremony }
            } = await this.api.putCeremony(processedValues.id, processedValues);

            formikBag.setSubmitting(false);
            const { entities } = normalize(updatedCeremony, ceremonySchema);

            this.props.updateStoreEntities(entities);
            this.props.hideModal();
            this.props.showToast({
                body: i18n.generic.updateCeremonySuccess,
                title: "Success",
                themeClass: "is-success"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);

                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                render={formProps => (
                    <MainCeremonyPhotoModalView
                        resetMainImage={this.resetMainImage}
                        hideModal={this.props.hideModal}
                        isVisible={this.props.isVisible}
                        {...formProps}
                        {...this.props}
                    ></MainCeremonyPhotoModalView>
                )}
            />
        );
    }
}

export default withRouter(MainCeremonyPhotoModalContainer);
