/* eslint-disable default-case */

import produce from "immer";

import {
    INVALIDATE_USER,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE
} from "../constants/actionTypes";

const initialState = {
    didInvalidate: false,
    error: null,
    isFetching: false,
    lastUpdated: null,
    profile: null
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case INVALIDATE_USER:
            draft.didInvalidate = true;
            break;
        case FETCH_USER_REQUEST:
            draft.isFetching = true;
            break;
        case FETCH_USER_SUCCESS:
            draft.error = null;
            draft.isFetching = false;
            draft.didInvalidate = false;
            draft.lastUpdated = Date.now();
            draft.profile = action.payload;
            break;
        case FETCH_USER_FAILURE:
            draft.isFetching = false;
            draft.error = action.error;
            break;
    }
}, initialState);

export default reducer;
