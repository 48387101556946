import { connect } from "react-redux";
import { get, pick, omit } from "micro-dash";

import CrematoryModalContainer from "./CrematoryModalContainer";
import { putNormalizedEntities } from "../../../../helpers";
import { deleteCrematory } from "../../../../actions/crematories";
import { hideModal } from "../../../../actions/modals";
import { getCrematoryItemById } from "../../../../selectors/crematories";
import { showToast } from "../../../../actions/toasts";

const allowedFields = [
    "active",
    "address",
    "administrators",
    "aspectRatio",
    "city",
    "zipcode",
    "locationPhoneNumber",
    "locationEmail",
    "id",
    "logo",
    "name",
    "region",
    "rooms",
    "organization"
];

const mapStateToProps = (state, props) => {
    const isEditing = Boolean(props.crematoryId);
    let crematoryItem = null;

    if (isEditing) {
        crematoryItem = getCrematoryItemById(state, props);
        crematoryItem = pick.apply(this, [crematoryItem, ...allowedFields]);
        crematoryItem = omit(
            {
                ...crematoryItem,
                crematoriumAdminEmail: get(crematoryItem, [
                    "administrators",
                    "0",
                    "email"
                ]),
                crematoriumAdminName: get(crematoryItem, [
                    "administrators",
                    "0",
                    "name"
                ])
            },
            "administrators"
        );
    }

    return {
        crematory: crematoryItem,
        userId: state.user.profile.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        },
        showToast: options => {
            dispatch(showToast(options));
        },
        hideModal: () => {
            dispatch(hideModal());
        },
        onCrematoryDeleted: id => {
            dispatch(deleteCrematory(id));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CrematoryModalContainer);
