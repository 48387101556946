import React from "react";

import styles from "./LockedTimelineModal.module.scss";

import i18n from "../../../../constants/i18n";
import ShowIf from "../../../ShowIf";

const LockedTimelineModalView = ({
    areAssetsCleanedUp,
    ceremonyMaster,
    onCloseButtonClick,
    isVisible,
    onFooterButtonClick
}) => {
    const bodyLabel1 =
        i18n.lockedTimelineModal[
            areAssetsCleanedUp
                ? "mediaAlreadyClearedUpExplanationParagraph1"
                : "ceremonyLockedParagraph1"
        ];
    const bodyLabel2 =
        i18n.lockedTimelineModal[
            areAssetsCleanedUp
                ? "mediaAlreadyClearedUpExplanationParagraph2"
                : "ceremonyLockedParagraph2"
        ];
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.lockedTimelineModal.title}
                    </h2>
                    <button
                        onClick={onCloseButtonClick}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.explanation}>
                        <p>{bodyLabel1}</p>
                        <p>{bodyLabel2}</p>
                    </div>

                    <ShowIf condition={!areAssetsCleanedUp}>
                        <h4 className={styles.contactTitle}>
                            {
                                i18n.lockedTimelineModal
                                    .ceremonyMasterContactHeader
                            }
                        </h4>
                        <div className={styles.contactDetails}>
                            <p>{ceremonyMaster.name}</p>
                            <p>{ceremonyMaster.email}</p>
                            <p>{ceremonyMaster.phone}</p>
                        </div>
                    </ShowIf>
                </section>
                <footer className="modal-card-foot">
                    <ShowIf condition={!areAssetsCleanedUp}>
                        <button
                            onClick={onFooterButtonClick}
                            className="button is-primary is-rounded"
                        >
                            {i18n.blockProcessingModal.footerButtonLabel}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default LockedTimelineModalView;
