import { omit } from "micro-dash";

import { isJSONObject as isValidJsonString } from "../../helpers";

function isIterable(data) {
    return Array.isArray(data);
}

export function transformReorderBlockResponse(response) {
    if (!isValidJsonString(response)) {
        return response;
    }

    // Wrap the response in a data field
    return JSON.stringify({ data: JSON.parse(response) });
}

export function transformBlockResponse(response) {
    if (!isValidJsonString(response)) {
        return response;
    }
    const { data } = JSON.parse(response);

    function transformer(block) {
        if (!block.image_ids) {
            return { ...block, image_ids: [] };
        } else if (isValidJsonString(block.image_ids)) {
            return { ...block, image_ids: JSON.parse(block.image_ids) };
        }

        return { ...block };
    }

    if (isIterable(data)) {
        return { data: data.map(transformer) };
    } else {
        return { data: transformer(data) };
    }
}

export function transformBlockRequest(requestBody, headers) {
    function transformer(block) {
        return omit(
            { ...block, image_ids: JSON.stringify(block.image_ids) },
            "photos",
            "audio_track",
            "video",
            "created_at",
            "updated_at"
        );
    }

    if (isIterable(requestBody)) {
        return requestBody.map(transformer);
    } else {
        return transformer(requestBody);
    }
}
