import React, { Component } from "react";
import axios from "axios";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Sentry from "@sentry/browser";
import { omit } from "micro-dash";

import createApiService from "../../network";
import ValidationError from "../../helpers/ValidationError";
import { extractApiErrorMessage } from "../../helpers";
import i18n from "../../constants/i18n";

import ResetPasswordFormView from "./ResetPasswordFormView";

class ResetPasswordFormContainer extends Component {
    initialValues = {
        email: "",
        current: "",
        password: "",
        passwordConfirmation: "",
        termsAndConditions: 0
    };

    constructor(props) {
        super(props);

        this.api = createApiService(axios);

        const urlParams = new URLSearchParams(props.location.search);
        const prefilledEmailValue = urlParams.get("email");
        if (prefilledEmailValue) {
            this.initialValues.email = prefilledEmailValue.replace(/\s/g, "+");
        }
    }

    validationSchema = Yup.object().shape({
        password: Yup.string().required(i18n.validation.required),
        termsAndConditions: Yup.boolean().oneOf(
            [true],
            i18n.validation.required
        ),
        passwordConfirmation: Yup.string()
            .required(i18n.validation.required)
            .oneOf([Yup.ref("password"), null], i18n.validation.matchPasswords)
    });

    resetPasswordValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(i18n.validation.email)
            .required(i18n.validation.required)
    });

    updatePasswordValidationSchema = Yup.object().shape({
        current: Yup.string().required(i18n.validation.required)
    });

    onSubmit = async (values, formikBag) => {
        try {
            if (this.props.updatePassword) {
                await this.api.updatePassword(omit(values, "email"));
            } else {
                const token = this.props.match.params.token;
                await this.api.resetPassword(token, omit(values, "current"));
            }

            formikBag.setSubmitting(false);
            formikBag.resetForm();
            this.props.showToast({
                body: i18n.resetPasswordForm.successMessage,
                title: "Success",
                themeClass: "is-success"
            });
            this.props.history.push("/login");
        } catch (e) {
            if (e instanceof ValidationError) {
                formikBag.setErrors(e.errors);
                formikBag.setSubmitting(false);
            } else {
                Sentry.captureException(e);
                console.error(e);
                formikBag.setSubmitting(false);
                this.props.showToast({
                    body: extractApiErrorMessage(e),
                    title: "Error",
                    themeClass: "is-danger"
                });
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                onSubmit={this.onSubmit}
                validationSchema={
                    this.props.updatePassword
                        ? this.validationSchema.concat(
                              this.updatePasswordValidationSchema
                          )
                        : this.validationSchema.concat(
                              this.resetPasswordValidationSchema
                          )
                }
                render={props => (
                    <ResetPasswordFormView
                        {...props}
                        updatePassword={this.props.updatePassword}
                    ></ResetPasswordFormView>
                )}
            />
        );
    }
}

export default withRouter(ResetPasswordFormContainer);
