import { connect } from "react-redux";

import roleTypes from "../../../constants/roles";
import { get } from "micro-dash";

import EditUserDetailContainer from "./EditUserDetailContainer";
import { withRouter } from "react-router-dom";
import { showToast } from "../../../actions/toasts";
import { putNormalizedEntities } from "../../../helpers";

function getUserIdFromRouteParams(match) {
    const id = get(match, ["params", "userID"], null);
    if (Number.isNaN(parseInt(id))) {
        return null;
    }

    return parseInt(id);
}

const mapStateToProps = (state, props) => {
    const userDetailId = getUserIdFromRouteParams(props.match);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);

    return {
        userToEdit: state.entities.users.byId[userDetailId]
            ? state.entities.users.byId[userDetailId].item
            : undefined,
        locations: state.entities.crematories.allIds,
        isAdmin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showToast: options => {
            dispatch(showToast(options));
        },
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        },
        dispatch
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditUserDetailContainer)
);
