import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { putNormalizedEntities } from "../../helpers";

import PollCeremonyLockStatusContainer from "./PollCeremonyLockStatusContainer";

const mapStateToProps = (state, props) => {
    const {
        entities: { ceremonies }
    } = state;
    const ceremonyId = parseInt(props.match.params.ceremonyID);
    const ceremony = ceremonies.byId[ceremonyId];

    return {
        ceremony
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateStoreEntities: entities =>
            putNormalizedEntities(dispatch, entities)
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PollCeremonyLockStatusContainer)
);
