import React from "react";

import logo from "../../assets/logo_text_purple.svg";
import i18n from "../../constants/i18n";

import styles from "./Footer.module.scss";

const FooterView = props => (
    <div className={`container ${styles.container}`}>
        <div className={`${styles.row}`}>
            <div>
                <img src={logo} alt="Novella logo"></img>
            </div>
            <div>
                <span>{i18n.footer.poweredBy}</span>
            </div>
            <div>
                <a href={i18n.footer.privacyStatementUrl} target="_blank">
                    <span>{i18n.footer.privacyStatement}</span>
                </a>
            </div>
        </div>
    </div>
);

export default FooterView;
