import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";

import modalTypes from "../../constants/modalTypes";
import roles from "../../constants/roles";
import { showModal } from "../../actions/modals";

import NavbarView from "./NavbarView";
import { stopImpersonation } from "../../actions/user";

const mapStateToProps = state => {
    const userRoles = get(state, ["user", "profile", "roles"]);

    return {
        isAdmin: Boolean(userRoles) && userRoles.includes(roles.admin),
        isCrematoryAdmin:
            Boolean(userRoles) && userRoles.includes(roles.crematory),
        isImpersonation:
            state.auth.token && Boolean(state.auth.token.isImpersonation)
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        showAdminModal: role => {
            dispatch(
                showModal({
                    type: modalTypes.ADMIN_MANAGEMENT,
                    props: { targetRole: role }
                })
            );
        },
        stopImpersonation: () => {
            dispatch(stopImpersonation(props));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NavbarView)
);
