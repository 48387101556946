import classNames from "classnames";
import T from "prop-types";
import React from "react";

import styles from "./BlockRemovalArea.module.scss";

const BlockRemovalAreaView = ({ isDraggingOver, placeholder }) => {
    return (
        <div
            className={classNames({
                [styles.removalBlockArea]: true,
                [styles.isActive]: isDraggingOver
            })}
        >
            <span className="icon">
                <i className="fas fa-trash-alt"></i>
            </span>
            <h2>Sleep om te verwijderen</h2>
            {placeholder}
        </div>
    );
};

BlockRemovalAreaView.propTypes = {
    isDraggingOver: T.bool
};

export default BlockRemovalAreaView;
