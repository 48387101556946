import React from "react";
import Tippy from "@tippy.js/react";

import ShowIf from "../../ShowIf";
import i18n from "../../../constants/i18n";

import styles from "./TableItem.module.scss";

const TableItemView = ({ devices, isAdmin, onDelete, onEdit, room }) => {
    if (!room) {
        return null;
    }

    const assignedDevice = devices.find(device => device.roomId === room.id);

    return (
        <tr>
            <td>
                <h2>{room.name}</h2>
            </td>
            <td>
                Ruimte ID:{" "}
                {assignedDevice ? <span>{assignedDevice.roomNumber}</span> : ""}
            </td>
            <td>
                {room.locationCode ? (
                    <span>{room.locationCode}</span>
                ) : (
                    "Geen player gekoppeld"
                )}
            </td>
            <td>
                <div className={styles.iconLabelContainer}>
                    <span className={styles.label}>Livestreaming</span>
                    <ShowIf condition={room.livestreaming}>
                        <span
                            className={`icon ${
                                room.livestreaming ? styles.iconPrimary : ""
                            }`}
                        >
                            <i className="fas fa-check"></i>
                        </span>
                    </ShowIf>
                    <ShowIf condition={!room.livestreaming}>
                        <span>
                            <i className="fas fa-times"></i>
                        </span>
                    </ShowIf>
                </div>
            </td>
            <td>
                <div className={styles.iconLabelContainer}>
                    <span className={styles.label}>Opnemen</span>
                    <ShowIf condition={room.recording}>
                        <span
                            className={`icon ${
                                room.recording ? styles.iconPrimary : ""
                            }`}
                        >
                            <i className="fas fa-check"></i>
                        </span>
                    </ShowIf>
                    <ShowIf condition={!room.recording}>
                        <span>
                            <i className="fas fa-times"></i>
                        </span>
                    </ShowIf>
                </div>
            </td>
            <ShowIf condition={isAdmin}>
                <td>
                    <div className={styles.iconLabelContainer}>
                        <span className={styles.label}>
                            {i18n.roomOverview.masterUnitLabel}
                        </span>
                        <ShowIf condition={room.hidden}>
                            <span
                                className={`icon ${
                                    room.hidden ? styles.iconPrimary : ""
                                }`}
                            >
                                <i className="fas fa-check"></i>
                            </span>
                        </ShowIf>
                        <ShowIf condition={!room.hidden}>
                            <span>
                                <i className="fas fa-times"></i>
                            </span>
                        </ShowIf>
                    </div>
                </td>
            </ShowIf>
            <ShowIf condition={isAdmin}>
                <td>
                    <div className={styles.iconLabelContainer}>
                        <span className={styles.label}>Bewegwijzering</span>
                        <ShowIf condition={room.signageEnabled}>
                            <span
                                className={`icon ${
                                    room.signageEnabled
                                        ? styles.iconPrimary
                                        : ""
                                }`}
                            >
                                <i className="fas fa-check"></i>
                            </span>
                        </ShowIf>
                        <ShowIf condition={!room.signageEnabled}>
                            <span>
                                <i className="fas fa-times"></i>
                            </span>
                        </ShowIf>
                    </div>
                </td>
                <td>
                    <div className="level">
                        <div className="level-item">
                            <Tippy content={i18n.generic.edit}>
                                <span
                                    onClick={() => onEdit(room)}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lg fa-pen"></i>
                                </span>
                            </Tippy>
                        </div>
                        <div className="level-item">
                            <Tippy content={i18n.generic.delete}>
                                <span
                                    onClick={() => onDelete(room.id)}
                                    className={`icon is-clickable ${styles.icon} ${styles.clickable}`}
                                >
                                    <i className="fas fa-lg fa-trash-alt"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                </td>
            </ShowIf>
        </tr>
    );
};

export default TableItemView;
