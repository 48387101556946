import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modals";
import { showToast } from "../../../../actions/toasts";
import { putNormalizedEntities } from "../../../../helpers";
import roleTypes from "../../../../constants/roles";
import RelativesModalContainer from "./RelativesModalContainer";
import { get } from "micro-dash";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const ceremony = state.entities.ceremonies.byId[ceremonyId];
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);
    const isCrematory = state.user.profile.roles.includes(roleTypes.crematory);
    const currentUserId = state.user.profile.id;

    return {
        isVisible: true,
        ceremony: ceremony.item,
        isCrematory,
        isAdmin,
        currentUserId,
        ceremonyMaster: get(
            state,
            [
                "entities",
                "ceremonies",
                "byId",
                ceremonyId,
                "item",
                "ceremonyMaster"
            ],
            {}
        )
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => {
            dispatch(hideModal());
        },
        showToast: options => {
            dispatch(showToast(options));
        },
        updateStoreEntities: entities => {
            putNormalizedEntities(dispatch, entities);
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RelativesModalContainer);
