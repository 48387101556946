/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";

import { DELETE_DEVICE, PUT_DEVICES } from "../../constants/actionTypes";

const devicesById = produce((draft, action) => {
    switch (action.type) {
        case PUT_DEVICES:
            const devices = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            devices.forEach(device => {
                draft[device.id] = device;
            });
            break;

        case DELETE_DEVICE: {
            delete draft[action.payload];
            break;
        }
    }
}, {});

const allDevices = produce((draft, action) => {
    switch (action.type) {
        case PUT_DEVICES:
            const devices = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            devices.forEach(device => {
                const isAlreadyPresent = draft.includes(device.id);
                if (!isAlreadyPresent) {
                    draft.push(device.id);
                }
            });
            break;

        case DELETE_DEVICE: {
            return draft.filter(id => id !== action.payload);
        }
    }
}, []);

export default combineReducers({
    byId: devicesById,
    allIds: allDevices
});
