import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import i18n from "../../constants/i18n";
import styles from "./LoginForm.module.scss";

const LoginView = props => (
    <Formik
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
        render={({ errors, isSubmitting, touched }) => (
            <Form>
                <div className={`field ${styles.field}`}>
                    <label className="label has-text-centered has-text-primary">
                        {i18n.loginForm.emailLabel}
                    </label>
                    <div className="control has-icons-right">
                        <Field
                            className={`input is-rounded ${
                                errors.email && touched.email ? "is-danger" : ""
                            }`}
                            type="email"
                            name="email"
                        />
                        {errors.email && touched.email ? (
                            <span className="icon is-right has-text-danger">
                                <i className="fas fa-exclamation-circle"></i>
                            </span>
                        ) : null}
                    </div>
                    <ErrorMessage
                        name="email"
                        component="p"
                        className="help is-danger is-size-6"
                    />
                </div>

                <div className={`field ${styles.field}`}>
                    <label className="label has-text-primary has-text-centered">
                        {i18n.loginForm.passwordLabel}
                    </label>
                    <div className="control has-icons-right">
                        <Field
                            className={`input is-rounded ${
                                errors.password && touched.password
                                    ? "is-danger"
                                    : ""
                            }`}
                            type="password"
                            name="password"
                        />
                        {errors.password && touched.password ? (
                            <span className="icon is-right has-text-danger">
                                <i className="fas fa-exclamation-circle"></i>
                            </span>
                        ) : null}
                    </div>
                    <ErrorMessage
                        name="password"
                        component="p"
                        className="help is-danger is-size-6"
                    />
                </div>

                <div className="columns is-centered">
                    <div
                        className={`column is-narrow ${styles.submitButtonContainer}`}
                    >
                        <button
                            type="submit"
                            className={`button is-primary is-rounded ${
                                styles.submitBtn
                            } ${isSubmitting ? "is-loading" : ""}`}
                            disabled={isSubmitting}
                        >
                            {i18n.loginForm.submitButtonLabel}
                        </button>
                    </div>
                </div>
            </Form>
        )}
    />
);

export default LoginView;
