import React from "react";

import styles from "./CeremonyCreateSuccessModal.module.scss";
import i18n from "../../../../constants/i18n";

const CeremonyCreateSuccessModalView = ({
    handleGoToTimeline,
    handleGoBackToOverview,
    isVisible
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        Success
                    </h2>
                    <button
                        onClick={handleGoBackToOverview}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <p className={styles.text}>
                        {i18n.generic.createCeremonySuccess}
                    </p>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={handleGoBackToOverview}
                        className="button is-primary is-rounded"
                    >
                        {i18n.ceremonySuccessModal.toOverview}
                    </button>
                    <button
                        onClick={handleGoToTimeline}
                        className="button is-secondary is-rounded"
                    >
                        {i18n.ceremonySuccessModal.toTimeline}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default CeremonyCreateSuccessModalView;
