import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "micro-dash";

import {
    getCeremonyBlocksWithAssets,
    getWalkInBlockId,
    getWalkOutBlockId
} from "../../../selectors/blocks";

import BlocksView from "./BlocksView";

const mapStateToProps = (state, ownProps) => {
    const ceremonyId = parseInt(ownProps.match.params.ceremonyID);
    const ceremony = state.entities.ceremonies.byId[ceremonyId];
    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    const blocksWithAssets = getCeremonyBlocksWithAssets(state, ceremonyId).map(
        block => {
            if (block.id === walkInBlockId) {
                return { ...block, isWalkInBlock: true };
            }
            if (block.id === walkOutBlockId) {
                return { ...block, isWalkOutBlock: true };
            }
            return { ...block };
        }
    );

    return {
        speechPhotoLimit: get(ceremony, ["item", "numberPhotos"]),
        blocks: blocksWithAssets
    };
};

export default withRouter(connect(mapStateToProps)(BlocksView));
