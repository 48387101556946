import React from "react";
import styles from "./UserDetail.module.scss";
import { format } from "date-fns";
import routeNames from "../../constants/routeNames";
import i18n from "../../constants/i18n";

const UserDetailView = ({ user, history, startImpersonation }) => {
    return (
        <div className="container">
            <div className={`${styles.backButton}`}>
                <div
                    onClick={() =>
                        history.push(
                            `${routeNames.usersPaginated}`.replace(
                                ":pageNumber",
                                1
                            )
                        )
                    }
                >
                    <span className="icon is-small">
                        <i className="fas fa-chevron-left"></i>
                    </span>
                    <span>{i18n.editUser.backToUserOverviewButton}</span>
                </div>
            </div>

            <div className="level">
                <div className="level-left">
                    <h1>{i18n.editUser.userLabel}</h1>
                </div>
                <div className="level-right">
                    <button
                        onClick={() =>
                            startImpersonation(user.item.id, user.item.name)
                        }
                        className={`button is-rounded is-secondary ${styles.detailsButton}`}
                    >
                        {i18n.userOverview.tableItemImpersonateButtonLabel}
                    </button>
                    <button
                        onClick={() =>
                            history.push(
                                `${routeNames.editUserDetail}`.replace(
                                    ":userID",
                                    user.item.id
                                )
                            )
                        }
                        className="button is-secondary is-rounded"
                    >
                        {i18n.editUser.editButton}
                    </button>
                </div>
            </div>

            <table className="table is-bordered is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.idLabel}</strong>
                        </td>
                        <td>{user.item.id}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.nameLabel}</strong>
                        </td>
                        <td>{user.item.name}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.emailLabel}</strong>
                        </td>
                        <td>{user.item.email}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.roleLabel}</strong>
                        </td>
                        <td>
                            {user.item.role.length === 0 ? (
                                "-"
                            ) : (
                                <strong>{i18n.roles[user.item.role]}</strong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.locationLabel}</strong>
                        </td>
                        <td>
                            {user.item.locations.map(location => {
                                return <p key={location.id}>{location.name}</p>;
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.phoneLabel}</strong>
                        </td>
                        <td>{user.item.phone}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.organizationLabel}</strong>
                        </td>
                        <td>{user.item.organizationName}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.createdAtLabel}</strong>
                        </td>
                        <td>
                            {format(
                                new Date(user.item.createdAt),
                                "dd/MM/’yy - HH:mm"
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{i18n.editUser.updatedAtLabel}</strong>
                        </td>
                        <td>
                            {format(
                                new Date(user.item.updatedAt),
                                "dd/MM/’yy - HH:mm"
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default UserDetailView;
