// @ts-check
import React from "react";
import T from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";

import styles from "./DraggablePhoto.module.scss";
import { droppableIdPrefixes, draggableIdPrefixes } from "../constants";

function isPhotoBeingProcessed(photo) {
    return !photo.fileName;
}

function generateDropableId(photoId) {
    return `${droppableIdPrefixes.photos}${photoId}`;
}

function generateDraggableId(photoId) {
    return `${draggableIdPrefixes.photo}${photoId}`;
}
const DraggablePhotoView = ({
    isGhosting,
    isSelected,
    onClick,
    onKeyDown,
    onTouchEnd,
    photo,
    displayableIndex,
    render,
    selectionCount
}) => {
    return (
        <Droppable droppableId={generateDropableId(photo.id)} isDropDisabled>
            {providedRoot => (
                <div
                    ref={providedRoot.innerRef}
                    {...providedRoot.droppableProps}
                >
                    <div className={`${styles.countPhoto}`}>
                        {displayableIndex}
                    </div>
                    <Draggable
                        draggableId={generateDraggableId(photo.id)}
                        index={photo.index}
                        isDragDisabled={isPhotoBeingProcessed(photo)}
                    >
                        {(provided, snapshot) => (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                onClick={onClick}
                                onTouchEnd={onTouchEnd}
                                onKeyDown={event =>
                                    onKeyDown(event, provided, snapshot)
                                }
                                className={classNames({
                                    [styles.draggableRoot]: true,
                                    [styles.isDragging]: snapshot.isDragging
                                })}
                            >
                                {render({
                                    snapshot,
                                    isSelected,
                                    isGhosting,
                                    selectionCount
                                })}
                            </div>
                        )}
                    </Draggable>
                    {providedRoot.placeholder}
                </div>
            )}
        </Droppable>
    );
};

DraggablePhotoView.propTypes = {
    isGhosting: T.bool,
    isSelected: T.bool,
    onClick: T.func,
    onKeyDown: T.func,
    onTouchEnd: T.func,
    photo: T.shape({
        id: T.oneOfType([T.string, T.number]),
        index: T.number
    }),
    displayableIndex: T.number,
    render: T.func,
    selectionCount: T.number
};

export default DraggablePhotoView;
