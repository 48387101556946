import React from "react";
import { Form } from "formik";
import styles from "./OrganizationModal.module.scss";
import i18n from "../../../../constants/i18n";
import Input from "../../../Input";
import ShowIf from "../../../ShowIf";
import { FormNotice } from "../../../FormNotice/FormNotice";

const OrganizationModalView = ({
    isEditing,
    isVisible,
    isFetching,
    hideModal,
    setFieldValue,
    errors,
    touched,
    values,
    submitForm,
    isSubmitting,
    deleteOrganization,
    setActiveStatus,
    handleBlur,
    organizations,
    ...props
}) => {
    return (
        <div className={`modal ${isVisible ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <ShowIf condition={isEditing}>
                        <span
                            className={`icon has-text-danger is-medium ${styles.deleteButton}`}
                            onClick={() => deleteOrganization(values.id)}
                        >
                            <i className="fas fa-trash-alt fa-lg" />
                        </span>
                    </ShowIf>
                    <h2 className="modal-card-title has-text-primary">
                        {isEditing
                            ? i18n.organizationModal.titleEditMode
                            : i18n.organizationModal.titleCreateMode}
                    </h2>
                    <button
                        onClick={hideModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <Form>
                        <Input
                            errors={errors}
                            touched={touched}
                            name="name"
                            label={i18n.generic.name}
                            placeholder={i18n.generic.generic}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="address"
                            label={i18n.generic.address}
                            placeholder={i18n.generic.address}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="zipcode"
                            label={i18n.generic.zipcode}
                            placeholder={i18n.generic.zipcode}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="city"
                            label={i18n.generic.city}
                            placeholder={i18n.generic.city}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="email"
                            label={i18n.generic.email}
                            placeholder={i18n.generic.locationEmail}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="phoneNumber"
                            label={i18n.generic.phoneNumber}
                            placeholder={i18n.generic.phoneNumber}
                        />
                        <Input
                            errors={errors}
                            touched={touched}
                            name="webcastApiExternalId"
                            label={i18n.generic.webcastOrganizationId}
                            placeholder={i18n.generic.webcastOrganizationId}
                        />
                        <FormNotice>
                            {i18n.organizationModal.webcastOrganizationIdInfo}
                        </FormNotice>
                    </Form>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={submitForm}
                        disabled={isSubmitting}
                        className="button is-primary is-rounded"
                    >
                        {isEditing ? i18n.generic.save : i18n.generic.add}
                    </button>
                    <ShowIf condition={isEditing}>
                        <button
                            onClick={() => deleteOrganization(values.id)}
                            className="button is-danger is-rounded"
                        >
                            {i18n.generic.deleteButtonLabel}
                        </button>
                    </ShowIf>
                </footer>
            </div>
        </div>
    );
};

export default OrganizationModalView;
