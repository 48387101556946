import { sortBy } from "micro-dash";

function findBlockAssets({ block, audioTracks, photos, videos }) {
    let result = { ...block };

    if (block.audioId) {
        result = {
            ...result,
            audioTrack: audioTracks.find(
                audioTrack => audioTrack.id === block.audioId
            )
        };
    }

    if (block.videoId) {
        result = {
            ...result,
            video: videos.find(video => video.id === block.videoId)
        };
    }

    if (block.imageIds) {
        result = {
            ...result,
            photos: block.imageIds.map(photoId =>
                photos.find(photo => photo.id === photoId)
            )
        };
    }

    return result;
}

export const getCeremonyBlocksWithAssets = (state, ceremonyId) => {
    const { audioTracks, blocks, photos, videos } = state.entities;

    const audioTrackItems = Object.values(audioTracks.byId).filter(
        audio => audio.ceremonyId === ceremonyId
    );
    const blockItems = Object.values(blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );
    const photoItems = Object.values(photos.byId).filter(
        photo => photo.ceremonyId === ceremonyId
    );
    const videoItems = Object.values(videos.byId).filter(
        video => video.ceremonyId === ceremonyId
    );

    return blockItems.map(block =>
        findBlockAssets({
            audioTracks: audioTrackItems,
            block,
            photos: photoItems,
            videos: videoItems
        })
    );
};

export const getCeremonyBlockWithAssetsById = (
    state,
    { ceremonyId, blockId }
) => {
    const blocks = getCeremonyBlocksWithAssets(state, ceremonyId);

    return blocks.find(block => block.id === blockId);
};

export const getWalkInBlockId = (state, ceremonyId) => {
    const blocks = Object.values(state.entities.blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );
    const sortedByPosition = sortBy(blocks, block => block.position);

    if (!sortedByPosition.length) return null;

    return sortedByPosition[0].id;
};

export const getWalkOutBlockId = (state, ceremonyId) => {
    const blocks = Object.values(state.entities.blocks.byId).filter(
        block => block.ceremonyId === ceremonyId
    );
    const sortedByPosition = sortBy(blocks, block => block.position);

    if (!sortedByPosition.length) return null;

    return sortedByPosition[sortedByPosition.length - 1].id;
};
