import React, { useRef } from "react";
import ReactCrop from "react-image-crop";

import styles from "./BlockImageRotateModal.module.scss";

import i18n from "../../../../constants/i18n";

const BlockImageRotateModalView = ({
    isSubmitting,
    isVisible,
    onRotationChange,
    onSubmit,
    rotation,
    scale,
    src,
    onReturnToImageAdjustmentModal
}) => {
    const ref = useRef(null);
    return (
        <div
            className={`modal ${styles.modal} ${isVisible ? "is-active" : ""}`}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <h2 className="modal-card-title has-text-primary">
                        {i18n.photoADjustmentModal.rotateTitle}
                    </h2>
                    <button
                        onClick={onReturnToImageAdjustmentModal}
                        className="delete"
                        aria-label="close"
                    />
                </header>
                <section className="modal-card-body">
                    <div className={styles.cropContainer}>
                        {src && (
                            <img
                                ref={ref}
                                src={src}
                                style={{
                                    transform: `rotate(${rotation}deg) scale(${scale})`
                                }}
                                alt=""
                            />
                        )}
                    </div>
                    <div className={styles.rotateButtonContainer}>
                        <div
                            onClick={() => {
                                onRotationChange(
                                    ref.current.width,
                                    ref.current.height
                                );
                            }}
                            className="field has-addons"
                        >
                            <p className="control">
                                <button className="button is-rounded">
                                    <span>Roteren</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-retweet"></i>
                                    </span>
                                </button>
                            </p>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button
                        onClick={onSubmit}
                        className={`button is-primary is-rounded ${
                            isSubmitting ? "is-loading" : ""
                        }`}
                    >
                        {i18n.photoADjustmentModal.saveAndReturnToBlockEdit}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default BlockImageRotateModalView;
