import { connect } from "react-redux";

import { startImpersonation } from "../../../actions/user";
import TableItemView from "./TableItemView";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.entities.users.byId[ownProps.userId]
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        startImpersonation: (userId, username) => {
            dispatch(startImpersonation(userId, username, props));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableItemView)
);
