import { connect } from "react-redux";
import { sortBy } from "micro-dash";

import { hideModal, showModal } from "../../../../actions/modals";
import {
    getCeremonyBlocksWithAssets,
    getWalkInBlockId,
    getWalkOutBlockId
} from "../../../../selectors/blocks";

import BlockPreviewModalContainer from "./BlockPreviewModalContainer";
import roleTypes from "../../../../constants/roles";
import modalTypes from "../../../../constants/modalTypes";

const mapStateToProps = (state, props) => {
    const ceremonyId = parseInt(props.ceremonyId);
    const crematoryId = parseInt(props.crematoryId);
    const walkInBlockId = getWalkInBlockId(state, ceremonyId);
    const walkOutBlockId = getWalkOutBlockId(state, ceremonyId);
    const isAdmin = state.user.profile.roles.includes(roleTypes.admin);
    const isCrematoryAdmin = state.user.profile.roles.includes(
        roleTypes.crematory
    );
    const unorderedBlocks = getCeremonyBlocksWithAssets(state, ceremonyId).map(
        block => {
            if (block.id === walkInBlockId) {
                return { ...block, isWalkInBlock: true };
            }
            if (block.id === walkOutBlockId) {
                return { ...block, isWalkOutBlock: true };
            }
            return { ...block };
        }
    );

    const blocks = sortBy(unorderedBlocks, block => block.position);

    return {
        ceremony: state.entities.ceremonies.byId[ceremonyId],
        blocks,
        ceremonyId,
        isAdmin,
        crematoryId,
        isCrematoryAdmin,
        isVisible: true
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        hideModal: () => dispatch(hideModal()),
        showLockedTimelineModal: ({ areAssetsCleanedUp }) =>
            dispatch(
                showModal({
                    type: modalTypes.LOCKED_TIMELINE,
                    props: {
                        ceremonyId: parseInt(props.ceremonyId),
                        crematoryId: parseInt(props.crematoryId),
                        areAssetsCleanedUp
                    }
                })
            )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockPreviewModalContainer);
