/* eslint-disable default-case */

import produce from "immer";
import { combineReducers } from "redux";
import { get } from "micro-dash";

import {
    INVALIDATE_USER,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    PUT_USERS,
    FETCH_USER_BY_ID_SUCCESS,
    FETCH_USER_BY_ID_FAILURE,
    FETCH_USER_BY_ID_REQUEST
} from "../../constants/actionTypes";

const usersById = produce((draft, action) => {
    const userID = get(action, ["payload", "id"], action.payload);
    const user = draft[userID];

    switch (action.type) {
        case INVALIDATE_USER: {
            if (user) {
                user.didInvalidate = true;
            }
            break;
        }

        case FETCH_USER_BY_ID_REQUEST:
        case FETCH_USER_REQUEST: {
            if (user) {
                user.isFetching = true;
            } else {
                draft[userID] = {
                    error: null,
                    isFetching: true,
                    didInvalidate: false,
                    lastUpdated: Date.now(),
                    item: null
                };
            }
            break;
        }

        case FETCH_USER_BY_ID_SUCCESS:
        case PUT_USERS: {
            const users = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            users.forEach(user => {
                const existingUser = draft[user.id];

                if (existingUser) {
                    existingUser.error = null;
                    existingUser.isFetching = false;
                    existingUser.didInvalidate = false;
                    existingUser.lastUpdated = Date.now();
                    existingUser.item = user;
                } else {
                    draft[user.id] = {
                        error: null,
                        isFetching: false,
                        didInvalidate: false,
                        lastUpdated: Date.now(),
                        item: user
                    };
                }
            });
            break;
        }

        case FETCH_USER_BY_ID_FAILURE:
        case FETCH_USER_FAILURE: {
            if (user) {
                user.isFetching = false;
                user.error = action.error;
                user.lastUpdated = Date.now();
            }
            break;
        }
    }
}, {});

const allUsers = produce((draft, action) => {
    switch (action.type) {
        case FETCH_USER_SUCCESS:
        case PUT_USERS: {
            const users = Array.isArray(action.payload)
                ? [...action.payload]
                : [action.payload];

            users.forEach(user => {
                const id = user.id;
                if (!draft.find(item => item === id)) {
                    draft.push(user.id);
                }
            });
            break;
        }
    }
}, []);

export default combineReducers({
    byId: usersById,
    allIds: allUsers
});
