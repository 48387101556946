import React from "react";
import { isNumber } from "micro-dash";

import i18n from "../../constants/i18n";

const TotalCrematoryCountView = props => (
    <span>
        {i18n.generic.totalCrematoryCount}
        {isNumber(props.value) ? props.value : ""}
    </span>
);

export default TotalCrematoryCountView;
